import { all } from "redux-saga/effects";
import {
  listenGetToken,
  listenSetSessionToken,
  listenSetToken,
} from "./auth.sagas";
import { listengetRoles } from "./authr.sagas";
import {
  listenGetConstants,
  listenGetCountries,
  listenGetHotelGroupList,
  listenGetLanguages,
  listenGetStates,
} from "./constants.sagas";
import {
  listenassignHotelForHotelGroup,
  listenCheckEmail,
  listenCreateHotelGroup,
  listenGetHotelByHotelGroups,
  listenGetHotelGroupDetails,
  listenGetHotelGroups,
  listenGetOrhanHotels,
  listenGetSearchSortedHotelGroups,
  listenGetSortedHotelGroups,
  listenhotelGroupName,
  listenResetUpdateHotelGroup,
  listenUnassignHotelFromHotelGroup,
  listenUpdateHotelGroup,
  searchHotelGroup,
} from "./hotelgroup.sagas";
import {
  listenAddPmsFormData,
  listenAssignPmsHotel,
  listenCreateHotels,
  listenGetHotelDetails,
  listenGetHotels,
  listenGetPms,
  listenGetPmsHotel,
  listenGetSearchSortedHotels,
  listenGetSortedHotels,
  listenResetUpdateHote,
  listenSyncCategory,
  listenSyncRooms,
  listenSyncProducts,
  listenupdateHotelDetails,
  listenupdateHotelStatus,
  listenUpdatePmsIntegration,
  searchHotel,
} from "./hotels.sagas";
import {
  listenActivateUser,
  listenChangePassword,
  listenconfirmPassword,
  listenDeactivateUser,
  listenforgotPassword,
  listenLogin,
  listenLogOut,
  listenResetPassword,
  listenSetPassword,
} from "./login.sagas";
import {
  listenAddUser,
  listengetUserById,
  listengetUserProfile,
  listengetUsers,
  listenUpdateUser,
  listenupdateUserProfile,
} from "./userManagement.sagas";
export default function* rootSaga() {
  yield all([
    listenGetHotelGroups(),
    listenGetSortedHotelGroups(),
    listenGetHotelByHotelGroups(),
    listenUnassignHotelFromHotelGroup(),
    listenCreateHotelGroup(),
    listenassignHotelForHotelGroup(),
    listenGetHotelGroupDetails(),
    listenGetOrhanHotels(),
    listenUpdateHotelGroup(),
    listenGetSearchSortedHotelGroups(),
    listenGetHotels(),
    listenGetSortedHotels(),
    listenCreateHotels(),
    listenGetHotelDetails(),
    searchHotelGroup(),
    listenGetConstants(),
    listenGetLanguages(),
    listenupdateHotelDetails(),
    listenGetSearchSortedHotels(),
    listenupdateHotelStatus(),
    searchHotel(),
    listenGetHotelGroupList(),
    listenGetCountries(),
    listenGetStates(),
    listenResetUpdateHotelGroup(),
    listenResetUpdateHote(),
    listenLogin(),
    listenGetToken(),
    listenSetToken(),
    listenhotelGroupName(),
    listenCheckEmail(),
    listenLogOut(),
    listenforgotPassword(),
    listenconfirmPassword(),
    listengetUsers(),
    listengetUserById(),
    listenAddUser(),
    listenUpdateUser(),
    listengetUserProfile(),
    listenupdateUserProfile(),
    listenResetPassword(),
    listenSetPassword(),
    listenSetSessionToken(),
    listenChangePassword(),
    listenDeactivateUser(),
    listengetRoles(),
    listenActivateUser(),
    listenGetPms(),
    listenAddPmsFormData(),
    listenGetPmsHotel(),
    listenAssignPmsHotel(),
    listenUpdatePmsIntegration(),
    listenSyncCategory(),
    listenSyncRooms(),
    listenSyncProducts(),
  ]);
}
