import React from 'react';
import { Pagination } from 'react-bootstrap'

const PaginationComponent = (props) => {
    const incrementPageNo = () => {
        if(props.pageNo + 1 <= props.totalCount){
            props.onChange(props.pageNo + 1)

        }
    }

    const decreamentPageNo = () => {
        if(props.pageNo-1 >0){
            props.onChange(props.pageNo - 1)
        }
    }
    let totalCount = (props.totalCount===0)?(1):(props.totalCount)
    return (
        <Pagination size = "sm" sm={1}>
            <Pagination.Item>{props.pageNo}</Pagination.Item>
            <span> of {totalCount} Pages</span>
            <Pagination.Prev onClick={decreamentPageNo} />
            <Pagination.Next onClick={incrementPageNo} />
        </Pagination>
    )
}

export default PaginationComponent;