import actions from '../actions/actions'


const initialState = {
    constants: {},
    languages: [],
    result: {},
    hotel_group_list:[],
    countries : [],
    states:{},
}

export const constantsReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case actions.FETCH_CONSTANTS_SUCCESS: {
            return {
                ...state,
                constants: action.data,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_LANGUAGES_SUCCESS: {
            return {
                ...state,
                languages: action.data,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.GET_HOTEL_GROUP_LIST_SUCCESS:{
            let hg_list  = action.data.map((v,i)=>{
                return {id:v['id'], value : v['name']}
            })
            return {
                ...state,
                hotel_group_list:hg_list,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.GET_COUNTRY_SUCCESS:{
            return {
                ...state,
                countries:action.data,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.GET_STATES_SUCCESS:{
            return {
                ...state,
                states: {
                    ...state.states,
                    [parseInt(action.country)]:action.data
                },
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        default: {
            return state
        }
    }
}