// Toast msg configs
const TOAST_CONFIG = {
  default: {
    position: "bottom-center",
    duration: 3000,
  },
};

const MOBILE_SCREENS = {
  MAX_WIDTH: 768,
};

//Added private route for managing roles and permission
const PRIVATE_ROUTES = {
  home_page: "/home",
  create_hotel_group: "/hotel-group/create",
  hotel_group_listing: "/hotel-group/list",
  update_hotel_group: "/hotel-group/update/:id",
  create_hotel: "/hotel/create",
  hotel_listing: "/hotel/list",
  update_hotel: "/hotel/update/:id",
  assign_hotel: "/hotel/assign",
  create_new_user: "/user/create",
  user_listing: "/user/list",
  update_user: "/update-user/:id",
  user_profile: "/user/profile",
  update_profile: "",
  dashboard: "/dashboard",
  // set_password: "/set-password",
};
//storage keys:
const STORAGE_KEYS = {
  IdToken: "IdToken",
  RefreshToken: "RefreshToken",
  token: "token",
  allowedPages: "allowedPages",
  AccessToken: "AccessToken",
  UserFirstName: "user-first-name",
  dashboardLink: "dashboardLink",
};
const TABLE_ALIGNMENT = {
  "MONTHLY FEE(€)": "align-right",
  AMOUNT: "align-right",
  "COMMISSION%": "align-center",
  COMMISSION: "align-center",
};
const RES_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  BAD_REQ: 400,
  SUCCESS: 200,
  INTERNAL_SERVER: 500,
};

export {
  TOAST_CONFIG,
  TABLE_ALIGNMENT,
  MOBILE_SCREENS,
  STORAGE_KEYS,
  PRIVATE_ROUTES,
  RES_CODE
};

