import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import UserBasicInformation from "./UserBasicInformation";
import UserContactInformation from "./UserContactInformation";

const CreateUser = (props) => {
  const dispatch = useDispatch();
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const history = useHistory();
  const isAddUser = useSelector((state) => state.user.addUser);
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [countryISO, setCountryISO] = useState();
  const [formInitialValue, setFormInitialValue] = useState({
    first_name: "",
    email: "",
    middle_name: "",
    last_name: "",
    contact_address_street: "",
    contact_address_zipcode: "",
    contact_address_state_id: "",
    contact_address_country_id: "",
    contact_address_city: "",
    phone_number: "",
    phone_number_country: "",
    language_id: "",
    role_id: "",
  });
  const formSchema = yup.object().shape({
    first_name: yup.string().trim().required("Please Enter"),
    last_name: yup.string().trim().required("Please Enter"),
    contact_address_street: yup.string().trim().required("Please Enter"),
    contact_address_zipcode: yup.string().trim().required("Please Enter"),
    phone_number: yup.string().trim().required("Please Enter"),
    email: yup.string().required("Please Enter"),
    contact_address_city: yup.string().trim().required("Please Enter"),
    contact_address_country_id: yup.string().required("Please Enter"),
    language_id: yup.string().required("Please Enter"),
    role_id: yup.string().required("Please Enter"),
  });
  const countries = useSelector((state) => state.constants.countries);

  const [idCountry, setCountryId] = useState();
  const states = useSelector((state) => state.constants.states[idCountry]);
  const breadcrumbs = [
    { path: "/user/list", title: "Users" },
    { path: `/user/create`, title: "Add new user" },
  ];

  const createUser = (payload) => {  
    dispatch(actions.createUser(payload));
  };
  const onCancelClick = () => {
    history.push("/user/list");
  };
  useEffect(() => {
    dispatch(actions.getCountries());
    if (isAddUser) {
      history.push("/user/list");
    }
  }, [isAddUser]);

  useEffect(() => {
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry, countries]);

  let setcountry = (e, setFieldValue) => {
    if (e) {
      setSelectedCountry(e);
      setCountryId(e.value);
      setFieldValue("contact_address_country_id", e.value);
      setSelectedCountry(null);
    } else {
      setFieldValue("contact_address_country_id", null);
      setSelectedState(null);
      setSelectedCountry("");
      setCountryId(undefined);
    }
  };
  const updateLanguage = (editorState, setFieldValue) => {
    if (editorState) {
      setFieldValue("language_id", editorState.value);
    } else {
      setFieldValue("language_id", "");
    }
  };

  const updateRoles = (editorState, setFieldValue) => {
    if (editorState) {
      setFieldValue("role_id", editorState.value);
    } else {
      setFieldValue("role_id", "");
    }
  };
  const updateState = (editorState, setFieldValue) => {
    if (editorState) {
      setFieldValue("contact_address_state_id", editorState.value);
    } else {
      setSelectedState(null);
    }
  };

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3> Add new user </h3>
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        enableReinitialize={false}
        onSubmit={(values) => {
          const payload = {
            ...values,
          };
          createUser(payload);
        }}
        onerror={(err) => {}}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          submitForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="content">
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <UserBasicInformation
                        change={handleChange}
                        error={errors}
                        values={values}
                        touched={touched}
                        updateform={handleChange}
                        updateDropdown={(updateContent) => {
                          updateLanguage(updateContent, setFieldValue);
                        }}
                        updateRole={(updateContent) => {
                          updateRoles(updateContent, setFieldValue);
                        }}
                        value={selectedLanguage}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UserContactInformation
                        change={handleChange}
                        error={errors}
                        values={values}
                        setCountryISO={setCountryISO}
                        touched={touched}
                        country={countries ? countries : []}
                        states={states ? states : []}
                        updateCountry={(updateContent) => {
                          setcountry(updateContent, setFieldValue);
                        }}
                        updateState={(updateContent) => {
                          updateState(updateContent, setFieldValue);
                        }}
                        updateform={handleChange}
                        idCountry={idCountry}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} sm={6} xs={7}>
                      {hiddenIconAsPerRole(PRIVATE_ROUTES.create_new_user) ? (
                        <Button
                          variant="primary"
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          Create User
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col md={3} sm={6} xs={5}>
                      <Button variant="secondary" onClick={onCancelClick}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default CreateUser;
