import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { getToken } from "../../services/auth.service";
import { StorageService } from "../../services/storage.service";
import actions from "../../store/actions/actions";
import { ReactComponent as ArrowLeft } from "../Common/arrowLeft.svg";
import DragDrop from "../Common/DragDrop";
import Breadcrumbs from "./../Common/Breadcrumbs";
import ChangePasswordModal from "./ChangePasswordModal";
import UserAccountInformation from "./UserAccountInformation";
import UserBasicInformation from "./UserBasicInformation";
import UserContactInformation from "./UserContactInformation";

const UpdateUser = (props) => {
  const history = useHistory();

  const [modalShow, setModalShow] = React.useState(false);
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const userProfile = useSelector((state) => state?.user?.userProfile);
  const isProfile = useSelector((state) => state?.user?.getUserProfile);
  const [emailId, setEmailId] = useState(null);
  const isResetPassword = useSelector((state) => state?.login?.isResetPassword);
  const isChangePassword = useSelector(
    (state) => state?.login?.isChangePassword
  );
  const [fileUploaded, setFileUploaded] = useState(null);
  const [imageFromApi, setimageFromApi] = useState([]);
  const [idCountry, setCountryId] = useState();
  const [user_name, setUser_name] = useState();
  const countries = useSelector((state) => state.constants.countries);
  const states = useSelector((state) => state.constants.states[idCountry]);
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const token = getToken();
  const roles = useSelector((state) => state.authr.roles);
  const [roleList, setRoleList] = useState();
  const [countryISO, setCountryISO] = useState();

  const [formInitialValue, setFormInitialValue] = useState({
    first_name: "",
    email: "",
    middle_name: "",
    last_name: "",
    contact_address_street: "",
    contact_address_zipcode: "",
    contact_address_state_id: "",
    contact_address_country_id: "",
    phone_number: "",
    phone_number_country: "",
    language_id: "",
    contact_address_city: "",
    //password: ""
  });
  const formSchema = yup.object().shape({
    first_name: yup.string().trim().required("Please Enter"),
    last_name: yup.string().trim().required("Please Enter"),
    contact_address_street: yup.string().trim().required("Please Enter"),
    contact_address_zipcode: yup.string().trim().required("Please Enter"),
    phone_number: yup.string().trim().required("Please Enter"),
    email: yup.string().trim().required("Please Enter"),
    contact_address_country_id: yup.string().required("Please Enter"),
    language_id: yup.string().required("Please Enter"),
    contact_address_city: yup.string().trim().required("Please Enter"),
    //password: yup.string().required("Please Enter"),
  });
  const updateUserProfile = (payload) => {
    const accessToken = storageService.getItem("token");
    payload.data["accessToken"] = accessToken;
    dispatch(actions.updateUserProfile(payload));
  };
  useEffect(() => {
    dispatch(actions.getCountries());
    if (!isProfile) {
      const accessToken = storageService.getItem("token");
      const payload = { accessToken: accessToken };
      dispatch(actions.getUserProfile(payload));
    }
  }, [isProfile]);
  const isConfirm = () => {};
  const resetPassword = () => {
    if (emailId) {
      setModalShow(true);
    }
  };
  const confirmPassword = (confirmPassword) => {
    confirmPassword.accessToken = storageService.getItem("token");
    confirmPassword.email = emailId;
    dispatch(actions.changePassword(confirmPassword));
  };

  useEffect(() => {
    if (isChangePassword) {
      setModalShow(false);
    }
  }, [isChangePassword]);
  const onCancelClick = () => {
    history.push("/home");
  };
  useEffect(() => {
    if (userProfile.id) {
      setEmailId(userProfile.email);
      formikRef.current.setValues({
        first_name: userProfile.first_name,
        email: userProfile.email,
        middle_name: userProfile.middle_name,
        last_name: userProfile.last_name,
        contact_address_street: userProfile.contact_address_street,
        contact_address_zipcode: userProfile.contact_address_zipcode,
        phone_number: userProfile.phone_number,
        contact_address_country_id: userProfile?.contact_address_country_id,
        language_id: userProfile?.language?.id,
        contact_address_city: userProfile?.contact_address_city,
        phone_number_country: userProfile?.phone_number_country,
      });
      const img = [];
      img.push({
        arrId: 1,
        preview: userProfile.profile_picture
          ? userProfile.profile_picture
          : null,
      });
      setimageFromApi(img);
      setselectedLanguage({
        label: userProfile?.language?.code,
        value: userProfile?.language?.id,
      });
      setSelectedCountry({
        label: userProfile?.contact_address_country?.name,
        value: userProfile?.contact_address_country?.id,
      });
      setSelectedState({
        label: userProfile?.contact_address_state?.name,
        value: userProfile?.contact_address_state?.id,
      });
      setCountryId(userProfile?.contact_address_country_id);
      const userName =
        `${userProfile.first_name}` +
        ` ${userProfile.middle_name ? userProfile.middle_name : ""}` +
        ` ${userProfile.last_name}`;
      setUser_name(userName);
      if (roles) {
        const roleList = roles?.find((role) => {
          return role.id === userProfile?.e_user_roles?.role_id;
        });
        setRoleList({ label: roleList?.name, value: roleList?.id });
      }
    }
  }, [userProfile, roles]);
  const breadcrumbs = [
    { path: "/user/list", title: "Users" },
    { path: `/user/profile`, title: user_name ? user_name : "" },
  ];
  let setcountry = (e, setFieldValue) => {
    if (e) {
      setSelectedCountry(e);
      setCountryId(e.value);
      setFieldValue("contact_address_country_id", e.value);
      setSelectedState(null);
    } else {
      setSelectedCountry(null);
      setSelectedState(null);
      setCountryId(undefined);
      setFieldValue("contact_address_country_id", null);
      setFieldValue("contact_address_city", null);
    }
  };
  useEffect(() => {
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry, countries]);

  const updateLanguage = (editorState, setFieldValue) => {
    if (editorState) {
      setselectedLanguage(editorState);
      setFieldValue("language_id", editorState.value);
    } else {
      setselectedLanguage(null);
      setFieldValue("language_id", null);
    }
  };
  const updateState = (editorState, setFieldValue) => {
    if (editorState) {
      setSelectedState(editorState);
      setFieldValue("contact_address_state_id", editorState.value);
    } else {
      setSelectedState(null);
      setFieldValue("contact_address_state_id", null);
    }
  };
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6} className="extra-content">
            <tr>
              <td>
                <Button
                  variant="white"
                  onClick={() => {
                    props.history.push("/home");
                  }}
                >
                  <ArrowLeft />
                </Button>
              </td>
              <td className="p-3"> Back </td>
            </tr>
          </Col>
          <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            {/* <Button variant="white">
                            <Vectors />
                        </Button> */}
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        enableReinitialize={false}
        innerRef={formikRef}
        onSubmit={(values) => {
          const payload = {
            data: {
              ...values,
            },
            image: fileUploaded,
          };
          if (fileUploaded.length < 1) {
            payload.data["profilePictureDeleted"] = 1;
          }
          updateUserProfile(payload);
        }}
        onerror={(err) => {}}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          submitForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="content">
              <p>
                <h5>Edit user information</h5>
              </p>
              <div>
                {/* <tr>
                                    <td>
                                        <img className="userImage" alt="userImage" src="/icons/user.png" />
                                    </td>
                                    <td className="pencilIcon">
                                        <img src={pencil} alt="pencil" />
                                    </td>
                                </tr> */}
                <DragDrop
                  maximumFile={1}
                  multiUpload={false}
                  handleChange={setFileUploaded}
                  values={imageFromApi}
                  path={imageFromApi[0]?.preview != null ? "update" : "add"}
                ></DragDrop>
                <div>
                  <div style={{ fontSize: "small" }}>
                    Only PNG, JPEG files with max size of 15MB
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <UserBasicInformation
                  isEditForm={true}
                  change={handleChange}
                  error={errors}
                  values={values}
                  touched={touched}
                  updateform={handleChange}
                  updateDropdown={(updateContent) => {
                    updateLanguage(updateContent, setFieldValue);
                  }}
                  value={selectedLanguage}
                />
              </div>{" "}
              <hr className="border-line" />
              <div className="mt-4">
                <UserAccountInformation
                  isEditForm={true}
                  change={handleChange}
                  error={errors}
                  values={values}
                  touched={touched}
                  value={selectedLanguage}
                  updateDropdown={(updateContent) => {
                    updateLanguage(updateContent, setFieldValue);
                  }}
                  resetPassword={resetPassword}
                  selectedRole={roleList}
                />
              </div>{" "}
              <hr className="border-line" />
              <div className="mt-4">
                <UserContactInformation
                  isEditForm={true}
                  change={handleChange}
                  error={errors}
                  values={values}
                  touched={touched}
                  country={countries ? countries : []}
                  states={states ? states : []}
                  updateCountry={(updateContent) => {
                    setcountry(updateContent, setFieldValue);
                  }}
                  updateState={(updateContent) => {
                    updateState(updateContent, setFieldValue);
                  }}
                  updateform={handleChange}
                  idCountry={idCountry}
                  isedit={true}
                  OldCountryVal={selectedCountry}
                  OldStateVal={selectedState}
                  setCountryISO={setCountryISO}
                />
              </div>
              <tr>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Save
                  </Button>
                </td>
                <td className="p-3">
                  <Button variant="secondary" onClick={onCancelClick}>
                    Cancel
                  </Button>
                </td>
              </tr>
            </div>
            <ChangePasswordModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              isConfirm={isConfirm}
              header={"Change Password"}
              confirmPassword={confirmPassword}
            />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpdateUser;
