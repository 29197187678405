import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import BaseLayout from "../components/layout/BaseLayout";
import { StorageService } from "./../services/storage.service";
import actions from "./../store/actions/actions";
import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";
import jwt_decode from "jwt-decode";

export const Routes = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authTokenExist = useSelector((state) => state.login.result.token);
  const redirectToHome = useSelector((state) => state.login.redirectToHome);
  const isSetPassword = useSelector((state) => state.login.redirectToHome);
  const sessionTokenExist = useSelector(
    (state) => state.login.result.sessionToken
  );
  const history = useHistory();
  const recentPath = useRef(history.location.pathname);
  const storageService = new StorageService();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getToken());
    if (history.location.pathname !== "/login") {
      recentPath.current = history.location.pathname;
    }
  }, []);

  // useEffect(() => {
  //   setIsAuthenticated(authTokenExist && authTokenExist.length ? true : false);
  // }, [authTokenExist]);
  // useEffect(() => {
  //   setIsAuthenticated(sessionTokenExist && sessionTokenExist.length ? true : false);
  // }, [sessionTokenExist]);

  useEffect(() => {
    if (
      (authTokenExist && authTokenExist.length > 1) ||
      (sessionTokenExist && sessionTokenExist.length > 1)
    ) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authTokenExist, sessionTokenExist]);

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     const decoded = jwt_decode(localStorage.getItem("token"));
  //     if (new Date() > new Date(new Date(0).setUTCSeconds(decoded?.exp))) {
  //       localStorage.clear();

  //       setIsAuthenticated(false);
  //       dispatch(actions.removeToken());
  //     }
  //   }
  // }, []);

  const publicRoutes = () => {
    return <PublicRoute />;
  };

  const privateRoutes = () => {
    if (sessionTokenExist) {
      recentPath.current = "/set-password";
      return <PrivateRoute recentPath={recentPath.current} />;
    } else {
      if (redirectToHome && !isSetPassword) {
        recentPath.current = "/home";
        return (
          <BaseLayout>
            <PrivateRoute recentPath={recentPath.current} />
          </BaseLayout>
        );
      } else {
        return (
          <BaseLayout>
            <PrivateRoute recentPath={recentPath.current} />
          </BaseLayout>
        );
      }
    }
  };

  const getRoutes = () => {
    return isAuthenticated ? privateRoutes() : publicRoutes();
  };

  return <Switch>{getRoutes()}</Switch>;
};
