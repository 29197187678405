import React, { useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import SingleDropDown from "../Common/singleDropDown";

const CONSTANT_FIELDS = {
  CONTRACT_TYPE: 0,
  HOTEL_TYPE: 1,
  PMS: 2,
  SUBSCRIPTION_TYPE: 3,
  HOTEL_GROUP: 4,
};

const HotelsBasicInformation = (props) => {
  const dispatch = useDispatch();

  let [HotelVisible, setHotelVisible] = useState({
    heading: true,
    name: true,
    cluster: true,
    area: true,
    region: true,
    division: true,
    brand: true,
    hotel_group: true,
    contract_type_id: true,
    hotel_type_id: true,
    pms_id: true,
    address_zipcode: true,
    state_country: true,
    total_rooms_count: true,
  });

  const [idCountry, setCountryId] = useState();
  useEffect(() => {
    if (props.updateState != undefined) {
      setHotelVisible(props.updateState);
    }
    dispatch(actions.getHotelGroupList());
    dispatch(
      actions.getConstantsData([
        CONSTANT_FIELDS.CONTRACT_TYPE,
        CONSTANT_FIELDS.HOTEL_TYPE,
        CONSTANT_FIELDS.PMS,
        CONSTANT_FIELDS.SUBSCRIPTION_TYPE,
      ])
    );
  }, []);

  let states = useSelector((state) => state.constants.states[idCountry]);

  if (idCountry === undefined || idCountry === null) {
    states = [];
  }

  useEffect(() => {
    if (props.sendOldHotelValues !== undefined) {
      setCountryId(
        props.sendOldHotelValues.country_id === null
          ? undefined
          : props.sendOldHotelValues.country_id
      );
    }
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry]);

  let setcountry = (e) => {
    setCountryId(e);
  };

  let constantData = useSelector((state) => state.constants.constants);
  let hotel_group_list = useSelector(
    (state) => state.constants.hotel_group_list
  );
  let countries = useSelector((state) => state.constants.countries);

  let giveDropdownData = (field) => {
    return constantData[field];
  };

  let hotelGrpFromProp = {
    hotel_group_id:
      props.param === undefined
        ? undefined
        : parseInt(props.param.queryParamId),
  };
  let hotelGrpId = props.param === undefined ? null : props.param.queryParamId;

  return (
    <div>
      {HotelVisible.heading === true && <h5>Basic information</h5>}
      {HotelVisible.name === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>
                Hotel name <span style={{ color: "red" }}>*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={props.updateform}
                isInvalid={
                  props.sendOldHotelValues === undefined ||
                  props.sendOldHotelValues.name === undefined
                    ? false
                    : props.sendOldHotelValues.name.length < 2 ||
                      props.sendOldHotelValues.name.length > 100
                    ? true
                    : false
                }
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.name
                }
                minLength={2}
                maxLength={100}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.address_zipcode === true && (
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>
                Address <span style={{ color: "red" }}>*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                onChange={props.updateform}
                isInvalid={
                  props.sendOldHotelValues === undefined ||
                  props.sendOldHotelValues.address === undefined
                    ? false
                    : props.sendOldHotelValues.address.length < 1 ||
                      props.sendOldHotelValues.address.length > 254
                    ? true
                    : false
                }
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.address
                }
                minLength={1}
                maxLength={255}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 255, Minimum limit is 1)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>
                Zipcode <span style={{ color: "red" }}>*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                name="zip_code"
                isInvalid={
                  props.sendOldHotelValues === undefined ||
                  props.sendOldHotelValues.zip_code === undefined
                    ? false
                    : props.sendOldHotelValues.zip_code.length < 1 ||
                      props.sendOldHotelValues.zip_code.length > 20
                    ? true
                    : false
                }
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.zip_code
                }
                onChange={props.updateform}
                minLength={1}
                maxLength={20}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 20, Minimum limit is 1)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.state_country === true && (
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>
                Country <span style={{ color: "red" }}>*</span>{" "}
              </Form.Label>
              {/* <Form.Control type="text" name="country" defaultValue={(props.sendOldHotelValues===undefined)?(null):(props.sendOldHotelValues.country)} placeholder="United State" onChange = {props.updateform}></Form.Control> */}
              <SingleDropDown
                val={countries}
                inpName={"country_id"}
                sendOldHotelValues={props.sendOldHotelValues}
                updateDropdown={props.updateform}
                req={true}
                setCountry={setcountry}
              ></SingleDropDown>
            </Form.Group>
          </Col>
          {idCountry !== undefined && (
            <Col md={3}>
              <Form.Group>
                <Form.Label>STATE/PROVINCE</Form.Label>
                {/* <Form.Control type="text" name="state" placeholder="California" defaultValue={(props.sendOldHotelValues===undefined)?(null):(props.sendOldHotelValues.state)} onChange = {props.updateform}></Form.Control> */}
                <SingleDropDown
                  val={states}
                  inpName={"state_id"}
                  sendOldHotelValues={props.sendOldHotelValues}
                  updateDropdown={props.updateform}
                  req={false}
                ></SingleDropDown>
              </Form.Group>
            </Col>
          )}
          {idCountry !== undefined && (
            <Col md={3}>
              <Form.Group>
                <Form.Label>
                  City <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address_city"
                  onChange={props.updateform}
                  isInvalid={
                    props.sendOldHotelValues === undefined ||
                    props.sendOldHotelValues.address_city === undefined
                      ? false
                      : props.sendOldHotelValues.address_city.length < 1 ||
                        props.sendOldHotelValues.address_city.length > 100
                      ? true
                      : false
                  }
                  value={
                    props.sendOldHotelValues === undefined
                      ? ""
                      : props.sendOldHotelValues.address_city
                  }
                  minLength={1}
                  maxLength={101}
                ></Form.Control>
              </Form.Group>
            </Col>
          )}
        </Row>
      )}
      {HotelVisible.cluster === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Cluster</Form.Label>
              <Form.Control
                type="text"
                name="cluster"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.cluster
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.area === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Area</Form.Label>
              <Form.Control
                type="text"
                name="area"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.area
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.region === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Region</Form.Label>
              <Form.Control
                type="text"
                name="region"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.region
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.division === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Division</Form.Label>
              <Form.Control
                type="text"
                name="division"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.division
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.brand === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type="text"
                name="brand"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.brand
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.hotel_group === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>HOTEL GROUP</Form.Label>
              <SingleDropDown
                val={hotel_group_list}
                inpName={"hotel_group_id"}
                sendOldHotelValues={
                  isNaN(hotelGrpFromProp.hotel_group_id)
                    ? props.sendOldHotelValues
                    : hotelGrpFromProp
                }
                updateDropdown={props.updateform}
                req={false}
                disabled={hotelGrpId !== null ? true : props.disabled}
              ></SingleDropDown>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.contract_type_id === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Contract Type</Form.Label>
              <SingleDropDown
                val={giveDropdownData(CONSTANT_FIELDS.CONTRACT_TYPE)}
                inpName={"contract_type_id"}
                sendOldHotelValues={props.sendOldHotelValues}
                updateDropdown={props.updateform}
                req={false}
              >
                {" "}
              </SingleDropDown>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.hotel_type_id === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>
                Hotel Type<span style={{ color: "red" }}>*</span>
              </Form.Label>
              {/* <Form.Control type="text" name="hotel_type" placeholder="City hotel" onChange={onChangeHandleInputChange} required></Form.Control> */}
              <SingleDropDown
                val={giveDropdownData(CONSTANT_FIELDS.HOTEL_TYPE)}
                inpName={"hotel_type_id"}
                sendOldHotelValues={props.sendOldHotelValues}
                updateDropdown={props.updateform}
                req={true}
              ></SingleDropDown>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.pms_id === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>
                PMS<span style={{ color: "red" }}>*</span>
              </Form.Label>
              {/* <Form.Control type="text" name="pms" placeholder="Opera" onChange={onChangeHandleInputChange} required></Form.Control> */}
              <SingleDropDown
                val={giveDropdownData(CONSTANT_FIELDS.PMS)}
                inpName={"pms_id"}
                sendOldHotelValues={props.sendOldHotelValues}
                updateDropdown={props.updateform}
                req={true}
              ></SingleDropDown>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.total_rooms_count === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Total Rooms</Form.Label>
              <Form.Control
                type="number"
                name="total_rooms_count"
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.total_rooms_count
                }
                onChange={props.updateform}
              ></Form.Control>
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default HotelsBasicInformation;
