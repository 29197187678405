import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import actions from '../../store/actions/actions'
import PaginationComponent from '../Common/PaginationComponent'
import SearchComponents from '../Common/SearchComponents'
import TableComponent from '../Common/TableComponent'

const HotelList = (props) => {
    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(actions.setSearchHotel(""))
      dispatch(actions.setUserSearch(""))
     
    },[])

    let total_count = useSelector(state => state.hotelgroup.totalCount)

    const { id } = useParams()
    
    let hotelByHotelGrouplist = useSelector(state => state.hotelgroup.hotels)
    let mapper = []
    const hotelListCommonDetails = useSelector(
      (state) => state?.common
    );
    
  const [pageNo, setPageNo] = useState(hotelListCommonDetails.hotelListPageNo);
  const [sortingColumnName, setSortingColumnName] = useState(hotelListCommonDetails.hotelColumnName);
  const [sortColumns, setSortColumns] = useState([hotelListCommonDetails.hotelColumnName]);
  const sortColumn = useSelector(
    (state) => state?.common.sortForHotel
  );
  const searchHotelGroupHotel = useSelector((state)=>state?.common?.hotelGroupHotelSearch)
  const [sort, setSort] = useState(sortColumn);


    hotelByHotelGrouplist.forEach((itr, i) => {
        let temp_map = {
            'id' : itr['id'],
            'HOTEL NAME': itr['name'],
            'EMAIL' :(itr['contact_info_user'] === null || itr['contact_info_user'] === undefined)?(""):itr['contact_info_user']['email'],
            'COUNTRY' : (itr['country']=== null || itr['country']=== undefined)?(''):(itr['country']['name']),
            'SUBSCRIPTION TYPE': ( itr['finiancial_aggrement']===null || itr['finiancial_aggrement']===undefined || itr['finiancial_aggrement']['type_of_subscription_constant_value'] === undefined || itr['finiancial_aggrement']['type_of_subscription_constant_value']===null)?(""):itr['finiancial_aggrement']['type_of_subscription_constant_value']['value'],
            'MONTHLY FEE(€)' : <NumberFormat
                                value={(itr['finiancial_aggrement']===null || itr['finiancial_aggrement']===undefined ||  itr['finiancial_aggrement']['amount'] === undefined || itr['finiancial_aggrement']['amount']===null)?(""):itr['finiancial_aggrement']['amount']?.toLocaleString("en",{useGrouping: false,minimumFractionDigits: 2})}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
            
        }
        mapper.push(temp_map)
    })


    let edit_row = (event)=>{
        props.history.push('/hotel/update/'+ event.target.id)
    }
    
    const [searchKey, setSearchKey] = useState(searchHotelGroupHotel || "");
    
    let columnDataMap = {
        "HOTEL NAME": "name",
        "HOTEL GROUP": "hotel_group_name",
        EMAIL: "email",
        "SUBSCRIPTION TYPE": "type_of_subscription",
        "MONTHLY FEE(€)": "amount",
        "COMMISSION%": "commision_percentage",
        COUNTRY: "country_name",
      };
      useEffect(() => {
        if (hotelListCommonDetails) {
          //set current page no 
          setPageNo(hotelListCommonDetails.hotelListPageNo)
        }
    
      }, [hotelListCommonDetails]);
      useEffect(() => {
        if (searchKey === "") {
          if (sortingColumnName === "") {
            
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5));
          } else {
            if (sort[sortingColumnName]) {
              dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[sortingColumnName],"DESC"));
              
            } else {
                dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[sortingColumnName],"ASC"))
            }
          }
        }
        if (searchKey !== "") {
          if (sortingColumnName != "") {
            if (sort[sortingColumnName]) {
                dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[sortingColumnName],"DESC",searchKey))
            
            } else {
                dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[sortingColumnName],"ASC",searchKey))
            }
          } else {
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, "","",searchKey))
            
          }
        }
      }, [pageNo]);
    
      let sortingClick = (event) => {
        setSortColumns([event.target.innerText.trim()]);
        try {
          if (sort === "") {
            setSort({ ...sort, [event.target.innerText.trim()]: true });
          } else {
            setSort({
              ...sort,
              [event.target.innerText.trim()]: !sort[event.target.innerText.trim()],
            });
          }
        } catch (error) {
          setSort({ ...sort, [event.target.innerText.trim()]: true });
        }
        /* TODO need an api call over here !!! */
        setSortingColumnName(event.target.innerText.trim());
        if (sort[event.target.innerText.trim()]) {
          if (searchKey != "") {
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[event.target.innerText.trim()],"ASC",searchKey))
            
          } else {
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[event.target.innerText.trim()],"ASC",""))
            
          }
        } else if (!sort[event.target.innerText.trim()]) {
          if (searchKey != "") {
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[event.target.innerText.trim()],"DESC",searchKey))
            
          } else {
            dispatch(actions.getHotelsFromHotelGroup(id,pageNo, 5, columnDataMap[event.target.innerText.trim()],"DESC"))
            
          }
        }
      };

      let searchFun = (searchFilter = "") => {
        setPageNo(1);
        dispatch(actions.setSearchHotelGroupHotel(searchFilter))
        if (pageNo == 1) {
          if (sortingColumnName !== "") {
            if (sort[sortingColumnName]) {
              dispatch(actions.getHotelsFromHotelGroup(id,1, 5, columnDataMap[sortingColumnName],"DESC",searchFilter.replace(",", "")))
              
            } else {
              dispatch(actions.getHotelsFromHotelGroup(id,1, 5, columnDataMap[sortingColumnName],"ASC",searchFilter.replace(",", "")))
             
            }
          } else {
            dispatch(actions.getHotelsFromHotelGroup(id,1, 5, "","",searchFilter.replace(",", "")))
           
          }
        }
        setSearchKey(searchFilter.replace(",", ""));
        
      };
    

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <SearchComponents 
                            AssignButtonClick={props.AssignButtonClick} 
                            modalShow={props.modalShow} 
                            buttonLable={props.buttonLable} 
                            hotelAssign={props.hotelAssign} 
                            queryParamId = {props.queryParamId} 
                            search={searchFun}
                            commonSearchTerm={searchHotelGroupHotel}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TableComponent type={'update_list_table'} mapper={mapper} action = {{edit_row}} sort={{ sortColumns, sort, sortingClick }}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaginationComponent pageNo={pageNo} totalCount={Math.ceil(total_count / 5)} onChange={setPageNo} />
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}
export default HotelList;
