import actions from '../actions/actions'

const initialState = {
    page: 1,
    limit: 10,
    success: false,
    isLoading: false,
    created: false,
    updated: false,
    sortBy: "ASC",
    sortColumnName: false,

    hotelColumnName: "HOTEL NAME",
    hotelSortColumnName: false,
    hotelSortBy: "ASC",
    sortForHotel: { "HOTEL NAME": false },
    hotelListPageNo: 1,
    hotelSearch:"",

    hotelGroupColumnName: "HOTEL GROUP",
    hotelGroupSortColumnName: false,
    hotelGroupSortBy: "ASC",
    sortForHotelGroup: { "HOTEL GROUP": false },
    hotelGroupListPageNo: 1,
    hotelGroupSearch:"",
    hotelGroupHotelSearch:"",

    userColumnName: "USER NAME",
    userSortColumnName: false,
    userSortBy: "ASC",
    sortForUser: { "USER NAME": false },
    userListPageNo: 1,
    userSearch: ""

}
export const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_HOTEL_SORT: {
            return {
                ...state,
                hotelColumnName: action.sortColumnName,
                hotelSortColumnName: action.sortName,
                hotelSortBy: action.sortBy,
                sortForHotel: {
                    ...state.sortForHotel, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_HOTEL_GROUP_SORT: {
            return {
                ...state,
                hotelGroupColumnName: action.sortColumnName,
                hotelGroupSortColumnName: action.sortName,
                hotelGroupSortBy: action.sortBy,
                sortForHotelGroup: {
                    ...state.sortForHotelGroup, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_USER_SORT: {
            return {
                ...state,
                userColumnName: action.sortColumnName,
                userSortColumnName: action.sortName,
                userSortBy: action.sortBy,
                sortForUser: {
                    ...state.sortForUser, [action.sortColumnName]: action.sort
                }
            }
        }
        case actions.SET_HOTEL_LIST_PAGE_NO: {
            return {
                ...state,
                hotelListPageNo: action?.page,
            }
        }

        case actions.SET_HOTEL_GROUP_LIST_PAGE_NO: {
            return {
                ...state,
                hotelGroupListPageNo: action?.page,
            }
        }
        case actions.SET_USER_LIST_PAGE_NO: {
            return {
                ...state,
                userListPageNo: action?.page,
            }
        }
        case actions.RESET_HOTEL_LIST: {
            return {
                ...state,
                hotelColumnName: "HOTEL NAME",
                hotelSortColumnName: false,
                hotelSortBy: "ASC",
                sortForHotel: { "HOTEL NAME": false },
                hotelListPageNo: 1,
            }
        }
        case actions.RESET_HOTEL_GROUP_LIST: {
            return {
                ...state,
                hotelGroupColumnName: "HOTEL GROUP",
                hotelGroupSortColumnName: false,
                hotelGroupSortBy: "ASC",
                sortForHotelGroup: { "HOTEL GROUP": false },
                hotelGroupListPageNo: 1,
            }
        }
        case actions.RESET_SORT_BY: {
            return initialState
        }

        case actions.SET_SEARCH_HOTEL:{
            return{
                ...state,
                hotelSearch: action?.searchTerm || ""
            }
        }

        case actions.SET_SEARCH_HOTEL_GROUP:{
            return{
                ...state,
                hotelGroupSearch: action?.searchTerm || ""
            }
        }

        case actions.SET_SEARCH_USER:{
            return{
                ...state,
                userSearch: action?.searchTerm || ""
            }
        }
        
        case actions.SET_SEARCH_HOTEL_GROUP_HOTEL:{
            return{
                ...state,
                hotelGroupHotelSearch: action?.searchTerm || ""
            }
        }

        default: {
            return state

        }
    }
}