import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
import actions from '../../store/actions/actions'
import { Utils } from "../../utils/utils"
import ModalCopmponents from '../Common/ModalComponent'
import ModalTableComponent from '../Common/ModalTableComponent.js'
import HotelsByHotelGroup from './HotelsByHotelGroup'


const HotelAssignforHotelGroup = (props) => {
    const dispatch = useDispatch()
    const [modalShow, setModalShow] = useState(false);
    const [hotelIDForAssigntToHotelGroup, setHotelIDForAssigntToHotelGroup] = React.useState([]);
    const { id } = useParams()
    const assignHotelforHotelGroup = () => {
        setModalShow(false)
        dispatch(actions.assignHotelForHotelGroup(id,{'hotels':Object.keys(hotelIDForAssigntToHotelGroup)}))
    }
    const hiddenIconAsPerRole = (path) => {
        const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
        return allowedPagesAccess;
      }

    const getHotelId=(e)=>{
        
        if(e.target.checked){

            setHotelIDForAssigntToHotelGroup({...hotelIDForAssigntToHotelGroup,[e.target.id]:e.target.value})
        }else{
            if(hotelIDForAssigntToHotelGroup[e.target.id]){
                delete hotelIDForAssigntToHotelGroup[e.target.id]
                setHotelIDForAssigntToHotelGroup(hotelIDForAssigntToHotelGroup)
            }
        }

    }


    return (
        <Row>
            <Col>
                <Row>
                    {/* <Col>
                        <SearchComponents 
                            AssignButtonClick={setModalShow} 
                            modalShow={modalShow} 
                            buttonLable={'+ Add a new hotel'} 
                            hotelAssign={true} 
                            queryParamId = {props.queryParamId} 
                            search={searchFun}
                        />
                    </Col> */}
                </Row>
                <Row>
                    <Col>
                        <HotelsByHotelGroup {...props} 
                            
                            AssignButtonClick={setModalShow} 
                            modalShow={modalShow} 
                            buttonLable={'+ Add new hotel'} 
                            hotelAssign={true} 
                            queryParamId = {props.queryParamId} 
                            
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ModalCopmponents show={modalShow} onHide={() => setModalShow(false)} clickonComfirmButton={assignHotelforHotelGroup} header={"Select hotels from the list"} body={<ModalTableComponent hotelId={getHotelId}></ModalTableComponent>} />
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}
export default HotelAssignforHotelGroup