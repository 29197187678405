import { Table,Form, Row, Col} from 'react-bootstrap';
import { useDispatch,useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import actions from '../../store/actions/actions'


const ModalTableComponent = (props)=>{
    const dispatch = useDispatch()
    let orphanHotelList = useSelector(state => state.hotelgroup.orphanHotels)
    useEffect(() => {
        dispatch(actions.getOrphanHotels())
    }, []);

    const sendCheckeddata =( e)=>{

        props.hotelId(e)
    }

    return (
        <Table borderless responsive striped>
            <thead>
                <th className="table__th">HOTEL NAME</th>
            </thead>
            <tbody>
            { 
             orphanHotelList.map((rows, index) => (
                <tr>
                     
                       
                            <td>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label={rows.hotels_ts[0].name} id={rows.id} value={rows.hotels_ts[0].name}  onClick={sendCheckeddata}/>
                                </Form.Group>
                            </td>
                       
                   
                </tr>
                 ))
                 }
            </tbody>
        </Table>
    )
}

export default ModalTableComponent;