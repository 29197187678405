import actions from "../actions/actions";
import { ToastService } from "./../../services/toast.service";

const initialState = {
  hotels: [],
  page: 1,
  limit: 10,
  result: false,
  totalCount: 0,
  hotelDetails: {},
  pms: [],
  isFormDataFilled: false,
  isHotelAssigned: false,
  pmsList: [],
  updated: false,
  hotelDetails: false,
  pms_hotel_id: false,
};

export const hotelsReducer = (state = initialState, action) => {
  const toast = new ToastService();
  switch (action.type) {
    case actions.FETCH_HOTELS_SUCESS: {
      return {
        ...state,
        hotels: action.data.records,
        page: action.data.page,
        limit: action.data.limit,
        totalCount: action.data.totalCount,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FETCH_HOTELS_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.ADD_HOTELS_SUCESS: {
      toast.onSuccess("Hotel Added Successfully");
      return {
        ...state,
        result: {
          msg: "Hotel Added Successfully",
          success: true,
          created: true,
        },
      };
    }
    case actions.ADD_HOTELS_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
          created: false,
        },
      };
    }
    case actions.FETCH_HOTEL_DETAILS_SUCESS: {
      return {
        ...state,
        hotelDetails: action.data,
        isHotelAssigned: false,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FETCH_HOTEL_DETAILS_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.UPDATE_HOTEL_DETAILS_SUCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: true,
          update: true,
        },
      };
    }
    case actions.UPDATE_HOTEL_DETAILS_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
          update: false,
        },
      };
    }
    case actions.UPDATE_HOTEL_STATUS_SUCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.UPDATE_HOTEL_STATUS_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.CLEAR_HOTEL_LIST: {
      return initialState;
    }
    case actions.CLEAR_HOTEL_BY_ID: {
      return {
        ...state,
        hotelDetails: false,
      };
    }

    case actions.GET_PMS_SUCCESS: {
      return {
        ...state,
        pms: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.GET_PMS_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.ADD_PMS_FORM_DATA:
    case actions.ASSIGN_PMS_HOTEL: {
      return {
        ...state,
        isAssignHotel: true,
      };
    }

    case actions.ADD_PMS_FORM_DATA_SUCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        isFormDataFilled: true,
        pms_hotel_id: actions?.data?.pms_hotel_id,
        result: {
          msg: action?.msg,
          success: true,
        },
      };
    }
    case actions.ADD_PMS_FORM_DATA_FAILED: {
      return {
        ...state,
        isAssignHotel: false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.GET_PMS_HOTELS_BY_ID_SUCCESS: {
      return {
        ...state,
        pmsList: action.data,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }

    case actions.GET_PMS_HOTELS_BY_ID_FAILED: {
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.ASSIGN_PMS_HOTEL_SUCCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        isHotelAssigned: true,
        result: {
          msg: actions?.msg,
          success: true,
        },
      };
    }

    case actions.ASSIGN_PMS_HOTEL_FAILED: {
      return {
        ...state,
        isAssignHotel: false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.UPDATE_PMS_INTEGRATION_SUCCESS: {
      return {
        ...state,
        success: true,
        updated: true,
      };
    }
    case actions.UPDATE_PMS_INTEGRATION_FAILED: {
      return {
        ...state,
        result: {
          msg: action?.msg,
        },
        success: false,
        updated: false,
      };
    }

    case actions.SYNC_CATEGORIES_SUCCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          msg: action?.msg,
          success: true,
        },
      };
    }

    case actions.SYNC_CATEGORIES_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.SYNC_ROOMS_SUCCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          msg: action?.msg,
          success: true,
        },
      };
    }

    case actions.SYNC_ROOMS_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.SYNC_PRODUCTS_SUCCESS: {
      toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          msg: action?.msg,
          success: true,
        },
      };
    }

    case actions.SYNC_PRODUCTS_FAILED: {
      toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.RESET_PMS_LIST_ARRAY: {
      return {
        ...state,
        pmsList: [],
        isFormDataFilled: false,
      };
    }

    default: {
      return state;
    }
  }
};
