import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./close.svg";
 
const SearchComponents = (props) => {
  let link = `/hotel/create?queryParamId=${props.queryParamId}`;
  const [searchValue, setSearchValue] = useState(props?.commonSearchTerm || "");
  const [isEnterPressed, setEnterPressed] = useState(false);
 
  const setSearch = (filterValue = searchValue) => {
    props.search(filterValue);
  };
 
  return (
    <div className="w-100">
        <Form.Group>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchValue}
              onKeyUp={(event) => {
                if (+event?.keyCode === 13) {
                  setEnterPressed(true);
                  setSearch();
                }
              }}
              onChange={(event) => {
                setSearchValue(event?.target?.value);
                if (!event?.target?.value && isEnterPressed) {
                  setEnterPressed(false);
                  setSearch("");
                }
              }}
            ></Form.Control>
            {searchValue && (
              <InputGroup.Text
                className="pointer inputGroupClose"
                onClick={() => {
                  if (isEnterPressed) {
                    setEnterPressed(false);
                    setSearchValue("");
                    setSearch("");
                  } else {
                    setSearchValue("");
                    setSearch("");
                  }
                }}
                
              >
                <CloseIcon />
              </InputGroup.Text>
            )}
          </InputGroup>
        </Form.Group>
     
        <Form.Group>
          {props.hotelAssign ? (
            <Link to={link}>
              <Button>{props.buttonLable}</Button>
            </Link>
          ) : (
            ""
          )}
        </Form.Group>
        </div>
  );
};
export default SearchComponents;