import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import DropDown from "../Common/DropDown";
import SingleDropDown from "../Common/singleDropDown";

const HotelGroupBasicInformation = (props) => {
  let [HotelGroupVisible, setHotelGroupVisible] = useState({
    heading: true,
    name: true,
    address_street: true,
    address_zipcode: true,
    address_state: true,
    address_country: true,
    language: true,
  });

  const [idCountry, setCountryId] = useState(undefined);
  const [hotelgrpExist, setHotelGrpExist] = useState(false);
  const [langDropdownData, setLangDropdownData] = useState([]);

  const dispatch = useDispatch();
  const toast = new ToastService();

  try {
    useEffect(() => {
      if (props.updateState != undefined) {
        setHotelGroupVisible(props.updateState);
      }

      dispatch(actions.getLanguages());
    }, [props.updateState]);
  } catch (error) {
    console.log(error);
  }

  let languages = useSelector((state) => state.constants.languages);
  useEffect(() => {
    if (languages) {
      setLangDropdownData(
        [...languages].map((langRow) => {
          if (langRow.id === 1) return { ...langRow, isFixed: true };
          return langRow;
        })
      );
    }
  }, [languages]);

  let states = useSelector((state) => state.constants.states[idCountry]);
  if (idCountry === undefined) {
    states = [];
  }

  useEffect(() => {
    if (props.oldHotelValues !== undefined) {
      setCountryId(props.oldHotelValues.address_country_id);
    }
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry]);

  let setcountry = (e) => {
    setCountryId(e);
  };

  let countries = useSelector((state) => state.constants.countries);

  const checkHotelGroup = (event) => {
    if (event.target.value) {
      dispatch(actions.checkHotelGroup(event.target.value));
    }
  };
  const hotelGroupExist = useSelector(
    (state) => state.hotelgroup.result.existing
  );
  useEffect(() => {
    if (hotelGroupExist) {
      setHotelGrpExist(hotelGroupExist);
      toast.onWarning("HotelGroup Already exists");
    } else {
      setHotelGrpExist(hotelGroupExist);
    }
  }, [hotelGroupExist]);

  return (
    <div>
      {HotelGroupVisible.heading === true && <h5>Basic Information</h5>}
      {/* Heading */}
      <Row>
        <Col md={9} sm={6}>
          {HotelGroupVisible.name === true && (
            <Form.Group>
              <Form.Label>
                Hotel group name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues.name === undefined
                    ? false
                    : props.oldHotelValues.name.length < 2 ||
                      props.oldHotelValues.name.length > 100 ||
                      hotelgrpExist
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues.name
                }
                name="name"
                onChange={props.updateform}
                onBlur={checkHotelGroup}
                minLength={2}
                maxLength={100}
              ></Form.Control>
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        {/* Address Info */}
        <Col md={6}>
          {HotelGroupVisible.address_street === true && (
            <Form.Group>
              <Form.Label>
                Headquaters address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues.address_street === undefined
                    ? false
                    : props.oldHotelValues.address_street.length < 2 ||
                      props.oldHotelValues.address_street.length > 500
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues.address_street
                }
                name="address_street"
                onChange={props.updateform}
                minLength={2}
                maxLength={500}
                /* required */
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 500, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
        <Col md={3}>
          {HotelGroupVisible.address_zipcode === true && (
            <Form.Group>
              <Form.Label>
                Zipcode <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues.address_zipcode === undefined
                    ? false
                    : props.oldHotelValues.address_zipcode.length < 1 ||
                      props.oldHotelValues.address_zipcode.length > 20
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues.address_zipcode
                }
                name="address_zipcode"
                onChange={props.updateform}
                minLength={2}
                maxLength={20}
                /* required */
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 20, Minimum limit is 1)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          {HotelGroupVisible.address_country === true && (
            <Form.Group>
              <Form.Label>
                Country <span style={{ color: "red" }}>*</span>
              </Form.Label>
              {/* <Form.Control type="text" placeholder="United state" name="address_country" defaultValue={(props.oldHotelValues===undefined)?(null):(props.oldHotelValues.address_country)} onChange={props.updateform} required></Form.Control> */}
              <SingleDropDown
                val={countries}
                inpName={"address_country_id"}
                sendOldHotelValues={props.oldHotelValues}
                updateDropdown={props.updateform}
                req={true}
                setCountry={setcountry}
              ></SingleDropDown>
            </Form.Group>
          )}
        </Col>
        {HotelGroupVisible.address_state === true &&
          idCountry !== null &&
          idCountry !== undefined && (
            <Col md={4}>
              <Form.Group>
                <Form.Label>State/Province </Form.Label>
                {/* <Form.Control type="text" placeholder="California" name="address_state" defaultValue={(props.oldHotelValues===undefined)?(null):(props.oldHotelValues.address_state)} onChange={props.updateform} required></Form.Control> */}
                <SingleDropDown
                  val={states}
                  inpName={"address_state_id"}
                  sendOldHotelValues={props.oldHotelValues}
                  updateDropdown={props.updateform}
                  req={false}
                ></SingleDropDown>
              </Form.Group>
            </Col>
          )}
        {HotelGroupVisible.address_state === true &&
          idCountry !== null &&
          idCountry !== undefined && (
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  City <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={
                    props.oldHotelValues === undefined ||
                    props.oldHotelValues.address_city === undefined
                      ? false
                      : props.oldHotelValues.address_city.length < 1 ||
                        props.oldHotelValues.address_city.length > 100
                      ? true
                      : false
                  }
                  value={
                    props.oldHotelValues === undefined
                      ? ""
                      : props.oldHotelValues.address_city
                  }
                  name="address_city"
                  min={1}
                  max={101}
                  onChange={props.updateform}
                ></Form.Control>
              </Form.Group>
            </Col>
          )}
      </Row>
      {HotelGroupVisible.address_state === true && langDropdownData && (
        <DropDown
          lang={langDropdownData}
          updateform={props.updateform}
          sendOldHotelValues={props.oldHotelValues}
          inpName={"language_ids"}
          req={true}
        ></DropDown>
      )}
    </div>
  );
};

export default HotelGroupBasicInformation;
