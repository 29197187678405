const HotelValidator = (data, type) => {
  let keyValidate = {
    name: "",
    cluster: "",
    area: "",
    region: "",
    division: "",
    brand: "",
    hotel_group_id: "",
    contract_type_id: "",
    hotel_type_id: "",
    pms_id: "",
    lang_id: "",
    address: "",
    state_id: "",
    country_id: "",
    zip_code: "",
    additional_notes: "",
    finiancial_aggrement_id: "",
    address_city: "",
    invoice_info_id: "",
    financial_agreement: [
      "contract_start_date",
      "contract_end_date",
      "is_contract_no_end_date",
      "type_of_subscription",
      "amount",
      "discount_percentage",
      "commision_percentage",
    ],
    invoice_info: [
      "company_name",
      "billing_address",
      "zip_code",
      "state_id",
      "country_id",
      "vat_number",
      "address_city",
    ],
    contact_information: [
      "first_name",
      "middle_name",
      "last_name",
      "email",
      "phone_number",
    ],
  };
  let tempObj = {
    financial_agreement: {},
    invoice_info: {},
    contact_information: {},
  };
  try {
    Object.keys(keyValidate).forEach((v, i) => {
      if (
        (data === undefined || data[v] === undefined || data[v] === "") &&
        typeof keyValidate[v] !== "object"
      ) {
        tempObj[v] = "";
      } else if (typeof keyValidate[v] === "object") {
        keyValidate[v].forEach((v1, i1) => {
          if (
            data[v] === undefined ||
            data[v][v1] === undefined ||
            data[v][v1] === ""
          ) {
            tempObj[v][v1] = "";
          } else {
            tempObj[v][v1] = data[v][v1];
          }
        });
      } else {
        tempObj[v] = data[v];
      }
    });
    return tempObj;
  } catch (err) {
    return err;
  }
};

export default HotelValidator;
