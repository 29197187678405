import React, { useEffect, useState } from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { Utils } from "../../utils/utils";
import ActiveStatusButton from "../Common/ActiveStatusButton";
import actions from "./../../store/actions/actions";
import Breadcrumbs from "./../Common/Breadcrumbs";
import PaginationComponent from "./../Common/PaginationComponent";
import SearchComponents from "./../Common/SearchComponents";
import TableComponent from "./../Common/TableComponent";

const UserList = (props) => {
  const breadcrumbs = [{ path: "/user/list", title: "Users" }];
  //to manage roles and permission
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const history = useHistory();
  const [totalCount, setTotalCount] = useState(null);
  const [tableMapper, setTableMapper] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setSearchHotel(""));
    dispatch(actions.setSearchHotelGroupHotel(""));
    dispatch(actions.setSearchHotelGroup(""));
  }, []);

  const [searchKey, setSearchKey] = useState("");
  const userList = useSelector((state) => state?.user?.userList);
  const userSearchTerm = useSelector((state) => state?.common?.userSearch);
  const [searchTerm, setSearchTerm] = useState(userSearchTerm || "");

  const userListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(userListCommonDetails.userListPageNo);
  const [sortingColumnName, setSortingColumnName] = useState(
    userListCommonDetails.userColumnName
  );
  const [sortColumns, setSortColumns] = useState([
    userListCommonDetails.userColumnName,
  ]);
  const [sortOrder, setSortOrder] = useState(userListCommonDetails.userSortBy);

  const sortColumn = useSelector((state) => state?.common.sortForUser);
  const [sort, setSort] = useState(sortColumn);
  const columnDataMap = {
    "USER NAME": "username",
    EMAIL: "email",
    ROLE: "role",
    LANGUAGE: "code",
  };
  // const sortColumns = [
  //    "USERNAME", "EMAIL","ROLE","LANGUAGE"
  // ];

  useEffect(() => {
    if (userListCommonDetails) {
      //set current page no
      setPageNo(userListCommonDetails.userListPageNo);
    }
  }, [userListCommonDetails]);

  const onSearchEvent = (searchTxt = "") => {
    dispatch(actions.setUserSearch(searchTxt));
    setSearchTerm(searchTxt);
    let updateSortOrder = "";
    const sortBy = columnDataMap[sortColumns];
    // if(searchTxt.length===0){
    //   setSort({ ...sort, 'USER NAME':false,'EMAIL':false,LANGUAGE:false});
    //   updateSortOrder ="ASC"
    //
    // }
    setPageNo(1);
    getTableInfo(searchTxt, 1, sortBy, sortOrder, "");
  };
  const sortingClick = (event, headerInfo) => {
    const innerText = event.target.innerText.trim();
    // setSortingColumnName(innerText);
    setSortColumns(innerText);
    try {
      if (!sort && !Object.keys(sort)?.length) {
        setSort({ ...sort, [event.target.innerText.trim()]: true });
      } else {
        setSort({
          ...sort,
          [innerText]: !sort[innerText],
        });
      }
    } catch (error) {
      setSort({ ...sort, [innerText]: true });
    }
    const sortOrder = sort[innerText] ? "ASC" : "DESC";

    setSortOrder(sortOrder);
    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      innerText
    );
  };
  const getTableInfo = (
    searchTerm = "",
    pageNumber = pageNo,
    sortBy = columnDataMap[sortColumns] || "",
    sortOrder = "",
    innerText = "",
    filterType = extractedUserStatusType
  ) => {
    const updatedSort = columnDataMap[innerText];
    const payload = {
      searchKey: searchTerm,
    };

    if (sortOrder) {
      payload.sortBy = sortOrder;
    }

    if (sortBy) {
      payload.sort = sortBy;
    }
    if (updatedSort) {
      payload.sort = updatedSort;
    }

    if (pageNo) {
      payload.page = pageNumber;
    }
    payload.limit = 15;

    if (filterType) {
      payload.type = filterType;
    }

    dispatch(actions.getUsers({ ...payload }));
  };

  // // redirecting to update user
  const edit_row = (event) => {
    props.history.push("/update-user/" + event.target.id);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapTableInfo = (userList) => {
    const mapper = userList?.map((user, i) => {
      const userName =
        `${user.first_name}` +
        ` ${user.middle_name ? user.middle_name : ""}` +
        ` ${user.last_name}`;
      return {
        id: user.id,
        "USER NAME": userName,
        EMAIL: user?.email,
        ROLE: user["e_user_roles.role.name"],
        LANGUAGE: user?.code,
        ACTIVE: (
          <ActiveStatusButton pending={user?.is_active}></ActiveStatusButton>
        ),
      };
    });
    setTableMapper(mapper);
  };

  useEffect(() => {
    getTableInfo(
      searchTerm || "",
      userListCommonDetails.userListPageNo,
      userListCommonDetails.userSortColumnName,
      userListCommonDetails.userSortBy
    );
  }, []);

  useEffect(() => {
    userList?.records ? mapTableInfo(userList?.records) : mapTableInfo([]);
    setTotalCount(userList?.totalCount);
  }, [userList]);

  const updatePagination = (pageNo) => {
    setPageNo(pageNo);
    getTableInfo(
      searchTerm || "",
      pageNo,
      columnDataMap[sortColumns],
      sortOrder,
      ""
    );
  };

  // to filter users based on their status using dropdown
  const userStatusTypes = ["All Users", "Active Users", "Inactive Users"];
  const [selectedUserStatusType, setSelectedUserStatusType] = useState(
    userStatusTypes[0]
  );
  const [extractedUserStatusType, setExtractedUserStatusType] = useState("");

  useEffect(() => {
    const headerInfo = {
      searchKey: "",
      sortBy: "ASC",
      sort: "username",
      page: 1,
    };

    let type = "";
    if (selectedUserStatusType === userStatusTypes[1]) {
      type = "active";
    } else if (selectedUserStatusType === userStatusTypes[2]) {
      type = "deactive";
    }

    setExtractedUserStatusType(type);

    getTableInfo(
      searchTerm,
      pageNo,
      columnDataMap[headerInfo],
      sortOrder,
      "",
      type
    );
  }, [selectedUserStatusType]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col sm={12}>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
      </div>

      <Row>
        <Col sm={12}>
          <h3>Users</h3>
        </Col>
      </Row>

      <div className="content">
        <Row className="mb-3">
          <Col className="mb-md-0 mb-3" xl={5} lg={6} md={6} sm={12} xs={12}>
            <div className="content-box d-sm-flex justify-content-sm-between align-items-sm-center">
              <SearchComponents
                buttonLable={"+ Add New User"}
                addBtn={true}
                path={"/user/create"}
                search={onSearchEvent}
                commonSearchTerm={userSearchTerm}
              />
            </div>
          </Col>
          <Col xl={7} lg={6} md={6} sm={12} xs={12}>
            <div className="right-content d-flex align-items-center justify-content-end">
              <>
                <span className="switch-lable sub-header-text">
                  {selectedUserStatusType}
                </span>
                <Dropdown className="customer-dropdown">
                  <Dropdown.Toggle size="sm" variant="white"></Dropdown.Toggle>
                  <Dropdown.Menu className="dropdownAlignStart">
                    {userStatusTypes.map((type) => (
                      <Dropdown.Item
                        key={type}
                        onClick={() => {
                          setPageNo(1);
                          setSelectedUserStatusType(type);
                        }}
                      >
                        {type}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>

              <span className="switch-lable sub-header-text" />

              {hiddenIconAsPerRole(PRIVATE_ROUTES.create_new_user) ? (
                <Link to={PRIVATE_ROUTES.create_new_user}>
                  <Button className="button-wd">+ Add new user</Button>
                </Link>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <div className="userlist-tbl">
              <TableComponent
                type={
                  hiddenIconAsPerRole(PRIVATE_ROUTES.update_user)
                    ? "update_list_table"
                    : ""
                }
                mapper={tableMapper}
                sort={{ sortColumns, sort, sortingClick }}
                action={{ edit_row }}
                showAction={hiddenIconAsPerRole(PRIVATE_ROUTES.update_user)}
              />
            </div>

            <Row>
              <Col>
                <PaginationComponent
                  pageNo={pageNo}
                  totalCount={Math.ceil(totalCount / 15)}
                  onChange={updatePagination}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UserList;
