import * as authAction from './auth.action';
import * as authrAction from "./authr.action";
import * as constantsActions from './constants.action';
import * as hotelgroupAction from './hotelgroup.action';
import * as hotelAction from './hotels.action';
import * as networkAction from './network.action';
import * as userManagement from "./userManagement.action";

const actions =  {
    ...hotelgroupAction,
    ...hotelAction,
    ...constantsActions,
    ...authAction,
    ...networkAction,
    ...userManagement,
    ...authrAction
}

export default actions