import { call, put, takeLatest } from 'redux-saga/effects';
import ConstantsService from '../../services/Constants';
import actions from '../actions/actions';

const constantsServ = new ConstantsService();

const fetchConstants = async (fields) => {
    try{
        const response = await constantsServ.getConstantsData(fields)
        return response
    }
    catch(error){
        throw error;
    }
   
}

const fetchLanguages = async () => {
    try{
        const response = await constantsServ.getLanguages()
        return response
    }
    catch(error){
        throw error
    }
   
}

const fetchHotelGroupList = async () => {
    try{
        const response = await constantsServ.getHotelGroupList()
        return response
    }
    catch(error){
        throw error;
    }
   
}

const fetchGetCountries = async ()=>{
    try{
        const response = await constantsServ.fetchGetCountries()
        return response
    }
    catch(error){
        throw error;
    }
   
}

const fetchGetStates = async (country_id)=>{
    try{
        const response = await constantsServ.fetchGetStates(country_id)
        return response
    }
   catch(error){
       throw error;
   }
}

function* dispatchGetConstants(action) {
    try {
        let response = yield call(fetchConstants, action.fields)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_CONSTANTS_SUCCESS,
                data: response.data.data,
                msg: response.msg
            })
        } else {
            yield put({
                type: actions.FETCH_CONSTANTS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.FETCH_CONSTANTS_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchGetLanguages() {
    try {
        let response = yield call(fetchLanguages)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_LANGUAGES_SUCCESS,
                data: response.data.data,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.FETCH_LANGUAGES_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.FETCH_LANGUAGES_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}


function* dispatchGetHotelGroupList() {
    try {
        let response = yield call(fetchHotelGroupList)
        if (response.data.success) {
            yield put({
                type: actions.GET_HOTEL_GROUP_LIST_SUCCESS,
                data: response.data.data,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.GET_HOTEL_GROUP_LIST_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_HOTEL_GROUP_LIST_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}


function* dispatchGetCountries() {
    try {
        let response = yield call(fetchGetCountries)
        if (response.data.success) {
            yield put({
                type: actions.GET_COUNTRY_SUCCESS,
                data: response.data.data,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.GET_COUNTRY_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_COUNTRY_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchGetStates(action) {
    try {
        let response = yield call(fetchGetStates, action.country_id)
        if (response.data.success) {
            yield put({
                type: actions.GET_STATES_SUCCESS,
                data: response.data.data,
                country : action.country_id,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.GET_STATES_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_STATES_FAILED,
            msg: error?.response?.data?.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

export function* listenGetConstants() {
    yield takeLatest(actions.GET_CONSTANTS, dispatchGetConstants)
}
export function* listenGetLanguages() {
    yield takeLatest(actions.GET_LANGUAGES, dispatchGetLanguages)
}

export function* listenGetHotelGroupList() {
    yield takeLatest(actions.GET_HOTEL_GROUP_LIST, dispatchGetHotelGroupList)
}

export function* listenGetCountries() {
    yield takeLatest(actions.GET_COUNTRY, dispatchGetCountries)
}

export function* listenGetStates() {
    yield takeLatest(actions.GET_STATES, dispatchGetStates)
}