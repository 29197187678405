import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import Spinner from "./Spiner";

const LanguageDropDown = (props) => {


  const sendDropdownValue = value => {
    props.updateDropdown(value)
  };

  let invalidStyle = {}
  let isInvalid = (props?.touched[props.inpName] && props?.errors[props.inpName])?(true):(false)

  if(isInvalid){

    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: '1px solid red',
        '&:hover': { borderColor: 'red' },
        boxShadow: 'none'
      })
    }
  }
 
  if (props.val!== undefined){
    const options = [];
    props.val.forEach((v,i) => {
      options.push({value:v['id'], label:v['value']})
    });

      return (
            <div>
              
              <Select
                styles={invalidStyle}
                name={props.inpName}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={sendDropdownValue}
                isClearable ={true}
                value={props.values}
              />
            </div>
      );
  }
  else{
      return(
          <Spinner></Spinner>
      )
  }
};

export default LanguageDropDown;
