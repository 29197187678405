import { Modal,Button } from 'react-bootstrap'
import { Row } from 'react-bootstrap';
import {ReactComponent as CloseIcon} from './close.svg'
import { Col } from 'react-bootstrap';
const ModalCopmponents = (props) =>{
    return(
        <Modal {...props} size={props?.size?props?.size:"lg"}  aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="mt-3">
                <Row>
                    <Col md={2}></Col>
                    <Col md={8} className = "alignCenter"> <h5> {props.header} </h5>  </Col>
                    <Col md={2} className="pl-3">
                        <span onClick={props.onHide} className="pointer">
                            <CloseIcon/> 
                         </span>
                    </Col>
                </Row>
            </div>
            <Modal.Body>
                {props.body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.clickonComfirmButton}>Confirm</Button>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default ModalCopmponents