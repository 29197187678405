import Sidebar from "../Common/Sidebar";
import { Col, Row, Container, Button, Dropdown } from "react-bootstrap";
import React, { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as HamburgerSVG } from "../Common/icons/hamburger.svg";
import LoginDropdown from "../Common/LoginDropdown";
const BaseLayout = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  
  const getHeader = () => {
    return (
      <>
        <Row className = "sticky-header">
          <Col md={6} sm={6} xs={6}>
            <div className="mobile-sidebar set-absolute">
              {!sidebar && (
                <div
                  onClick={() => {
                    setSidebar(!sidebar);
                  }}
                >
                  {" "}
                  <HamburgerSVG />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col md={5} sm={5} xs={5} className = "language-setup">
            <div className="set-absolute pt-3">
              <table>
                <tbody>
                  <tr>
                    {/* <td> <Languages onClick = {onLanguageSelect} /> </td> */}
                    <td>
                      <span className="paddingLeft-10">
                        <LoginDropdown/>
                        {/* <Button size="sm" variant="white" >
                                                    <UserSVG />
                                                </Button> */}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={location.pathname === "/" ? 12 : 11}>
            {children}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col md={1} className="sidebar-wraper">
          <Sidebar onChangeSideBar={setSidebar} sidebar={sidebar} />
        </Col>
        <Col
          md={11}
          className={
            sidebar
              ? "mainpage__transform background-setup"
              : "mainpage background-setup"
          }
        >
          {getHeader()}
        </Col>
      </Row>
    </Container>
  );
};
export default BaseLayout;
