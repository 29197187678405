import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import ModalComponentActivateDeactivate from "../Common/ModalComponentActivateDeactivate";
import Spiner from "../Common/Spiner";
import { ReactComponent as Vectors } from "../Common/vectors.svg";
import Breadcrumbs from "./../Common/Breadcrumbs";
import UserBasicInformation from "./UserBasicInformation";
import UserContactInformation from "./UserContactInformation";

const EditUser = (props) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [idCountry, setCountryId] = useState();
  const getUserById = useSelector((state) => state?.user?.userById);
  const countries = useSelector((state) => state.constants.countries);
  const states = useSelector((state) => state.constants.states[idCountry]);
  const [selectedLanguage, setselectedLanguage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [user_name, setUser_name] = useState();
  const [emailId, setEmailId] = useState(null);
  const roles = useSelector((state) => state.authr.roles);
  const [roleList, setRoleList] = useState();
  const isUpdate = useSelector((state) => state.user.isUpdate);
  const [showModal, setshowModal] = useState(false);
  const [countryISO, setCountryISO] = useState();

  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const [formInitialValue, setFormInitialValue] = useState({
    first_name: "",
    email: "",
    middle_name: "",
    last_name: "",
    contact_address_street: "",
    contact_address_zipcode: "",
    contact_address_state_id: "",
    contact_address_country_id: "",
    contact_address_city: "",
    phone_number: "",
    phone_number_country: "",
    language_id: "",
    role_id: "",
  });
  const formSchema = yup.object().shape({
    first_name: yup.string().trim().required("Please Enter"),
    last_name: yup.string().trim().required("Please Enter"),
    contact_address_street: yup.string().trim().required("Please Enter"),
    contact_address_zipcode: yup.string().trim().required("Please Enter"),
    phone_number: yup.string().trim().required("Please Enter"),
    email: yup.string().trim().required("Please Enter"),
    contact_address_country_id: yup.string().required("Please Enter"),
    language_id: yup.string().required("Please Enter"),
    contact_address_city: yup.string().trim().required("Please Enter"),
    role_id: yup.string().required("Please Enter"),
  });
  //const breadcrumbs = [{ "path": "/user/list", "title": "User management" }, { "path": `/user/create`, "title": "Add a new user" }];
  const updateUser = (payload) => {
    dispatch(actions.UpdateUser(id, payload));
  };
  useEffect(() => {
    dispatch(actions.getUserById(id));
    dispatch(actions.getCountries());
    dispatch(actions.getRole());
  }, []);
  const onCancelClick = () => {
    history.push("/user/list");
  };
  useEffect(() => {
    if (getUserById?.id) {
      setEmailId(getUserById.email);
      formikRef.current.setValues({
        first_name: getUserById?.first_name,
        email: getUserById?.email,
        middle_name: getUserById?.middle_name,
        last_name: getUserById?.last_name,
        contact_address_street: getUserById?.contact_address_street,
        contact_address_zipcode: getUserById?.contact_address_zipcode,
        phone_number: getUserById?.phone_number,
        phone_number_country: getUserById?.phone_number_country,
        contact_address_city: getUserById?.contact_address_city,
        language_id: getUserById?.language?.id,
        contact_address_country_id: getUserById?.contact_address_country_id,
        role_id: getUserById.e_user_roles.role_id,
      });
      setselectedLanguage({
        label: getUserById?.language?.code,
        value: getUserById?.language?.id,
      });
      setSelectedCountry({
        label: getUserById?.contact_address_country?.name,
        value: getUserById?.contact_address_country?.id,
      });
      setSelectedState({
        label: getUserById?.contact_address_state?.name,
        value: getUserById?.contact_address_state?.id,
      });
      setCountryId(getUserById?.contact_address_country_id);
      const userName =
        `${getUserById.first_name}` +
        ` ${getUserById.middle_name ? getUserById.middle_name : ""}` +
        ` ${getUserById.last_name}`;
      setUser_name(userName);

      if (roles) {
        const roleList = roles?.find((role) => {
          return role?.id === getUserById?.e_user_roles?.role_id;
        });
        setRoleList({ label: roleList?.name, value: roleList?.id });
      }
    }
  }, [getUserById, roles]);
  const breadcrumbs = [
    { path: "/user/list", title: "Users" },
    {
      path: `/update-user/${props.match.params.id}`,
      title: user_name ? user_name : "",
    },
  ];
  let setcountry = (e, setFieldValue) => {
    if (e) {
      setSelectedCountry(e);
      setCountryId(e.value);
      setFieldValue("contact_address_country_id", e.value);
      setSelectedState(null);
    } else {
      setSelectedCountry(null);
      setSelectedState(null);
      setCountryId(undefined);
      setFieldValue("contact_address_country_id", null);
      setFieldValue("contact_address_city", null);
    }
  };
  useEffect(() => {
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry, countries]);

  useEffect(() => {
    if (isUpdate) {
      history.push("/user/list");
    }
  }, [isUpdate]);

  const updateLanguage = (editorState, setFieldValue) => {
    if (editorState) {
      setselectedLanguage(editorState);
      setFieldValue("language_id", editorState.value);
    } else {
      setselectedLanguage(null);
      setFieldValue("language_id", null);
    }
  };
  const updateState = (editorState, setFieldValue) => {
    if (editorState) {
      setSelectedState(editorState);
      setFieldValue("contact_address_state_id", editorState.value);
    } else {
      setSelectedState(null);
      setFieldValue("contact_address_state_id", null);
    }
  };
  const deactivateUser = () => {
    if (emailId) {
      const payload = { email: emailId, id: id };
      dispatch(actions.deactivateUser(payload));
    }
  };
  const activateUser = () => {
    if (emailId) {
      const payload = { email: emailId, id: id };
      dispatch(actions.activateUser(payload));
    }
  };
  const updateRoles = (editorState, setFieldValue) => {
    if (editorState) {
      setRoleList(editorState);
      setFieldValue("role_id", editorState.value);
    } else {
      setFieldValue("role_id", "");
      setRoleList(null);
    }
  };
  const modalBodyDeactivate = () => (
    <div className="alignCenter">
      <Row>
        <div className="mt-3 mb-3 alignCenter">
          Do you really want to deactivate user?
        </div>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              deactivateUser();
              setshowModal(!showModal);
            }}
          >
            Deactivate
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            onClick={() => {
              setshowModal(!showModal);
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
  const modalBodyActivate = () => (
    <div className="alignCenter">
      <Row>
        <div className="mt-3 mb-3 alignCenter">
          Do you really want to activate user?
        </div>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            variant="primary"
            onClick={() => {
              activateUser();
              setshowModal(!showModal);
            }}
          >
            Activate
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            onClick={() => {
              setshowModal(!showModal);
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6} className="extra-content">
            {/* <tr>
                            <td>
                                <Button variant="white" onClick={() => {
                                    props.history.push('/user/list')
                                }}>
                                    <ArrowLeft />
                                </Button>
                            </td>
                            <td className="p-3"> Back </td>
                        </tr> */}
          </Col>
          <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            <>
              <Dropdown className="customer-dropdown">
                <Dropdown.Toggle size="sm" variant="white">
                  <Vectors />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setshowModal(true);
                    }}
                  >
                    {getUserById?.is_active
                      ? "Deactivate User"
                      : "Activate User"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
            {/* <Button variant="white" onClick={deactivateUser}>
                            <Vectors />
                        </Button> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <h3> Edit user information </h3>
          </Col>
        </Row>
      </div>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        enableReinitialize={false}
        innerRef={formikRef}
        onSubmit={(values) => {
          const payload = {
            ...values,
          };
          updateUser(payload);
        }}
        onerror={(err) => {}}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          submitForm,
          setFieldValue,
        }) => (
          <Form>
            <div className="content">
              {getUserById ? (
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <UserBasicInformation
                          change={handleChange}
                          error={errors}
                          values={values}
                          touched={touched}
                          updateform={handleChange}
                          updateDropdown={(updateContent) => {
                            updateLanguage(updateContent, setFieldValue);
                          }}
                          updateRole={(updateContent) => {
                            updateRoles(updateContent, setFieldValue);
                          }}
                          value={selectedLanguage}
                          selectedRole={roleList}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <UserContactInformation
                          change={handleChange}
                          setCountryISO={setCountryISO}
                          error={errors}
                          values={values}
                          touched={touched}
                          country={countries ? countries : []}
                          states={states ? states : []}
                          updateCountry={(updateContent) => {
                            setcountry(updateContent, setFieldValue);
                          }}
                          updateState={(updateContent) => {
                            updateState(updateContent, setFieldValue);
                          }}
                          updateform={handleChange}
                          idCountry={idCountry}
                          isedit={true}
                          OldCountryVal={selectedCountry}
                          OldStateVal={selectedState}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} sm={6} xs={7}>
                        {hiddenIconAsPerRole(PRIVATE_ROUTES.update_user) ? (
                          <Button
                            variant="primary"
                            variant="primary"
                            onClick={() => {
                              submitForm();
                            }}
                          >
                            Save changes
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col md={3} sm={6} xs={7}>
                        <Button variant="secondary" onClick={onCancelClick}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Spiner />
              )}
            </div>
          </Form>
        )}
      </Formik>
      <ModalComponentActivateDeactivate
        onHide={() => {
          setshowModal(!showModal);
        }}
        header={""}
        size="md"
        show={showModal}
        body={
          getUserById?.is_active ? modalBodyDeactivate() : modalBodyActivate()
        }
      ></ModalComponentActivateDeactivate>
    </React.Fragment>
  );
};

export default EditUser;
