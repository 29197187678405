import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";

const HotelGroupContactInformation = (props) => {
  const [HotelGroupVisible, setHotelGroupVisible] = useState({
    heading: true,
    first_name: true,
    middle_name: true,
    last_name: true,
    email: true,
    role: true,
  });

  const dispatch = useDispatch();
  const [emailExist, setEmailExist] = useState(false);
  try {
    useEffect(() => {
      if (props.updateState != undefined) {
        setHotelGroupVisible(props.updateState);
        dispatch(actions.checkEmail(props.oldHotelValues.email));
      }
    }, [props.updateState]);
  } catch (error) {
    console.log(error);
  }

  const checkEmail = (event) => {
    if (event.target.value) {
      dispatch(actions.checkEmail(event.target.value));
    }
  };
  const result = useSelector((state) => state.hotelgroup.result);
  useEffect(() => {
    if (result?.isEmailExist) {
      setEmailExist(true);

      props.updateform({
        target: { name: "first_name", value: result.data.first_name },
      });
      props.updateform({
        target: { name: "middle_name", value: result.data.middle_name },
      });
      props.updateform({
        target: { name: "last_name", value: result.data.last_name },
      });
      props.updateform({
        target: { name: "phone_number", value: result.data.phone_number },
      });
      props.updateform({
        target: {
          name: "phone_number_country",
          value: result.data.phone_number_country,
        },
      });
    } else if (result?.isEmailExist === false) {
      if (emailExist) {
        setEmailExist(false);
        props.updateform({ target: { name: "first_name", value: "" } });
        props.updateform({ target: { name: "middle_name", value: "" } });
        props.updateform({ target: { name: "last_name", value: "" } });
        props.updateform({ target: { name: "phone_number", value: "" } });
        props.updateform({
          target: { name: "phone_number_country", value: "" },
        });
      }
    }
  }, [result]);

  // intl tel input handler
  const handlePhoneChange = (_status, phoneNumber, country) => {
    props.updateform({
      target: { name: "phone_number", value: phoneNumber },
    });
    props.setCountryISO(country.iso2);
  };

  const handleFlagChange = (_data, t1, _t2) => {
    props.setCountryISO(t1.iso2);
  };

  const intlTelInputRef = useRef();

  useEffect(() => {
    if (
      intlTelInputRef.current &&
      props.oldHotelValues?.phone_number_country &&
      props.oldHotelValues?.phone_number_country.length > 0
    ) {
      intlTelInputRef.current.setFlag(
        props.oldHotelValues.phone_number_country
      );
      if (intlTelInputRef.current?.tel) {
        const intlInputParentDOM = intlTelInputRef.current?.tel?.parentElement;
        if (intlInputParentDOM) {
          const dialcodeDOM =
            intlInputParentDOM.firstElementChild.querySelector(
              ".selected-dial-code"
            );
          if (dialcodeDOM) {
            dialcodeDOM.classList.remove("selected-dial-code");
            dialcodeDOM.style.marginLeft = "5px";
          }
        }
      }
    }
  }, [intlTelInputRef, props.oldHotelValues, HotelGroupVisible.heading]);

  return (
    <div>
      {HotelGroupVisible.heading === true && <h5>Contact Information</h5>}
      <Row>
        <Col md={4}>
          {HotelGroupVisible.email === true && (
            <Form.Group controlId="formGridEmailaddress">
              <Form.Label>
                Email address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues.email === undefined
                    ? false
                    : props.oldHotelValues?.email?.length < 1 ||
                      props.oldHotelValues?.email?.length > 100
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues.email
                }
                name="email"
                onChange={props.updateform}
                onBlur={checkEmail}
                minLength={1}
                maxLength={100}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                                    {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 1)"}
                                </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          {HotelGroupVisible.first_name === true && (
            <Form.Group controlId="formGridFirstname">
              <Form.Label>
                First name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues?.first_name === undefined
                    ? false
                    : props.oldHotelValues?.first_name?.length < 1 ||
                      props.oldHotelValues?.first_name?.length > 100
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues?.first_name
                }
                name="first_name"
                onChange={props.updateform}
                disabled={emailExist}
                minLength={1}
                maxLength={100}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                            {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 1)"}
                        </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
        <Col md={3}>
          {HotelGroupVisible.middle_name === true && (
            <Form.Group controlId="formGridMiddlename">
              <Form.Label>Middle name</Form.Label>
              <Form.Control
                type="text"
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues?.middle_name
                }
                name="middle_name"
                onChange={props.updateform}
                disabled={emailExist}
              ></Form.Control>
            </Form.Group>
          )}
        </Col>
        <Col md={3}>
          {HotelGroupVisible.last_name === true && (
            <Form.Group controlId="formGridLastname">
              <Form.Label>
                Last name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                isInvalid={
                  props.oldHotelValues === undefined ||
                  props.oldHotelValues?.last_name === undefined
                    ? false
                    : props.oldHotelValues?.last_name?.length < 1 ||
                      props.oldHotelValues?.last_name?.length > 100
                    ? true
                    : false
                }
                value={
                  props.oldHotelValues === undefined
                    ? ""
                    : props.oldHotelValues?.last_name
                }
                name="last_name"
                onChange={props.updateform}
                disabled={emailExist}
                minLength={1}
                maxLength={100}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                            {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 1)"}
                        </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
      </Row>
      <Col md={5}>
        {HotelGroupVisible.email === true && (
          <Form.Group controlId="formGridPhoneNo">
            <Form.Label>
              Phone Number <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control"
              disabled={emailExist}
              input
              type="tel"
              value={
                props.oldHotelValues === undefined
                  ? ""
                  : props.oldHotelValues?.phone_number
                  ? props.oldHotelValues?.phone_number
                  : ""
              }
              fieldName="phone_number"
              onSelectFlag={handleFlagChange}
              onPhoneNumberChange={handlePhoneChange}
              separateDialCode={true}
              ref={intlTelInputRef}
            />
            {/* <Form.Control
              type="text"
              isInvalid={
                props.oldHotelValues === undefined ||
                props.oldHotelValues?.phone_number === undefined
                  ? false
                  : props.oldHotelValues?.phone_number?.length < 1 ||
                    props.oldHotelValues?.phone_number?.length > 30
                  ? true
                  : false
              }
              value={
                props.oldHotelValues === undefined
                  ? ""
                  : props.oldHotelValues?.phone_number
              }
              name="phone_number"
              onChange={props.updateform}
              disabled={emailExist}
              minLength={1}
              maxLength={30}
            ></Form.Control> */}
            {/*  <Form.Control.Feedback type="invalid">
                                    {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 1)"}
                                </Form.Control.Feedback> */}
          </Form.Group>
        )}
      </Col>

      <Row>
        <Col md={5}>
          {HotelGroupVisible.role === true && (
            <Form.Group controlId="formGridRole">
              <Form.Label>
                User Role <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Super Admin"
                name="role"
                required
                defaultValue="Super Admin"
              ></Form.Control>
            </Form.Group>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default HotelGroupContactInformation;
