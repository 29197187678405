import actions from '../actions/actions';
import { ToastService } from './../../services/toast.service';


const initialState = {
    hotelsGroup: [],
    hotels:[],
    orphanHotels:[],
    hotelGroupDetails:false,
    page: 1,
    limit: 10,
    result: false,
    totalCount: 0
}

export const hotelgroupReducer = (state = initialState, action) => {
    const toast = new ToastService();
    switch (action.type) {
        
        case actions.FETCH_HOTELGROUPS_SUCESS: {
            return {
                ...state,
                hotelsGroup: action.data.records,
                page: action.data.page,
                limit: action.data.limit,
                totalCount: action.data.totalCount,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_HOTELGROUPS_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }

        case actions.FETCH_HOTELGROUP_DETAILS_SUCESS: {
            return {
                ...state,
                hotelGroupDetails: action.data,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_HOTELGROUP_DETAILS_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }
        case actions.FETCH_HOTELS_FROM_HOTELGROUP_SUCESS: {
            return {
                ...state,
                hotels: action.data.records,
                page: action.data.page,
                limit: action.data.limit,
                totalCount: action.data.totalCount,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_HOTELS_FROM_HOTELGROUP_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }
        case actions.FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_SUCESS: {
            toast.onSuccess(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_FAILED: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }
        case actions.ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_SUCESS: {
            toast.onSuccess(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }

        case actions.ADD_HOTELGROUP_SUCESS: {
            toast.onSuccess(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true,
                    created :true
                }
            }
        }

        case actions.ADD_HOTELGROUP_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false,
                    created :false
                }
            }
        }
        case actions.FETCH_ORPHAN_HOTELS_SUCESS: {
            return {
                ...state,
                orphanHotels: action.data.records,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_ORPHAN_HOTELS_FAILED: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }

        case actions.UPDATE_HOTEL_GROUP_SUCESS: {
            toast.onSuccess(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true,
                    update :true
                }
            }
        }

        case actions.UPDATE_HOTEL_GROUP_FAILED: {
            toast.onFailure(action?.msg);
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false,
                    update :false
                }
            }
        }
        case actions.FETCH_ORPHAN_HOTELS_SUCESS: {
            return {
                ...state,
                orphanHotels: action.data.records,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.FETCH_ORPHAN_HOTELS_FAILED: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }

        case actions.UPDATE_HOTEL_GROUP_SUCESS: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: true
                }
            }
        }

        case actions.UPDATE_HOTEL_GROUP_FAILED: {
            return {
                ...state,
                result: {
                    msg: action.msg,
                    success: false
                }
            }
        }

        case actions.CHECK_HOTEL_GROUP_SUCCESS :{
            return{
                ...state,
                result :{
                    data: action.data,
                    msg: action.msg,
                    success :true,
                    existing:true
                }
            }
        }
        case actions.CHECK_HOTEL_GROUP_FAILED :{
            return{
                ...state,
                result :{
                    msg: action.msg,
                    success :false,
                    existing:false
                }
            }
        }

        case actions.CHECK_HOTEL_GROUP_USER_EMAIL_SUCCESS :{
            
            return{
                ...state,
                result :{
                    data: action.data,
                    msg: action.msg,
                    success :true,
                    isEmailExist:true
                }
            }
        }
        case actions.CHECK_HOTEL_GROUP_USER_EMAIL_FAILED :{
            return{
                ...state,
                result :{
                    msg: action.msg,
                    success :false,
                    isEmailExist:false
                }
            }
        }

        case actions.RESET_EMAIL:{
            return{
                ...state,
                result:false
            }
        }
        case actions.CLEAR_HOTEL_GROUP_LIST:{
            return initialState
        }
        case actions.CLEAR_HOTEL_GROUP_BY_ID:{
            return {
                ...state,
                hotelGroupDetails:false
            }
        }

        default: {
            return state
        }
    }
}