export const API_FAILED = "API_FAILED";
export const API_FETCHING = "API_FETCHING";
export const API_WARNING = "API_WARNING";
export const API_SUCCESS = "API_SUCCESS";


export const apiFailed = (response) => {
  return {
    type: API_FAILED,
    response,
  };
};