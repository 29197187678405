import axios from "axios";
import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import { getTokens } from "./auth.service";

const storageService = new StorageService();

const authrInstance = axios.create({
  baseURL: `${process.env.REACT_APP_AUTHR_URL}`,
  withCredentials: true,
});

//applying header
authrInstance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers["Authorization"] = authToken.AccessToken;
  config.headers["Refresh-Token"] = authToken.RefreshToken;
  return config;
});

//applying refresh token
authrInstance.interceptors.response.use(
  (response) => {
    if (response.headers) {
      if (response.headers.isaccesstokenrefreshed === "1") {
        storageService.setItem(
          STORAGE_KEYS.AccessToken,
          response.headers.AccessToken
        );
      }
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

export default authrInstance;
