import React, { useState } from "react";
import { Button, Col, Modal, Row } from 'react-bootstrap';

const ConfirmWindow = (props) => {
    const [show, setShow] = useState(false);
    return (
        <>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <div className="mt-3">
                    <Row>
                        <Col md={2}></Col>
                        <Col md={8} className="alignCenter"></Col>
                        <Col md={2} className="pl-3">
                            {/* <span onClick={props.onHide} className="pointer">
                                <CloseIcon />
                            </span> */}
                        </Col>
                    </Row>
                </div>
                <Modal.Body className="alignCenter">
                    {props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.redirctToResetPassword}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmWindow