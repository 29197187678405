import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "./storage.service";

const storageService = new StorageService();

const getToken = () => {
  return `${storageService.getItem("token")}` || false;
};
const getAccessToken = () => {
  return `${storageService.getItem(STORAGE_KEYS.AccessToken)}` || false;
};
const getDashboardLink = () => {
  return `${storageService.getItem(STORAGE_KEYS.dashboardLink)}` || false;
};

const getTokens = () => {
  const tokens = {
    AccessToken: storageService.getItem(STORAGE_KEYS.AccessToken),
    RefreshToken: storageService.getItem(STORAGE_KEYS.RefreshToken),
  };
  return tokens || false;
};

const removeToken = () => {
  storageService.removeItem(STORAGE_KEYS.token);
  storageService.removeItem(STORAGE_KEYS.IdToken);
  storageService.removeItem(STORAGE_KEYS.RefreshToken);
  storageService.removeItem(STORAGE_KEYS.AccessToken);
  storageService.removeItem(STORAGE_KEYS.allowedPages);
  storageService.removeItem(STORAGE_KEYS.dashboardLink);
  storageService.removeItem("user-first-name");
  return storageService.removeItem(STORAGE_KEYS.token);
};

const removeSessionToken = () => {
  return storageService.removeItem("session-token");
};

export {
  getToken,
  removeToken,
  removeSessionToken,
  getTokens,
  getAccessToken,
  getDashboardLink,
};
