import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PRIVATE_ROUTES } from "../../helper/constants"
import { getHotelGroup, getSortedHotelGroup, search_action, search_sorted_action, setSearchHotelGroup, setSearchHotelGroupHotel } from '../../store/actions/hotelgroup.action'
import { setSearchHotel } from '../../store/actions/hotels.action'
import { setUserSearch } from '../../store/actions/userManagement.action'
import { Utils } from "../../utils/utils"
import PaginationComponent from '../Common/PaginationComponent'
import SearchComponents from '../Common/SearchComponents'
import TableComponent from '../Common/TableComponent'
import Breadcrumbs from './../Common/Breadcrumbs'
import Spiner from "./../Common/Spiner"


const HotelGroupList = (props) =>{
    const breadcrumbs = [{"path":"/hotel-group/list","title":"Hotel groups"} ];
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(setSearchHotel(""))
        dispatch(setUserSearch(""))
        dispatch(setSearchHotelGroupHotel(""))
        
    },[])

    let HotelGroupListval = useSelector(state => state.hotelgroup.hotelsGroup)
    
    const hotelGroupSearchTerm = useSelector((state)=>state?.common?.hotelGroupSearch)
    const [searchKey, setSearchKey] = useState(hotelGroupSearchTerm || '')
    //to manage roles and permission 

    const hiddenIconAsPerRole = (path) => {
        const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
        return allowedPagesAccess;
      }
    
    let total_count = useSelector(state => state.hotelgroup.totalCount)

    const hotelGroupCommonDetails = useSelector(
        (state) => state?.common
      );
  const [pageNo, setPageNo] = useState(hotelGroupCommonDetails.hotelGroupListPageNo);
  const [sortingColumnName, setSortingColumnName] = useState(hotelGroupCommonDetails.hotelGroupColumnName);
  const [sortColumns, setSortColumns] = useState([hotelGroupCommonDetails.hotelGroupColumnName]);

  const sortColumn = useSelector(
    (state) => state?.common.sortForHotelGroup
  );
  const [sort, setSort] = useState(sortColumn);

    let columnDataMap ={
        'HOTEL GROUP':'name',
        'EMAIL' :'email',
        'CONTACT PERSON':'contact_person',
        'HOTELS': 'hotels_count'
    }
    useEffect(() => {
        if (hotelGroupCommonDetails) {
          //set current page no 
          setPageNo(hotelGroupCommonDetails.hotelGroupListPageNo)
        }
    
      }, [hotelGroupCommonDetails]);
    useEffect(()=>{
        if (searchKey === ""){

            if(sortingColumnName===''){
    
                dispatch(getHotelGroup(pageNo, 15))
            }else{
              
                if(sort[sortingColumnName]){
    
                    dispatch(getSortedHotelGroup(pageNo, 15, columnDataMap[sortingColumnName], 'DESC'))
                }else{
                    dispatch(getSortedHotelGroup(pageNo, 15, columnDataMap[sortingColumnName], 'ASC'))
                }
            }
        }
        
        if (searchKey !==""){

            if (sortingColumnName!=""){
               
                if(sort[sortingColumnName]){
                    
                    dispatch(search_sorted_action(searchKey,pageNo , 15, columnDataMap[sortingColumnName], 'DESC'))
                }else{
                    dispatch(search_sorted_action( searchKey, pageNo, 15, columnDataMap[sortingColumnName] ,'ASC'))
                }
            }else{

                dispatch(search_action(searchKey, pageNo, 15))
            }
            
            
        }
        
    }, [pageNo]);
    let successMsg = useSelector(state => state.hotelgroup.result.success)
    let resp_msg = useSelector(state => state.hotelgroup.result.msg)
    let mapper = []
    
    HotelGroupListval.forEach((itr,i)=>{
        let temp_map = {
            'HOTEL GROUP': itr['name'],
            'EMAIL' : (itr['contact_info_user']===null)?(""):itr['contact_info_user']['email'],
            'CONTACT PERSON' :(itr['contact_info_user']===null)?(""):`${itr['contact_info_user']['first_name']} ${(itr['contact_info_user']['middle_name'] === null)?(""):(itr['contact_info_user']['middle_name'])} ${itr['contact_info_user']['last_name']}`,
            'HOTELS' : itr['hotels_count'],
            'id' : itr['id']
        }
        mapper.push(temp_map)
    })

    
    
    let sortingClick = (event)=>{
        
        setSortColumns([event.target.innerText.trim()])
       
        try {
            if(sort===''){
                setSort({...sort, [event.target.innerText.trim()]:true})
            }else{
                setSort({...sort, [event.target.innerText.trim()]:!sort[event.target.innerText.trim()]})
            }
        } catch (error) {
            
            setSort({...sort, [event.target.innerText.trim()]:true})
        }
        
        setSortingColumnName(event.target.innerText.trim())
        if (sort[event.target.innerText.trim()]){
            if (searchKey!==""){
                dispatch(search_sorted_action(searchKey,pageNo,15,columnDataMap[event.target.innerText.trim()], 'ASC'))
            }else{

                dispatch(getSortedHotelGroup(pageNo, 15,columnDataMap[event.target.innerText.trim()], 'ASC'))
            }
        }else if(!sort[event.target.innerText.trim()]){
            if (searchKey!==""){
                dispatch(search_sorted_action(searchKey,pageNo,15,columnDataMap[event.target.innerText.trim()],  'DESC'))
            }else{

                dispatch(getSortedHotelGroup(pageNo, 15, columnDataMap[event.target.innerText.trim()], 'DESC'))
            }
        }
    }

    let edit_row = (event)=>{
        
        props.history.push('/hotel-group/update/'+ event.target.id)
    }

    let searchFun =(searchFilter = '')=>{
        setPageNo(1);
        dispatch(setSearchHotelGroup(searchFilter))
        if ( pageNo==1){
            if (sortingColumnName!=""){
                if(sort[sortingColumnName]){

                    dispatch(search_sorted_action(searchFilter.replace(",", ""),1 , 15, columnDataMap[sortingColumnName], 'DESC'))
                }else{
                    dispatch(search_sorted_action(searchFilter.replace(",", ""),1, 15,columnDataMap[sortingColumnName], 'ASC'))
                }
            }else{

                dispatch(search_action(searchFilter.replace(",", ""), 1, 15))
            }
        }
        setSearchKey(searchFilter.replace(",", ""))
        
    
    }
    
    
    return (
        <React.Fragment>
            <div className="breadcrumbs">
                <Row>
                    <Col>
                        <Breadcrumbs BreadcrumbData={breadcrumbs} />
                    </Col>
                </Row>
                <Row className = "marginBottom">
                    <Col md={6} sm={6} xs={6}>
                        <h3> Hotel Groups </h3>
                    </Col>
                    <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
                    {hiddenIconAsPerRole(PRIVATE_ROUTES.create_hotel_group) ? (<Link to={PRIVATE_ROUTES.create_hotel_group}>
                            <Button>
                            + Add new hotel group
                            </Button>
                        </Link>) : (<></>)}
                    </Col>
                </Row>
            </div>
            <div className = "content">
                <Row>
                    <Col>
            <Row>
                <Col>
                    <SearchComponents path ={'/hotel-group/create'} 
                        search={searchFun} 
                        buttonLable={'+ Add new hotel group'}
                        commonSearchTerm={hotelGroupSearchTerm}
                        />
                        
                </Col>
            </Row>
            <Row>
                { (successMsg === true) &&<Col>
                    {
                        (HotelGroupListval.length > 0) && <TableComponent 
                                                            type ={hiddenIconAsPerRole(PRIVATE_ROUTES.update_hotel_group)?'update_list_table':''}
                                                            mapper={mapper} 
                                                            sort = {{sortColumns, sort ,sortingClick}}
                                                            action = {{edit_row}}
                                                            showAction={hiddenIconAsPerRole(PRIVATE_ROUTES.update_hotel_group)}
                                                            />
                    } 
                    {
                        (HotelGroupListval.length < 1) && <strong><span>No Records Found</span></strong>
                    }  
                </Col>}
            </Row>
            <Row>
                { (successMsg === false) &&<Col>
                    {
                        (HotelGroupListval.length < 1 ) && <strong><span style={{align:'center'}}> {resp_msg} </span></strong>
                    }   
                    </Col>
                }
            </Row>
            <Row>
                {
                    (successMsg === undefined) &&<Col>
                        <Spiner></Spiner>  
                    </Col>
                }
                
            </Row>
            <Row>
                <Col>
                    <PaginationComponent pageNo={pageNo} totalCount = {Math.ceil(total_count/15)} onChange={setPageNo} />
                </Col>
            </Row>
        </Col>
                </Row>
            </div>
        </React.Fragment>        
    )

}

export default HotelGroupList