import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ReactComponent as DashboardSVG } from "../components/Common/DashboardIcon/Dashboard.svg";
import Hotelgroups from "../components/Common/DashboardIcon/Hotelgroups.svg";
import Hotels from "../components/Common/DashboardIcon/Hotels.svg";
import Users from "../components/Common/DashboardIcon/Users.svg";
import { PRIVATE_ROUTES } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import actions from "../store/actions/actions";
import { Utils } from "../utils/utils";

const Dashboard = () => {
  const [firstName, setFirstName] = useState(false);
  const storageService = new StorageService();
  const dashboard = useSelector(
    (state) => state?.login?.result?.dashboard_link
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setSearchHotel(""));
    dispatch(actions.setSearchHotelGroupHotel(""));
    dispatch(actions.setSearchHotelGroup(""));
    dispatch(actions.setUserSearch(""));
  }, []);

  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const urlReturn = () => {
    if (
      dashboard == "undefined" ||
      dashboard == undefined ||
      dashboard == null ||
      dashboard?.length < 1
    ) {
      return "";
    } else {
      return dashboard;
    }
  };
  const redirectToDashboard = () => {
    if (dashboard) {
      window.open(dashboard);
    }
  };
  useEffect(() => {
    if (!firstName) setFirstName(storageService.getItem("user-first-name"));
  }, []);
  return (
    <React.Fragment>
      {/* <div className="background-setup" /> */}
      <Row className="dashboard-header">
        <Col md={12}>
          <div>
            <h5 className="welcome_text">Hello {firstName}</h5>
            <div className="alignCenterWK">
              {" "}
              Choose a category to start working{" "}
            </div>
          </div>
        </Col>
        <Col md={12}>
          <Row className="alignCenter">
            <Col className="mt-4">
              <Row className="alignCenterWK">
                {hiddenIconAsPerRole(PRIVATE_ROUTES.home_page) ? (
                  <Col className="mb-2 noPadding">
                    <Link>
                      <Card className="dashboard-icon">
                        <DashboardSVG
                          className="card-img-top"
                          onClick={redirectToDashboard}
                        />
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  <></>
                )}
                {hiddenIconAsPerRole(PRIVATE_ROUTES.hotel_group_listing) ? (
                  <Col className="mb-2 noPadding">
                    <Link to={PRIVATE_ROUTES.hotel_group_listing}>
                      <Card className="dashboard-icon">
                        <Card.Img variant="top" src={Hotelgroups} />
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  <></>
                )}

                {hiddenIconAsPerRole(PRIVATE_ROUTES.hotel_listing) ? (
                  <Col className="mb-2 noPadding">
                    <Link to={PRIVATE_ROUTES.hotel_listing}>
                      <Card className="dashboard-icon">
                        <Card.Img variant="top" src={Hotels} />
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  <></>
                )}

                {hiddenIconAsPerRole(PRIVATE_ROUTES.user_listing) ? (
                  <Col className="mb-2 noPadding">
                    <Link to={PRIVATE_ROUTES.user_listing}>
                      <Card className="dashboard-icon">
                        <Card.Img variant="top" src={Users} />
                      </Card>
                    </Link>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
