import axios from "../axiosInstance";

class ConstantsService {
    getConstantsData = async (fields) => {
        try{
            const response = await axios.get(`/constants?fields=${fields.join(",").trim()}`);
            return response;
        }
        catch(error){
            throw error
        }
    }

    getLanguages = async () => {
        try{
            const response = await axios.get(`/languages`);
            return response;
        }
        catch(error){
            throw error
        }

    }

    getHotelGroupList = async () => {
        try{
            const response = await axios.get(`/hotel-groups/list`);
            return response;
        }
        catch(error){
            throw error
        }
       
    }

    fetchGetCountries = async () => {
        try{
            const response = await axios.get(`/countries`);
            return response;
        }
        catch(error){
            throw error
        }
    }

    fetchGetStates = async (country_id) =>{
        try{
            const response = await axios.get(`/states/${country_id}`)
            return response;
        }
        catch(error){
            throw error
        }
    }
}

export default ConstantsService;