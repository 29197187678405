import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import HotelValidator from "../../services/validator/hotelValidator";
import nullFormatter from "../../services/validator/nullFormat";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import Breadcrumbs from "./../Common/Breadcrumbs";
import HotelGroupContactInformation from "./HotelContactInformation";
import HotelInvoiceInformation from "./HotelInvoiceInformation";
import HotelAdditonalNotes from "./HotelsAdditonalNotes";
import HotelsBasicInformation from "./HotelsBasicInforamtion";
import HotelFinancialAgreements from "./HotelsFinancialAgreement";

const CreateHotels = (props) => {
  const breadcrumbs = [
    { path: "/hotel/list", title: "Hotels" },
    { path: "/hotel/create", title: "Add new hotel" },
  ];
  const [hotel, setHotel] = useState({
    lang_id: 1,
    contact_information: {},
    financial_agreement: {},
    invoice_info: {},
  });
  const [countryISO, setcountryISO] = useState();
  const dispatch = useDispatch();
  const [saveHit, setSaveHit] = useState(false);
  const [isInvoiceAddressDuplicate, setIsInvoiceAddressDuplicate] =
    useState(false);
  const [tempInvoiceAddress, setTempInvoiceAddress] = useState();
  let result = useSelector((state) => state.hotels.result);
  const toast = new ToastService();

  let search = useLocation().search;
  let queryParamId = new URLSearchParams(search).get("queryParamId");
  let link = "";
  if (queryParamId !== null) {
    link = "/hotel-group/update/" + queryParamId;
  } else {
    link = "/hotel/list";
  }
  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  useEffect(() => {
    if (result.success && result.created && saveHit) {
      setSaveHit(false);
      props.history.push(link);
    }
  }, [result]);
  const createHotel = (e) => {
    setSaveHit(true);
    e.preventDefault();
    /* Yet to add Hotel Validation */
    let validating = HotelValidator(hotel, "create");

    let notMandatoryField = [
      "cluster",
      "area",
      "region",
      "division",
      "brand",
      "hotel_group_id",
      "contract_type_id",
      "middle_name",
      "discount_percentage",
      "commision_percentage",
      "is_contract_no_end_date",
      "additional_notes",
      "finiancial_aggrement_id",
      "invoice_info_id",
      "state_id",
    ];
    if (hotel.financial_agreement.is_contract_no_end_date) {
      notMandatoryField.push("contract_end_date");
    }
    const isNumeric = (str) => parseFloat(str) === parseFloat(str);

    if (queryParamId !== null && isNumeric(queryParamId)) {
      hotel.hotel_group_id = parseInt(queryParamId);
    }

    let flag = true;

    setHotel(validating);
    Object.keys(validating).forEach((validKeys, i) => {
      if (
        validating[validKeys] === "" &&
        !notMandatoryField.includes(validKeys) &&
        typeof validating[validKeys] !== "object"
      ) {
        flag = false;
      } else if (typeof validating[validKeys] === "object") {
        Object.keys(validating[validKeys]).forEach((vk2, i2) => {
          if (
            validating[validKeys][vk2] === "" &&
            !notMandatoryField.includes(vk2) &&
            typeof validating[validKeys][vk2] !== "object" &&
            !isInvoiceAddressDuplicate &&
            validKeys === "invoice_info" &&
            ["company_name", "vat_number"].includes(vk2)
          ) {
            flag = false;
          }
        });
      }
    });

    if (flag) {
      let nullFormatted = nullFormatter(hotel);
      dispatch(actions.createHotel(nullFormatted));
      e.target.reset();
    } else {
      toast.onWarning("Please fill out the fields correctly");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetEmail());
    };
  }, []);

  useEffect(() => {
    dispatch(actions.getCountries());
    if (queryParamId !== null) {
      setHotel({ ...hotel, hotel_group_id: parseInt(queryParamId) });
    }
  }, []);

  let setHotelInfo = (event) => {
    if (
      event.target.name === "country_id" &&
      event.target.value === undefined
    ) {
      delete hotel.state_id;
      delete hotel.address_city;
    }
    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        setHotel({ ...hotel, [event.target.name]: event.target.value });
      } else {
        setHotel({ ...hotel, [event.target.name]: "" });
      }
    } catch (err) {
      setHotel({ ...hotel, [event.target.name]: event.target.value });
    }
  };

  useEffect(() => {
    if (isInvoiceAddressDuplicate) {
      setTempInvoiceAddress({
        ...hotel.invoice_info,
      });

      setHotel({
        ...hotel,
        invoice_info: {
          ...hotel.invoice_info,
          billing_address: hotel.address,
          zip_code: hotel.zip_code,
          address_city: hotel.address_city,
          country_id: hotel.country_id,
          state_id: hotel.state_id,
        },
      });
    } else {
      setHotel({
        ...hotel,
        invoice_info: {
          ...hotel.invoice_info,
          billing_address: tempInvoiceAddress?.billing_address
            ? tempInvoiceAddress?.billing_address
            : "",
          zip_code: tempInvoiceAddress?.zip_code
            ? tempInvoiceAddress?.zip_code
            : "",
          address_city: tempInvoiceAddress?.address_city
            ? tempInvoiceAddress?.address_city
            : "",
          country_id: tempInvoiceAddress?.country_id
            ? tempInvoiceAddress?.country_id
            : "",
          state_id: tempInvoiceAddress?.state_id
            ? tempInvoiceAddress?.state_id
            : "",
        },
      });
    }
  }, [isInvoiceAddressDuplicate]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3> Add new hotel </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Form onSubmit={createHotel}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <HotelsBasicInformation
                    updateform={setHotelInfo}
                    param={{ queryParamId }}
                    sendOldHotelValues={hotel}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HotelGroupContactInformation
                    updateform={{ setHotel, hotel }}
                    setcountryISO={setcountryISO}
                    sendOldHotelValues={hotel.contact_information}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HotelFinancialAgreements
                    updateform={{ setHotel, hotel }}
                    sendOldHotelValues={hotel.financial_agreement}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HotelInvoiceInformation
                    updateform={{ setHotel, hotel }}
                    sendOldHotelValues={hotel.invoice_info}
                    setIsInvoiceAddressDuplicate={setIsInvoiceAddressDuplicate}
                    isInvoiceAddressDuplicate={isInvoiceAddressDuplicate}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HotelAdditonalNotes onChange={setHotel} hotel={hotel} />
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={6} xs={7}>
                  {hiddenIconAsPerRole(PRIVATE_ROUTES.create_hotel) ? (
                    <Button type="submit">+ Add new hotel</Button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={3} sm={6} xs={5}>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      props.history.push(link);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateHotels;
