export const GET_CONSTANTS = 'GET_CONSTANTS'
export const FETCH_CONSTANTS_SUCCESS = 'FETCH_CONSTANTS_SUCCESS'
export const FETCH_CONSTANTS_FAILED = 'FETCH_CONSTANTS_FAILED'

export const GET_LANGUAGES = 'GET_LANGUAGES'
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS'
export const FETCH_LANGUAGES_FAILED = 'FETCH_LANGUAGES_FAILED'

export const GET_HOTEL_GROUP_LIST = 'GET_HOTEL_GROUP_LIST'
export const GET_HOTEL_GROUP_LIST_SUCCESS = 'GET_HOTEL_GROUP_LIST_SUCCESS'
export const GET_HOTEL_GROUP_LIST_FAILED =  'GET_HOTEL_GROUP_LIST_SUCCESS'

export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAILED =  'GET_COUNTRY_SUCCESS'

export const GET_STATES = 'GET_STATES'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'
export const GET_STATES_FAILED =  'GET_STATES_FAILED'

export const getConstantsData = (fields) => {
    return {
        type: GET_CONSTANTS,
        fields
    }
}

export const getLanguages = () => {
    return {
        type: GET_LANGUAGES
    }
}

export const getHotelGroupList = ()=>{
    return{
        type: GET_HOTEL_GROUP_LIST
    }
}

export const getCountries = () =>{
    return {
        type: GET_COUNTRY
    }
}

export const getStates = (country_id)=>{
    return {
        type : GET_STATES,
        country_id
    }
}