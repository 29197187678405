import actions from "../actions/actions";
import { ToastService } from './../../services/toast.service';

const initialState = {
    result: false,
    msg: false,
    success: false,
    userList:false,
    addUser:false,
    getUserProfile:false,
    userProfile:false,
    isUpdate:false
  };
  

  export const userManagementReducer = (state = initialState, action) => {
    const toast = new ToastService();
    switch (action.type) {
      case actions.FETCH_USERS_SUCCESS: {
        state.userList = action?.result;
        return {
          ...state,
          addUser : false,
          isUpdate:false,
          getUserProfile:false,
          success: true,
        };
      }
      case actions.FETCH_USERS_FAILED: {
        toast.onFailure(action?.msg);
        return {
          ...state,
          result: {
              addUser : false,
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.ADD_USERS_SUCCESS: {
        toast.onSuccess(action?.msg);
        return {
          ...state,
          addUser : true,
          isUpdate:false,
          success: true,
        };
      }
      case actions.ADD_USERS_FAILED: {
        toast.onFailure(action?.msg);
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.UPDATE_USERS_SUCCESS: {
        toast.onSuccess(action?.msg);
        return {
          ...state,
          isUpdate:true,
          success: true,
        };
      }
      case actions.UPDATE_USERS_FAILED: {
        toast.onFailure(action?.msg);
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.UPDATE_USER_PROFILE_SUCCESS: {
        //toast.onSuccess(action?.msg)
        return {
          ...state,
          success: true,
        };
      }
      case actions.UPDATE_USER_PROFILE_FAILED: {
        toast.onFailure(action?.msg);
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.GET_USER_PROFILE_SUCCESS: {
        state.userProfile = action?.result;
        return {
          ...state,
          getUserProfile:true,
          success: true,
        };
      }
      case actions.GET_USER_PROFILE_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.GET_USER_BY_ID_SUCCESS: {
        state.userById = action?.result;
        return {
          ...state,
          getUserProfile:true,
          success: true,
        };
      }
      case actions.GET_USER_BY_ID_FAILED: {
        return {
          ...state,
          result: {
              msg: action?.msg,
          },
          success: false
        };
      }
      case actions.RESET_USER_BY_ID: {
        return {
          ...state,
          userById:false
        };
      }
      default: {
        return state;
      }
    }
  };