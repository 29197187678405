import React, { useEffect, useState } from "react";
import { Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import LanuageDropDown from '../Common/LanguageDropDown';

const UserBasicInformation = (props) => {
    const [languageList, setlanguageList] = useState([]);
    const [roleList, setRoleList] = useState();

    const dispatch = useDispatch();
    const languages = useSelector(state => state.constants.languages)
    const roles = useSelector(state => state.authr.roles)
    useEffect(() => {
        dispatch(actions.getLanguages())
        dispatch(actions.getRole())
    }, [])
    useEffect(() => {
        if (languages) {
            const langList = languages?.map((lang) => {
                return { value: lang.code, id: lang.id };
            });
            setlanguageList(langList);
        }
        if(roles){
            const roleList = roles?.map((role) => {
                return { value: role.name, id: role.id };
            });
            setRoleList(roleList);
        }
    }, [languages,roles]);
    return (
        <>
            <h5>Basic Information</h5>
            <Row>
                <Col md={3}>
                    <bootstrapForm.Group>
                        <bootstrapForm.Label>First Name <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                        <bootstrapForm.Control
                            type="text"
                            name="first_name"
                            isInvalid={props.touched.first_name && props.error?.first_name}
                            value={props.values?.first_name.trimStart()}
                            onChange={props.change}
                        ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                </Col>
                <Col md={3}>
                    <bootstrapForm.Group>
                        <bootstrapForm.Label>Middle Name</bootstrapForm.Label>
                        <bootstrapForm.Control
                            type="text"
                            name="middle_name"
                            isInvalid={props.touched.middle_name && props.error?.middle_name}
                            value={props.values?.middle_name ? props.values?.middle_name.trimStart() : props.values?.middle_name}
                            onChange={props.change}
                        ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                </Col>
                <Col md={3}>
                    <bootstrapForm.Group>
                        <bootstrapForm.Label>Last Name <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                        <bootstrapForm.Control
                            name="last_name"
                            isInvalid={props.touched.last_name && props.error?.last_name}
                            value={props.values?.last_name.trimStart()}
                            onChange={props.change}
                        ></bootstrapForm.Control>
                    </bootstrapForm.Group>
                </Col>
            </Row>
            {!props.isEditForm && (
                <>
                    <Row>
                        <Col md={9}>
                            <bootstrapForm.Group>
                                <bootstrapForm.Label>User Role <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                                <LanuageDropDown
                                    val={roleList}
                                    inpName={"role_id"}
                                    touched={props.touched}
                                    errors={props.error}
                                    updateDropdown={props.updateRole}
                                    values={props.selectedRole}
                                ></LanuageDropDown>
                            </bootstrapForm.Group>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={9}>
                            <bootstrapForm.Group>
                                <bootstrapForm.Label>Email <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                                <bootstrapForm.Control
                                    type="email"
                                    name="email"
                                    isInvalid={props.touched.email && props.error?.email}
                                    value={props.values?.email}
                                    onChange={props.change}
                                ></bootstrapForm.Control>
                            </bootstrapForm.Group>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={9}>
                            {/* <DropDown
                                lang={languages}
                                updateform={props.updateform}
                                sendOldHotelValues={props.oldHotelValues}
                                inpName={'language_ids'}
                                req={true}
                            ></DropDown> */}
                            {/* <bootstrapForm.Group>
                       
                        <bootstrapForm.Control
                            type="test"
                            placeholder="Choose a Language"
                        ></bootstrapForm.Control>
                    </bootstrapForm.Group> */}
                            <bootstrapForm.Label>LANGUAGE <span style={{ color: "red" }}>*</span></bootstrapForm.Label>
                            <LanuageDropDown
                                val={languageList}
                                inpName={"language_id"}
                                touched={props.touched}
                                errors={props.error}
                                updateDropdown={props.updateDropdown}
                                values={props.value}
                            ></LanuageDropDown>

                        </Col>
                    </Row>

                </>
            )}
        </>


    );
};
export default UserBasicInformation