import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form as BootstrapForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import actions from "../../../store/actions/actions";
import expectmelogo from "../icons/expectmelogo.svg";
import Logo from "../SidebarSvg/Logo.svg";

const Login = () => {
  const history = useHistory();

  const formInitialValue = {
    email: "",
    password: "",
  };
  const isConfirmApiSuccess = useSelector((state) => state.network.isLoading);
  const formSchema = yup.object().shape({
    email: yup.string().required("Please Enter email"),
    password: yup.string().required("Please Enter Password"),
  });
  const [loginCreditional, setLoginCreditional] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const signUp = (payload) => {
    dispatch(actions.login(payload));
  };
  // useEffect(() => {
  //   if(isConfirmApiSuccess){
  //       history.push("/home");
  //   }
  // }, [isConfirmApiSuccess])

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          ...formInitialValue,
        }}
        validationSchema={formSchema}
        onSubmit={(values) => {
          const payload = {
            ...values
          }
          signUp(payload)

        }}
      >
        {({
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          submitForm,
        }) => (
          <Form>
            <Row className="noMargin">
              <Col md={6} sm={12} xs={12} className="loginleft">
                <div className="logo">
                  <img alt="expect-me-logo" src={expectmelogo} />
                </div>
                <div className="dashborad-login-form">
                  <div>
                    <span className="welcometext">Welcome back!</span>
                  </div>
                  <div>
                    <span className="welcome-info-text">Sign into your account here:</span><br /><br />
                  </div>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Enter Your Email <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="email"
                      name="email"
                      onChange={handleChange}
                      isInvalid={touched?.email && errors?.email}
                      value={values?.email}
                    ></BootstrapForm.Control>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mt-3">
                    <BootstrapForm.Label>Enter Your Password <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                    <BootstrapForm.Control
                      type="password"
                      name="password"
                      onChange={handleChange}
                      isInvalid={touched?.password && errors?.password}
                      value={values?.password}
                      onChange={handleChange}
                    ></BootstrapForm.Control>
                  </BootstrapForm.Group>
                  <BootstrapForm.Check
                    className="mt-3"
                    inline
                    label="Remember me"
                    name="remember_me"
                    type="checkbox"
                  />
                  <Row className="mt-3">
                    <Col md={6} sm={6} xl={6}>
                      <Button variant="primary" type="submit">
                        Login
                      </Button>
                    </Col>
                    <Col md={6} sm={6} xl={6}>
                      <Link to="/forget-password">
                        <Button variant="secondary">Forgot Password</Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} sm={12} xs={12} className="loginright">
                <div className="login-right-panel ">
                  <div className="background-login" />
                  <div className="dashboard-header-login">
                    <img alt="logo" src={Logo} />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default Login;
