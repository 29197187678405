import axios from "../axiosInstance";

class HotelGroupService {
  getHotelGroup = async (pageNo, limit) => {
    try {
      const response = await axios.get(
        `/hotel-groups?page=${pageNo}&limit=${limit}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getSortedHotelGroup = async (pageNo, limit, columnName, sortBy) => {
    try {
      const response = await axios.get(
        `/hotel-groups?page=${pageNo}&limit=${limit}&sort=${columnName}&sortBy=${sortBy}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getSearchSortedHotelGroup = async (
    keyword,
    pageNo,
    limit,
    columnName,
    sortBy
  ) => {
    try {
      const response = await axios.get(
        `/hotel-groups?searchKey=${keyword}&page=${pageNo}&limit=${limit}&sort=${columnName}&sortBy=${sortBy}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getHotelGroupDetails = async (id) => {
    try {
      const response = await axios.get(`/hotel-group/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  gethotelGroupName = async (name) => {
    try {
      const encodedName = encodeURIComponent(name);
      const response = await axios.get(`/hotel-group/check/${encodedName}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  getCheckEmail = async (email) => {
    try {
      const encodedEmail = encodeURIComponent(email);
      const response = await axios.get(`/user/check/${encodedEmail}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getHotelsFromHotelGroup = async (
    id,
    pageNo,
    limit,
    sort,
    sortBy,
    searchKey
  ) => {
    try {
      const response = await axios.get(
        `/hotels?hotel_group_id=${id}&searchKey=${searchKey}&page=${pageNo}&limit=${limit}&sort=${sort}&sortBy=${sortBy}`
      );

      return response;
    } catch (error) {
      throw error;
    }
  };
  unAssignHotelFromHotelGroup = async (hotelGroupId, hotelId) => {
    try {
      const response = await axios.delete(
        `/hotel-group/${hotelGroupId}/${hotelId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  assignHotelForHotelGroup = async (hotelGroupId, hotelsId) => {
    try {
      const response = await axios.put(
        `/hotel-group/${hotelGroupId}/hotels`,
        hotelsId
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  createHotelGroup = async (data) => {
    try {
      const response = await axios.post("/hotel-group", data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  getSearchGroup = async (key, page_no, limit) => {
    try {
      const response = await axios.get(
        `/hotel-groups?searchKey=${key}&page=${page_no}&limit=${limit}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  getOrphanHotels = async (key) => {
    try {
      const response = await axios.get(`/hotels/orphan`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateHotelGroup = async (hotelGroupId, data) => {
    try {
      const response = await axios.put(`/hotel-group/${hotelGroupId}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default HotelGroupService;
