import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { default as NumberFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PRIVATE_ROUTES } from "../../helper/constants";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import PaginationComponent from "../Common/PaginationComponent";
import SearchComponents from "../Common/SearchComponents";
import TableComponent from "../Common/TableComponent";
import Breadcrumbs from "./../Common/Breadcrumbs";

const HotelsList = (props) => {
  const breadcrumbs = [{ path: "/hotel/list", title: "Hotels" }];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setUserSearch(""));
    dispatch(actions.setSearchHotelGroupHotel(""));
    dispatch(actions.setSearchHotelGroup(""));
  }, []);

  let hotelListval = useSelector((state) => state.hotels.hotels);
  const history = useHistory();
  let total_count = useSelector((state) => state.hotels.totalCount);
  const hotelSearchTerm = useSelector((state) => state?.common?.hotelSearch);
  const [searchKey, setSearchKey] = useState(hotelSearchTerm || "");
  //to manage roles and permission

  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const hotelListCommonDetails = useSelector((state) => state?.common);

  const [pageNo, setPageNo] = useState(
    hotelListCommonDetails.hotelListPageNo || 1
  );
  const [sortingColumnName, setSortingColumnName] = useState(
    hotelListCommonDetails.hotelColumnName
  );
  const [sortColumns, setSortColumns] = useState([
    hotelListCommonDetails.hotelColumnName,
  ]);

  const sortColumn = useSelector((state) => state?.common.sortForHotel);
  const [sort, setSort] = useState(sortColumn);

  let columnDataMap = {
    "HOTEL NAME": "name",
    "HOTEL GROUP": "hotel_group_name",
    EMAIL: "email",
    "SUBSCRIPTION TYPE": "type_of_subscription",
    "MONTHLY FEE(€)": "amount",
    "COMMISSION%": "commision_percentage",
    COUNTRY: "country_name",
  };
  useEffect(() => {
    if (hotelListCommonDetails) {
      //set current page no
      setPageNo(hotelListCommonDetails.hotelListPageNo);
    }
  }, [hotelListCommonDetails]);
  useEffect(() => {
    if (searchKey === "") {
      if (sortingColumnName === "") {
        dispatch(actions.getHotels(pageNo, 15));
      } else {
        if (sort[sortingColumnName]) {
          dispatch(
            actions.getSortedHotels(
              pageNo,
              15,
              columnDataMap[sortingColumnName],
              "DESC"
            )
          );
        } else {
          dispatch(
            actions.getSortedHotels(
              pageNo,
              15,
              columnDataMap[sortingColumnName],
              "ASC"
            )
          );
        }
      }
    }
    if (searchKey !== "") {
      if (sortingColumnName != "") {
        if (sort[sortingColumnName]) {
          dispatch(
            actions.search_sorted_action_hotel(
              searchKey,
              pageNo,
              15,
              columnDataMap[sortingColumnName],
              "DESC"
            )
          );
        } else {
          dispatch(
            actions.search_sorted_action_hotel(
              searchKey,
              pageNo,
              15,
              columnDataMap[sortingColumnName],
              "ASC"
            )
          );
        }
      } else {
        dispatch(actions.search_action_hotel(searchKey, pageNo, 15));
      }
    }
  }, [pageNo]);

  let mapper = [];

  hotelListval.forEach((itr, i) => {
    let temp_map = {
      id: itr["id"],
      "HOTEL NAME": itr["name"],
      "HOTEL GROUP":
        itr["hotel_group"] === null ? "" : itr["hotel_group"]["name"],
      EMAIL:
        itr["contact_info_user"] === null
          ? ""
          : itr["contact_info_user"]["email"],
      "SUBSCRIPTION TYPE":
        itr["finiancial_aggrement"] === null ||
        itr["finiancial_aggrement"]["type_of_subscription_constant_value"] ===
          undefined ||
        itr["finiancial_aggrement"]["type_of_subscription_constant_value"] ===
          null
          ? ""
          : itr["finiancial_aggrement"]["type_of_subscription_constant_value"][
              "value"
            ],
      "MONTHLY FEE(€)":
        itr["finiancial_aggrement"] === null ||
        itr["finiancial_aggrement"]["amount"] === undefined ||
        itr["finiancial_aggrement"]["amount"] === null
          ? ""
          : Utils.NumberFormatText("€", itr["finiancial_aggrement"]["amount"]),
      "COMMISSION%":
        itr["finiancial_aggrement"] === null ||
        itr["finiancial_aggrement"]["commision_percentage"] === undefined ||
        itr["finiancial_aggrement"]["commision_percentage"] === null
          ? ""
          : Utils.NumberFormatText(
              "",
              itr["finiancial_aggrement"]["commision_percentage"],
              "%"
            ),
      COUNTRY:
        itr["country"] === null || itr["country"] === undefined
          ? ""
          : itr["country"]["name"],
    };
    mapper.push(temp_map);
  });

  let sortingClick = (event) => {
    setSortColumns([event.target.innerText.trim()]);
    try {
      if (sort === "") {
        setSort({ ...sort, [event.target.innerText.trim()]: true });
      } else {
        setSort({
          ...sort,
          [event.target.innerText.trim()]: !sort[event.target.innerText.trim()],
        });
      }
    } catch (error) {
      setSort({ ...sort, [event.target.innerText.trim()]: true });
    }
    /* TODO need an api call over here !!! */
    setSortingColumnName(event.target.innerText.trim());
    if (sort[event.target.innerText.trim()]) {
      if (searchKey != "") {
        dispatch(
          actions.search_sorted_action_hotel(
            searchKey,
            pageNo,
            15,
            columnDataMap[event.target.innerText.trim()],
            "ASC"
          )
        );
      } else {
        dispatch(
          actions.getSortedHotels(
            pageNo,
            15,
            columnDataMap[event.target.innerText.trim()],
            "ASC"
          )
        );
      }
    } else if (!sort[event.target.innerText.trim()]) {
      if (searchKey != "") {
        dispatch(
          actions.search_sorted_action_hotel(
            searchKey,
            pageNo,
            15,
            columnDataMap[event.target.innerText.trim()],
            "DESC"
          )
        );
      } else {
        dispatch(
          actions.getSortedHotels(
            pageNo,
            15,
            columnDataMap[event.target.innerText.trim()],
            "DESC"
          )
        );
      }
    }
  };

  let edit_row = (event) => {
    props.history.push("/hotel/update/" + event.target.id);
  };

  let searchFun = (searchFilter = "") => {
    setPageNo(1);
    dispatch(actions.setSearchHotel(searchFilter));
    if (pageNo == 1) {
      if (sortingColumnName !== "") {
        if (sort[sortingColumnName]) {
          dispatch(
            actions.search_sorted_action_hotel(
              searchFilter.replace(",", ""),
              1,
              15,
              columnDataMap[sortingColumnName],
              "DESC"
            )
          );
        } else {
          dispatch(
            actions.search_sorted_action_hotel(
              searchFilter.replace(",", ""),
              1,
              15,
              columnDataMap[sortingColumnName],
              "ASC"
            )
          );
        }
      } else {
        dispatch(
          actions.search_action_hotel(searchFilter.replace(",", ""), 1, 15)
        );
      }
    }
    setSearchKey(searchFilter.replace(",", ""));
  };

  return (
    <React.Fragment>
      <div className="breadcrumbs mb-2">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6}>
            <h3> Hotels </h3>
          </Col>
          <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            {hiddenIconAsPerRole(PRIVATE_ROUTES.create_hotel) ? (
              <Button
                onClick={() => history.push(`${PRIVATE_ROUTES.create_hotel}`)}
              >
                + Add new hotel
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
      <div className="content">
        <Row>
          <Col>
            <Row>
              <Col>
                <SearchComponents
                  buttonLable={"Add New Hotel"}
                  search={searchFun}
                  path={"/hotel/create"}
                  commonSearchTerm={hotelSearchTerm}
                />
              </Col>
            </Row>
            <Row>
              {hotelListval.length > 0 && (
                <Col>
                  <TableComponent
                    type={
                      hiddenIconAsPerRole(PRIVATE_ROUTES.update_hotel) ? (
                        "update_list_table"
                      ) : (
                        <></>
                      )
                    }
                    mapper={mapper}
                    sort={{ sortColumns, sort, sortingClick }}
                    action={{ edit_row }}
                    showAction={hiddenIconAsPerRole(
                      PRIVATE_ROUTES.update_hotel
                    )}
                  />
                </Col>
              )}

              {hotelListval.length < 1 && (
                <strong>
                  <span style={{ align: "center" }}>
                    {" "}
                    {"No Records Found"}{" "}
                  </span>
                </strong>
              )}
              {/* <Col><Spiner></Spiner></Col> */}
            </Row>
            <Row>
              <Col>
                <PaginationComponent
                  pageNo={pageNo}
                  totalCount={Math.ceil(total_count / 15)}
                  onChange={setPageNo}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default HotelsList;
