import React, { Component, useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col, Form } from "react-bootstrap";

const DropDown = (props) => {
  let [temp, setTemp] = useState([]);
  const [options, setOptions] = useState([]);
  let selectedValue =
    props.sendOldHotelValues === undefined
      ? []
      : props.sendOldHotelValues[props.inpName];

  useEffect(() => {
    if (selectedValue !== null && selectedValue?.length > 0) {
      setTemp(selectedValue);
    } else if (props.inpName === "language_ids") setTemp([1]);
  }, []);

  useEffect(() => {
    if (props.lang) {
      setOptions([
        ...props.lang.map((v, i) => {
          return { value: v["id"], label: v["code"], isFixed: v?.["isFixed"] };
        }),
      ]);
    }
  }, [props.lang]);

  const sendDropdownValue = (evt, val) => {
    let e = {
      target: {},
    };
    e["target"]["name"] = val.name;
    if ("action" in val && val.action === "clear") {
      const fixedArr = [...options.filter((v) => v.isFixed)];
      setTemp([fixedArr[0].value]);
      e["target"]["value"] = [fixedArr[0].value];
      props.updateform(e);
      return;
    }

    let tempArr = evt.reduce((v, i) => {
      v.push(i["value"]);
      return v;
    }, []);

    e["target"]["value"] = tempArr;
    setTemp(tempArr);

    props.updateform(e);
  };

  let invalidStyle = {};

  let isInvalid =
    props.sendOldHotelValues === undefined ||
    props.sendOldHotelValues[props.inpName] === undefined
      ? false
      : props.sendOldHotelValues[props.inpName].length < 1
      ? true
      : false;
  if (isInvalid && props.req) {
    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: "1px solid red",
        "&:hover": { borderColor: "red" },
        boxShadow: "none",
      }),
    };
  }

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    },
  };

  selectedValue = selectedValue ? selectedValue : [];
  return (
    <Row>
      <Col md={5} sm={6}>
        <Form.Group>
          <Form.Label>
            Language <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Select
            isMulti
            styles={{ ...invalidStyle, ...styles }}
            name={props.inpName}
            options={options}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={props.placeholder || ""}
            onChange={sendDropdownValue}
            value={options.filter((option) => temp.includes(option.value))}
            defaultValue={options.filter((option) =>
              selectedValue.includes(option.value)
            )}
          />

          {/* <Form.Control className='d-none' isInvalid={isInvalid && props.req}></Form.Control>
            <Form.Control.Feedback type='invalid'>
              {"Please fill out the field correctly"}
            </Form.Control.Feedback> */}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DropDown;
