import actions from "../actions/actions";
import { ToastService } from "./../../services/toast.service";

const initialState = {
  result: false,
  token: false,
  AccessToken:false,
  emailId:false,
  isConfirmPassword:false,
  isFogotPasswordApiSuccess:false,
  isResetPassword:false,
  sessionToken:false,
  isSetPassword:false,
  isChangePassword:false,
  isLoading:false,
  isSignout:false,
  isLoginSuccess:false,
  redirectToHome:false

};

export const loginReducer = (state = initialState, action) => {

  const toast = new ToastService();

  switch (action.type) {
    case actions.LOGIN_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isFogotPasswordApiSuccess:false,
        isSignout:false,
        redirectToHome:action.token.Session?false:true,
        result: {
          msg: action.msg,
          success: true,
          sessionToken:action.token.Session,
        },
      };
    }
    case actions.LOGIN_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isFogotPasswordApiSuccess:false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.LOGOUT_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        redirectToHome:false,
        isSignout:true,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.LOGOUT_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    
    case actions.FORGOT_PASSWORD_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isFogotPasswordApiSuccess:true,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.FORGOT_PASSWORD_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isFogotPasswordApiSuccess:false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.CONFIRM_PASSWORD_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isConfirmPassword:true,
        isFogotPasswordApiSuccess:false,
        result: {
          ...state.result,
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.CONFIRM_PASSWORD_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isFogotPasswordApiSuccess:false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.SET_EMAIL: {
      return {
        ...state,
        emailId:action.emailId,
        result: {
          ...state.result,
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.FETCH_TOKEN_SUCCESS: {
      return {
        ...state,
        result: {
          ...action,
          token: action?.token,
          AccessToken: action?.AccessToken,
          success: true,
        },
      };
    }

    case actions.FETCH_TOKEN_FAILED: {
      return {
        ...state,
        result: {
          token: false,
        },
      };
    }
    case actions.RESET_PASSWORD_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isResetPassword:true,
        result: {
          ...state.result,
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.RESET_PASSWORD_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isResetPassword:false,
        result: {
          ...state.result,
          msg: action.msg,
          success: false,
        },
      };
    }

    case actions.SET_PASSWORD_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isResetPassword:true,
        isSetPassword:true,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.SET_PASSWORD_SUCESS_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isResetPassword:false,
        isSetPassword:false,
        result: {
          ...state.result,
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.CHANGE_PASSWORD_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        isChangePassword:true,
        result: {
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.CHANGE_PASSWORD_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        isChangePassword:false,
        result: {
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.DEACTIVATE_USER_SUCESS:
    case actions.ACTIVATE_USER_SUCESS: {
      //toast.onSuccess(action?.msg);
      return {
        ...state,
        result: {
          ...state.result,
          msg: action.msg,
          success: true,
        },
      };
    }
    case actions.DEACTIVATE_USER_FAILED:
    case actions.ACTIVATE_USER_FAILED: {
      //toast.onFailure(action?.msg);
      return {
        ...state,
        result: {
          ...state.result,
          msg: action.msg,
          success: false,
        },
      };
    }
    case actions.CLEAR_TOKEN: {
      return initialState
    }
    default: {
      return state;
    }
  }
};
