import React, { useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SingleDropDown from "../Common/singleDropDown";
import actions from "../../store/actions/actions";

const HotelInvoiceInformation = (props) => {
  const dispatch = useDispatch();

  let [HotelVisible, setHotelVisible] = useState({
    heading: true,
    company_name: true,
    zipcode_billing_address: true,
    state_country_invoice: true,
    vat_number: true,
  });

  useEffect(() => {
    if (props.updateState != undefined) {
      setHotelVisible(props.updateState);
    }
  }, []);

  const [idCountry, setCountryId] = useState();
  let states = useSelector((state) => state.constants.states[idCountry]);
  if (idCountry === undefined) {
    states = [];
  }

  useEffect(() => {
    if (props.sendOldHotelValues !== undefined) {
      setCountryId(props.sendOldHotelValues.country_id);
    }
    if (idCountry !== undefined) {
      dispatch(actions.getStates(idCountry));
    }
  }, [idCountry,props.sendOldHotelValues]);

  let setcountry = (e) => {
    setCountryId(e);
  };

  let countries = useSelector((state) => state.constants.countries);

  let formattingData = (event) => {
    if (
      event.target.name === "country_id" &&
      event.target.value === undefined
    ) {
      delete props.updateform.hotel["invoice_info"].state_id;
      delete props.updateform.hotel["invoice_info"].address_city;
    }

    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        props.updateform.setHotel({
          ...props.updateform.hotel,
          invoice_info: {
            ...props.updateform.hotel["invoice_info"],
            [event.target.name]: event.target.value,
          },
        });
      } else {
        props.updateform.setHotel({
          ...props.updateform.hotel,
          invoice_info: {
            ...props.updateform.hotel["invoice_info"],
            [event.target.name]: "",
          },
        });
      }
    } catch (err) {
      props.updateform.setHotel({
        ...props.updateform.hotel,
        invoice_info: {
          ...props.updateform.hotel["invoice_info"],
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  return (
    <>
      <div>
        {HotelVisible.heading === true && <h5>Invoice Information</h5>}
        {HotelVisible.company_name === true && (
          <Row>
            <Col md={9}>
              <Form.Group>
                <Form.Label>
                  Company name <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="company_name"
                  isInvalid={
                    props.sendOldHotelValues === undefined ||
                    props.sendOldHotelValues.company_name === undefined
                      ? false
                      : props.sendOldHotelValues.company_name.length < 2 ||
                        props.sendOldHotelValues.company_name.length > 199
                      ? true
                      : false
                  }
                  value={
                    props.sendOldHotelValues === undefined
                      ? ""
                      : props.sendOldHotelValues.company_name
                  }
                  minLength={2}
                  maxLength={200}
                  onChange={formattingData}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Col md={9}>
          <Form.Group>
            <Form.Check
              name="is_contract_no_end_date"
              defaultChecked={
                props.sendOldHotelValues === undefined
                  ? ""
                  : props.sendOldHotelValues.is_contract_no_end_date
              }
              label="Billing address same as Hotel address"
              onChange={() =>
                props.setIsInvoiceAddressDuplicate(
                  !props.isInvoiceAddressDuplicate
                )
              }
            />
          </Form.Group>
        </Col>
        <>
          {HotelVisible.zipcode_billing_address === true && (
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Billing address <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="billing_address"
                    isInvalid={
                      props.sendOldHotelValues === undefined ||
                      props.sendOldHotelValues.billing_address === undefined
                        ? false
                        : props.sendOldHotelValues.billing_address.length < 2 ||
                          props.sendOldHotelValues.billing_address.length > 499
                        ? true
                        : false
                    }
                    value={
                      props.sendOldHotelValues === undefined
                        ? ""
                        : props.sendOldHotelValues.billing_address
                    }
                    minLength={2}
                    maxLength={500}
                    onChange={formattingData}
                    disabled={props.isInvoiceAddressDuplicate}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>
                    Zipcode <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    isInvalid={
                      props.sendOldHotelValues === undefined ||
                      props.sendOldHotelValues.zip_code === undefined
                        ? false
                        : props.sendOldHotelValues.zip_code.length < 1 ||
                          props.sendOldHotelValues.zip_code.length > 19
                        ? true
                        : false
                    }
                    value={
                      props.sendOldHotelValues === undefined
                        ? ""
                        : props.sendOldHotelValues.zip_code
                    }
                    minLength={1}
                    maxLength={20}
                    onChange={formattingData}
                    disabled={props.isInvoiceAddressDuplicate}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}
          {HotelVisible.state_country_invoice === true && (
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>
                    Country <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  {/* <Form.Control type="text" placeholder="United State" name="country" defaultValue={(props.sendOldHotelValues===undefined)?(''):(props.sendOldHotelValues.country)} onChange={formattingData} required></Form.Control> */}
                  <SingleDropDown
                    val={countries}
                    inpName={"country_id"}
                    sendOldHotelValues={props.sendOldHotelValues}
                    updateDropdown={formattingData}
                    req={true}
                    setCountry={setcountry}         
                    disabled={props.isInvoiceAddressDuplicate}                               
                  ></SingleDropDown>
                </Form.Group>
              </Col>
              {idCountry !== undefined && (
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>STATE/PROVINCE</Form.Label>
                    {/* <Form.Control type="text" placeholder="California" name="state" defaultValue={(props.sendOldHotelValues===undefined)?(''):(props.sendOldHotelValues.state)} onChange={formattingData} required></Form.Control> */}
                    <SingleDropDown
                      val={states}
                      inpName={"state_id"}
                      sendOldHotelValues={props.sendOldHotelValues}
                      updateDropdown={formattingData}
                      req={false}
                      disabled={props.isInvoiceAddressDuplicate}
                    ></SingleDropDown>
                  </Form.Group>
                </Col>
              )}
              {idCountry !== undefined && (
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>
                      City <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="address_city"
                      onChange={formattingData}
                      isInvalid={
                        props.sendOldHotelValues === undefined ||
                        props.sendOldHotelValues.address_city === undefined
                          ? false
                          : props.sendOldHotelValues.address_city.length < 1 ||
                            props.sendOldHotelValues.address_city.length > 100
                          ? true
                          : false
                      }
                      value={
                        props.sendOldHotelValues === undefined
                          ? ""
                          : props.sendOldHotelValues.address_city
                      }
                      minLength={1}
                      maxLength={101}
                      disabled={props.isInvoiceAddressDuplicate}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              )}
            </Row>
          )}
        </>
        
        {HotelVisible.vat_number === true && (
          <Row>
            <Col md={9}>
              <Form.Group>
                <Form.Label>
                  VAT number <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="vat_number"
                  onChange={formattingData}
                  isInvalid={
                    props.sendOldHotelValues === undefined ||
                    props.sendOldHotelValues.vat_number === undefined
                      ? false
                      : props.sendOldHotelValues.vat_number.length < 4 ||
                        props.sendOldHotelValues.vat_number.length > 499
                      ? true
                      : false
                  }
                  value={
                    props.sendOldHotelValues === undefined
                      ? ""
                      : props.sendOldHotelValues.vat_number
                  }
                  minLength={4}
                  maxLength={500}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default HotelInvoiceInformation;
