import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastService } from "../../services/toast.service";
import actions from "../../store/actions/actions";
import Breadcrumbs from "../Common/Breadcrumbs";
import SingleDropDown from "../Common/singleDropDown";
import Spinner from "../Common/Spiner";
import TimezoneSelect from "react-timezone-select";

const PmsIntegration = (props) => {
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isFormValuesFilled, setIsFormValuesFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPMSHotel, setSelectedPMSHotel] = useState({
    pms_hotel_id: undefined,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formValue, setFormValue] = useState({
    hotel_id: parseInt(props.match?.params?.id),
    pms_id: props.location?.state?.pms_id,
    config_data: {},
  });
  const [formValidator, setFormValidator] = useState(new Map());
  const [pmsHotels, setPmsHotels] = useState([]);
  const [formData, setFormData] = useState({
    fields: [],
    documentLink: false,
  });

  const pmsList = useSelector((state) => state.hotels.pmsList);
  const isAssignHotel = useSelector((state) => state?.hotels?.isAssignHotel);
  const isFormDataFilledSuccess = useSelector(
    (state) => state.hotels.isFormDataFilled
  );
  const isHotelAssigned = useSelector((state) => state.hotels.isHotelAssigned);
  const hotelDetails = useSelector((state) => state.hotels.hotelDetails);

  // on mount set the form Data
  useEffect(() => {
    if (!props.location.state?.form_data) {
      props.history.goBack();
    } else {
      const tempFormData = JSON.parse(props.location.state?.form_data);
      const { fields, document_link } = tempFormData;
      setFormData({
        fields: fields,
        documentLink: document_link,
      });
      fields.forEach((field) => {
        const defaultValue = "default_value" in field ? true : false;
        formValidator.set(field.field_name, defaultValue);
      });
    }
    return () => {
      setIsSubmitted(false);
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (isHotelAssigned) props.history.goBack();
  }, [isHotelAssigned]);

  useEffect(() => {
    const pmsHotels = pmsList?.map((hotel) => {
      return { value: hotel.hotel_name, id: hotel.id };
    });
    setPmsHotels(pmsHotels);
  }, [pmsList]);

  /* Reset pmsList and isFormDataFilled flag on component unmount: to take user to original flow */
  useEffect(() => {
    return () => {
      dispatch(actions.resetPmsList());
    };
  }, []);

  useEffect(() => {
    if (!isAssignHotel) {
      setIsLoading(false);
    }
  }, [isAssignHotel]);

  // if first form is submitted succesfully
  // and integration row is added
  useEffect(() => {
    if (isFormDataFilledSuccess) {
      setIsFormFilled(true);
      setIsLoading(false);
      dispatch(actions.getPmsHotelsList(formValue.pms_id, formValue.hotel_id));
    }
  }, [isFormDataFilledSuccess]);

  const breadcrumbs = [
    { path: "/hotel/list", title: "Hotels" },
    {
      path: `/hotel/update/${props.match.params?.id}`,
      title: hotelDetails?.id && hotelDetails?.name,
    },
    {
      path: `/hotel/update/${props.match.params?.id}/pms-integration}`,
      title: "PMS-Integration",
    },
  ];

  const dispatch = useDispatch();
  const toast = new ToastService();

  // handler for Connect on click event
  const handleOnConnect = () => {
    // if first form is not submitted
    if (!isFormDataFilledSuccess) {
      setIsSubmitted(true);
      const tempFormData = { ...formValue };
      tempFormData.config_data = JSON.stringify(tempFormData.config_data);

      if (isFormValid()) {
        setIsLoading(true);
        dispatch(actions.addFormData(tempFormData));
      } else {
        toast.onWarning("Please fill out the fields correctly");
      }
    } else if (selectedPMSHotel.pms_hotel_id) {
      // if first form is submitted
      setIsLoading(true);
      dispatch(
        actions.assignPmsHotel(formValue.pms_id, formValue.hotel_id, {
          pms_hotel_id: selectedPMSHotel.pms_hotel_id,
        })
      );
    } else {
      toast.onWarning("Please select Hotel!");
    }
  };

  // handler for input change event
  const onInputChange = (e) => {
    let { value, name } = e?.target;
    setFormValue({
      ...formValue,
      config_data: { ...formValue.config_data, [name]: value },
    });
    if (!checkValueIsValid(value)) {
      setFormValidator(new Map(formValidator.set(name, true)));
    } else {
      setFormValidator(new Map(formValidator.set(name, false)));
    }
  };

  // handle for dropdown on change event
  const handleOnDropDownChange = (e) => {
    let { value, _name } = e.target;
    setSelectedPMSHotel({
      pms_hotel_id: value,
    });
  };

  // handle for Cancel on click event
  /* Commented code because it was not redirecting to the form where client secrets need to be entered */
  let handleOnCancel = () => {
    /* if (!isFormDataFilledSuccess) {
      props.history.push(`/hotel/update/${props.match.params.id}`);
    } else {
      props.history.push(
        `/hotel/update/${props.match.params.id}/pms-integration`
      );
    } */
    props.history.goBack();
  };

  const checkFieldIsValid = (field_name) => {
    if (!isSubmitted) return false;
    return checkValueIsValid(formValue.config_data[field_name]);
  };

  const checkValueIsValid = (value) => {
    return value === undefined ? true : value.length < 1 ? true : false;
  };

  const isFormValid = () => {
    return [...formValidator.values()].every((isValid) => isValid);
  };

  // generate dynamic form elements and
  // set default values where needed
  const generateDynamicForm = () => {
    let formInputs = [];
    formData?.fields?.forEach((fieldData) => {
      let dynamicProps = {};
      // if field have default value
      // set it to config and generate prop for input
      if ("default_value" in fieldData) {
        dynamicProps = {
          defaultValue: fieldData?.default_value,
          disabled: true,
        };

        formValue.config_data = {
          ...formValue.config_data,
          [fieldData.field_name]: fieldData.default_value,
        };
      }

      if (fieldData?.type === "Timezone") {
        if (!formValue.config_data[fieldData.field_name])
          formValue.config_data = {
            ...formValue.config_data,
            [fieldData.field_name]:
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
        formInputs.push(
          <Form.Group>
            <Form.Label>
              {fieldData.field_name.replaceAll("_", " ")}{" "}
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <TimezoneSelect
              value={formValue.config_data[fieldData.field_name]}
              onChange={(e) => {
                onInputChange({
                  target: {
                    value: e.value,
                    name: fieldData.field_name,
                  },
                });
              }}
            />
          </Form.Group>
        );
      } else {
        formInputs.push(
          <Form.Group>
            <Form.Label>
              {fieldData.field_name.replaceAll("_", " ")}{" "}
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              name={fieldData.field_name}
              {...(fieldData.default_value ? dynamicProps : {})}
              isInvalid={checkFieldIsValid(fieldData.field_name)}
              onChange={onInputChange}
            ></Form.Control>
          </Form.Group>
        );
      }
    });

    return formInputs;
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="breadcrumbs">
            <Row>
              <Col>
                <Breadcrumbs BreadcrumbData={breadcrumbs} />
              </Col>
            </Row>
          </div>
          <div className="content">
            <Form>
              <Row className="marginBottom">
                <h5>
                  <span className="text-in-content">
                    {props.location?.state?.pms_name}
                  </span>
                </h5>
              </Row>
              <Row className={`${isFormFilled ? "d-none" : ""}`}>
                <Col md={6} sm={8} xs={12}>
                  <>
                    {generateDynamicForm()}
                    <Col>
                      {formData?.documentLink ? (
                        <Form.Group>
                          <p>
                            If you don't know what to enter, please refer to
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${formData?.documentLink}`}
                            >
                              {" "}
                              document.
                            </a>
                          </p>
                        </Form.Group>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </>
                </Col>
              </Row>

              <Row className={`${!isFormFilled ? "d-none" : ""}`}>
                <Col>
                  <>
                    <SingleDropDown
                      req={true}
                      val={pmsHotels}
                      updateDropdown={handleOnDropDownChange}
                      sendOldHotelValues={selectedPMSHotel}
                      inpName="pms_hotel_id"
                    ></SingleDropDown>
                    <br />
                  </>
                </Col>
              </Row>

              <Row>
                <Col md={3} sm={6} xs={6}>
                  <Button onClick={handleOnConnect}>Connect</Button>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Button onClick={handleOnCancel} variant="secondary">
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
export default PmsIntegration;
