import { put, takeLatest } from "redux-saga/effects";
import { STORAGE_KEYS } from "../../helper/constants";
import {
  getAccessToken,
  getDashboardLink,
  getToken,
  removeToken as removeTokenFunction,
} from "../../services/auth.service";
import { Utils } from "../../utils/utils";
import actions from "../actions/actions";
import { StorageService } from "./../../services/storage.service";

const storageService = new StorageService();
function* dispatchSetToken(action) {
  try {
    // initial token value
    removeTokenFunction();
    const token = "user-logged-in";
    const pages = action.pages;
    const userDetails = action.userDetails;
    const dashboard_link = action.userDetails.dashboard_link;
    if (token && token.length && pages) {
      //adding authentication token in local storage
      storageService.setItem(STORAGE_KEYS.token, token);
      storageService.setItem(
        STORAGE_KEYS.UserFirstName,
        userDetails.first_name
      );
      storageService.setItem(STORAGE_KEYS.dashboardLink, dashboard_link);
      //adding page level access object in local storage
      const allowedPagesAccess = JSON.stringify(Utils.filterRoles(pages));
      storageService.setItem(STORAGE_KEYS.allowedPages, allowedPagesAccess);
      yield put({
        type: actions.FETCH_TOKEN_SUCCESS,
        token,
        AccessToken: "",
        dashboard_link,
      });
    } else {
      yield put({
        type: actions.FETCH_TOKEN_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.FETCH_TOKEN_FAILED,
    });
  }
}

function* dispatchRemoveToken() {
  yield put({
    type: actions.FETCH_TOKEN_FAILED,
  });
}

function* dispatchGetToken() {
  try {
    // initial token value
    const token = getToken();
    const AccessToken = getAccessToken();
    const dashboard_link = getDashboardLink();
    // TODO: condition refactor
    if (token && token.length && token !== "null") {
      yield put({
        type: actions.FETCH_TOKEN_SUCCESS,
        token,
        AccessToken,
        dashboard_link,
      });
    } else {
      yield put({
        type: actions.FETCH_TOKEN_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.FETCH_TOKEN_FAILED,
    });
  }
}

function* dispatchSetSessionToken(action) {
  try {
    // initial token value
    removeTokenFunction();
    const { token } = action;
    if (token && token.length) {
      storageService.setItem("session-token", token);
      yield put({
        type: actions.FETCH_SESSION_TOKEN_SUCCESS,
        token,
      });
    } else {
      yield put({
        type: actions.FETCH_SESSION_TOKEN_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.FETCH_SESSION_TOKEN_FAILED,
    });
  }
}

export function* listenGetToken() {
  yield takeLatest(actions.GET_TOKEN, dispatchGetToken);
}

export function* listenSetToken() {
  yield takeLatest(actions.SET_TOKEN, dispatchSetToken);
}

export function* removeToken() {
  yield takeLatest(actions.FETCH_TOKEN_FAILED, dispatchRemoveToken);
}

export function* listenSetSessionToken() {
  yield takeLatest(actions.SET_SESSION_TOKEN, dispatchSetSessionToken);
}
