import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import _NumberFormat from "react-number-format";

const storageService = new StorageService();
export class Utils {
  static replaceDateFormat = (date) => {
    return date?.replace(/-/g, "/");
  };

  static appendDataServiceAndOffers = (keyPairs) => {
    const bodyFormData = new FormData();
    Object.keys(keyPairs).forEach((value, index) => {
      if (value === "data") {
        bodyFormData.append(`${value}`, JSON.stringify(keyPairs[value]));
      }
      if (value === "image") {
        bodyFormData.append(`${value}`, keyPairs[value][0]);
      }
    });
    return bodyFormData;
  };
  static filterRoles = (pages) => {
    if (pages.length) {
      const filterdRoles = pages.map((pageDetail) => {
        return {
          page: pageDetail.page.page,
          pageURl: pageDetail.page.url,
          isAccessAllowed:
            pageDetail.access && +pageDetail.access === 1 ? true : false,
        };
      });
      return filterdRoles;
    }
  };
  static isAccessAllowedByRoute = (path) => {
    const filteredPages = JSON.parse(
      storageService.getItem(STORAGE_KEYS.allowedPages)
    );
    if (filteredPages) {
      return (
        filteredPages?.find((pageInfo) => {
          return pageInfo.pageURl === path;
        })?.isAccessAllowed || false
      );
    }
  };

  static getDDMMYY = (customDate) => {
    const date = new Date(this.replaceDateFormat(customDate))
      ?.toString()
      ?.split(" ");
    const dateNumber = date[2];
    const monthName = date[1];
    const year = date[3];
    return dateNumber && monthName && year
      ? `${dateNumber}  ${monthName} ${year}`
      : "";
  };

  static NumberFormat = (
    prefix = "",
    suffix = "",
    key = new Date(),
    _value,
    className,
    isDisabled,
    isInvalid,
    name,
    handleChange,
    customComponent,
    maxValue = 9999999
  ) => {
    const getValue = () => {
      if (isDisabled && _value === 0) return "";
      return _value;
    };

    const withValueLimit = ({ floatValue }) => floatValue <= maxValue;

    return (
      <_NumberFormat
        thousandSeparator={","}
        decimalSeparator={"."}
        prefix={prefix}
        suffix={suffix}
        key={key}
        type="text"
        value={getValue()}
        className={className}
        disabled={isDisabled}
        isInvalid={isInvalid}
        name={name}
        onChange={handleChange}
        customInput={customComponent}
        decimalScale={2}
        fixedDecimalScale={true}
        isAllowed={withValueLimit}
      />
    );
  };

  static NumberFormatText = (prefix = "", _value, suffix = "") => {
    const getValue = () => {
      if (!_value || _value === 0) return "";
      return _value;
    };
    return (
      <_NumberFormat
        value={getValue().toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        })}
        displayType={"text"}
        thousandSeparator={true}
        prefix={prefix}
        suffix={suffix}
      />
    );
  };
}
