import { combineReducers } from 'redux'
import { authrReducer } from './authr.reducer'
import { CommonReducer } from './common.reducer'
import { constantsReducer } from './constants.reducers'
import { hotelgroupReducer } from './hotelgroup.reducers'
import { hotelsReducer } from './hotels.reducers'
import { loginReducer } from './login.reducers'
import { networkReducer } from './network.reducers'
import { userManagementReducer } from "./userManagement.reducer"

export const rootReducer = combineReducers({
    hotelgroup: hotelgroupReducer,
    hotels: hotelsReducer,
    constants: constantsReducer,
    login:loginReducer,
    network: networkReducer,
    user:userManagementReducer,
    authr:authrReducer,
    common:CommonReducer
})