import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import HotelGroupValidator from "../../services/validator/hotelGroupvalidator";
import nullFormatter from "../../services/validator/nullFormat";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import { ReactComponent as ArrowLeft } from "../Common/arrowLeft.svg";
import pencil from "../Common/pencil.svg";
import Breadcrumbs from "./../Common/Breadcrumbs";
import Spiner from "./../Common/Spiner";
import HotelAssignforHotelGroup from "./HotelAssignforHotelGroup";
import HotelGroupBasicInformation from "./HotelGroupBasicInformation";
import HotelGroupContactInformation from "./HotelGroupContactInformation";

const UpdateHotelGroup = (props) => {
  const dispatch = useDispatch();
  const [editstate, setEditstate] = useState({
    heading: false,
    name: false,
    address_street: false,
    address_zipcode: false,
    address_state: false,
    address_country: false,
    language: false,
    first_name: false,
    middle_name: false,
    last_name: false,
    email: false,
    role: false,
  });
  //to manage roles and permission

  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };

  let hotelGroupDetails = useSelector(
    (state) => state.hotelgroup.hotelGroupDetails
  );
  let result = useSelector((state) => state.hotelgroup.result);
  const breadcrumbs = [
    { path: "/hotel-group/list", title: "Hotel groups" },
    {
      path: `/hotel-group/update/${props.match.params.id}`,
      title: hotelGroupDetails?.name,
    },
  ];
  const history = useHistory();
  let [saveCancel, setSaveCancel] = useState(false);
  let [saveHit, setSaveHit] = useState(false);
  let [formValues, setformValues] = useState();
  let toast = new ToastService();
  const [countryISO, setCountryISO] = useState();

  useEffect(() => {
    dispatch(actions.getHotelGroupDetails(props.match.params.id));
  }, []);

  useEffect(() => {
    if (result.success && result.msg === "Successfully reset" && saveHit) {
      history.push("/hotel-group/list");
      setSaveHit(false);
    }
  }, [result]);
  if (result.success && result.update) {
    dispatch(actions.resetUpdateHotelGroup());
  }

  useEffect(() => {
    if (hotelGroupDetails?.id !== undefined) {
      const languageId =
        hotelGroupDetails?.e_hotelgroup_selected_languages?.map((langInfo) => {
          return langInfo.language_id;
        });
      let oldMapper = {
        name: hotelGroupDetails.name,
        address_street: hotelGroupDetails.address_street,
        address_zipcode: hotelGroupDetails.address_zipcode,
        address_state_id: hotelGroupDetails.address_state_id,
        address_country_id: hotelGroupDetails.address_country_id,
        first_name:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["first_name"],
        middle_name:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["middle_name"] === null
            ? ""
            : hotelGroupDetails.contact_info_user["middle_name"],
        last_name:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["last_name"],
        email:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["email"],
        contact_role_id: 1,
        address_city:
          hotelGroupDetails.address_city === null
            ? ""
            : hotelGroupDetails.address_city,
        phone_number:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["phone_number"] === null
            ? ""
            : hotelGroupDetails.contact_info_user["phone_number"],
        phone_number_country:
          hotelGroupDetails.contact_info_user === null
            ? ""
            : hotelGroupDetails.contact_info_user["phone_number_country"] ===
              null
            ? ""
            : hotelGroupDetails.contact_info_user["phone_number_country"],
        language_ids: languageId ? languageId : [],
      };

      setformValues(oldMapper);
    }
  }, [saveCancel, hotelGroupDetails]);
  useEffect(() => {
    dispatch(actions.getCountries());
  }, []);

  const updateAddress = () => {
    Object.keys(editstate).map((v, i) => {
      if (!editstate[v]) {
        setSaveCancel(true);
      }
    });
    setEditstate({
      ...editstate,
      address_street: !editstate.address_street,
      address_zipcode: !editstate.address_zipcode,
      address_state: !editstate.address_state,
      address_country: !editstate.address_country,
      heading: true,
    });
  };
  const updateContact = () => {
    Object.keys(editstate).map((v, i) => {
      if (!editstate[v]) {
        setSaveCancel(true);
      }
    });
    setEditstate({
      ...editstate,
      first_name: !editstate.first_name,
      middle_name: !editstate.middle_name,
      last_name: !editstate.last_name,
      email: !editstate.email,
      role: !editstate.role,
      heading: true,
    });
  };
  const updateHotelGroupName = () => {
    Object.keys(editstate).map((v, i) => {
      if (!editstate[v]) {
        setSaveCancel(true);
      }
    });
    setEditstate({ ...editstate, name: !editstate.name, heading: true });
  };

  const updateBulkValues = (values) => {
    setformValues({ ...formValues, ...values });
  };

  let updateformValues = (event) => {
    if (
      event.target.name === "address_country_id" &&
      event.target.value === undefined
    ) {
      delete formValues.address_country_id;
      delete formValues.address_city;
    }
    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        setformValues((value) => ({
          ...value,
          [event.target.name]: event.target.value,
        }));
      } else {
        setformValues((value) => ({ ...value, [event.target.name]: "" }));
      }
    } catch (err) {
      setformValues((value) => ({
        ...formValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  let cancelButton = (e) => {
    setSaveCancel(!saveCancel);
    setEditstate({
      ...editstate,
      heading: false,
      name: false,
      address_street: false,
      address_zipcode: false,
      address_state: false,
      address_country: false,
      language: false,
      first_name: false,
      middle_name: false,
      last_name: false,
      email: false,
      role: false,
    });
  };

  const updateHotelGroup = (e) => {
    if (e.keyCode !== 13) {
      setSaveHit(true);
      e.preventDefault();
      let validating = HotelGroupValidator(formValues);

      let notMandatoryField = ["middle_name", "address_state_id"];
      let flag = true;
      setformValues(validating);

      Object.keys(validating).forEach((validKeys, i) => {
        if (
          validating[validKeys] === "" &&
          !notMandatoryField.includes(validKeys)
        ) {
          flag = false;
        }
      });
      if (flag) {
        let nullFormatted = nullFormatter(formValues);
        nullFormatted.phone_number_country = countryISO;
        dispatch(
          actions.updateHotelGroup(props.match.params.id, nullFormatted)
        );
        e.target.reset();
        cancelButton();
        dispatch(actions.resetEmail());
      } else {
        toast.onWarning("Please fill out the fields correctly");
      }
    }
  };

  const intlTelInputRef = useRef();

  useEffect(() => {
    if (
      intlTelInputRef.current &&
      hotelGroupDetails?.contact_info_user?.phone_number_country &&
      hotelGroupDetails?.contact_info_user?.phone_number_country?.length > 0
    ) {
      setTimeout(() => {
        intlTelInputRef.current.setFlag(
          hotelGroupDetails?.contact_info_user.phone_number_country
        );
      },100)
      
      if (intlTelInputRef.current?.tel) {
        const intlInputParentDOM = intlTelInputRef.current?.tel?.parentElement;
        if (intlInputParentDOM) {
          const dialcodeDOM =
            intlInputParentDOM.firstElementChild.querySelector(
              ".selected-dial-code"
            );
          if (dialcodeDOM) {
            dialcodeDOM.classList.remove("selected-dial-code");
            dialcodeDOM.style.marginLeft = "5px";
          }
        }
      }
    }
  }, [intlTelInputRef, hotelGroupDetails]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6} className="extra-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="white"
                      onClick={() => {
                        props.history.push("/hotel-group/list");
                      }}
                    >
                      <ArrowLeft />
                    </Button>
                  </td>
                  <td className="p-3"> Back </td>
                </tr>
              </tbody>
            </table>
          </Col>
          {/* <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            <Button variant="white">
              <Vectors />
            </Button>
          </Col> */}
        </Row>
      </div>
      <Col md={6} sm={6} xs={6}>
        <h3>Edit hotel group </h3>
      </Col>
      <div className="content">
        {hotelGroupDetails ? (
          <>
            <Form onSubmit={updateHotelGroup}>
              <Row>
                <Col>
                  <Row>
                    {hiddenIconAsPerRole(PRIVATE_ROUTES.update_hotel_group) ? (
                      <span className="alignRight">
                        {saveCancel && (
                          <Button
                            variant="outline-success"
                            type="submit"
                            size="sm"
                            className="m-1 save-btn-size"
                          >
                            Save changes
                          </Button>
                        )}
                        {saveCancel && (
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={cancelButton}
                          >
                            Cancel
                          </Button>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
                <h5>
                  {!editstate.name && (
                    <span className="text-in-content">
                      {hotelGroupDetails?.name}{" "}
                    </span>
                  )}

                  {!editstate.name && (
                    <sup>
                      <img
                        src={pencil}
                        alt="pencil-icon"
                        onClick={updateHotelGroupName}
                      />
                    </sup>
                  )}
                </h5>
                <Col>
                  {editstate.name && (
                    <HotelGroupBasicInformation
                      updateState={editstate}
                      disabled={true}
                      updateform={updateformValues}
                      oldHotelValues={formValues}
                    />
                  )}
                  <h6>
                    {!editstate.address_street && (
                      <span>
                        Address:{" "}
                        <sup>
                          <img src={pencil} onClick={updateAddress} />
                        </sup>
                      </span>
                    )}
                  </h6>
                  {!editstate.address_street && (
                    <p>                      
                      {hotelGroupDetails?.address_street},{" "}
                      {hotelGroupDetails?.address_city}-
                      {hotelGroupDetails?.address_zipcode}
                      <br />
                      {hotelGroupDetails?.address_state === undefined
                        ? ""
                        : hotelGroupDetails?.address_state?.name}
                      ,
                      {hotelGroupDetails?.address_state === undefined
                        ? ""
                        : hotelGroupDetails?.address_country?.name}
                    </p>
                  )}
                  {editstate.address_street && !editstate.name && (
                    <HotelGroupBasicInformation
                      updateState={editstate}
                      updateform={updateformValues}
                      oldHotelValues={formValues}
                      disabled={true}
                    />
                  )}
                  <br></br>
                </Col>
                <Col>
                  <Row>
                    {!editstate.first_name && (
                      <Col>
                        <h6>
                          <span>
                            Contact:{" "}
                            <sup>
                              <img src={pencil} onClick={updateContact} />
                            </sup>
                          </span>
                        </h6>
                        <p>
                          <strong>
                            {formValues === undefined
                              ? ""
                              : formValues.first_name +
                                " " +
                                formValues.middle_name +
                                " " +
                                formValues.last_name}
                            <br />
                          </strong>
                          Super Admin
                        </p>
                      </Col>
                    )}
                    {!editstate?.first_name && (
                      <Col className="hotel-update-contact">
                        <span></span>
                        <br />
                        <p>
                          {formValues === undefined ? "" : formValues.email}
                          <br />
                          <Card.Text>
                            <IntlTelInput
                              containerClassName="intl-tel-input"
                              disabled={true}
                              input
                              type="tel"
                              defaultValue={formValues?.phone_number || ""}
                              value={formValues?.phone_number || ""}
                              fieldName="phone_number"
                              separateDialCode={true}
                              ref={intlTelInputRef}
                            />
                          </Card.Text>
                        </p>
                      </Col>
                    )}
                    {editstate.first_name && (
                      <HotelGroupContactInformation
                        oldHotelValues={formValues}
                        updateState={editstate}
                        updateform={updateformValues}
                        updateBulkValues={updateBulkValues}
                        setCountryISO={setCountryISO}
                      ></HotelGroupContactInformation>
                    )}
                  </Row>
                  <br />
                </Col>
              </Row>
            </Form>

            <Col md={6} sm={6} xs={6}>
              <span className="text-in-content p-2">
                <h4>Hotel list </h4>
              </span>
            </Col>
            <Row>
              <Col>
                <Row>
                  <HotelAssignforHotelGroup
                    {...props}
                    queryParamId={hotelGroupDetails?.id}
                  />
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <Spiner />
        )}
      </div>
    </React.Fragment>
  );
};

export default UpdateHotelGroup;
