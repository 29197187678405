import React, { useEffect, useState } from "react";
import { Col, Form as bootstrapForm, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import LanuageDropDown from "../Common/LanguageDropDown";

const UserAccountInformation = (props) => {
  const dispatch = useDispatch();
  const [languageList, setlanguageList] = useState([]);
  const languages = useSelector((state) => state.constants.languages);
  useEffect(() => {
    dispatch(actions.getLanguages());
  }, []);
  useEffect(() => {
    if (languages) {
      const langList = languages?.map((lang) => {
        return { value: lang.code, id: lang.id };
      });
      setlanguageList(langList);
    }
  }, [languages]);
  return (
    <React.Fragment>
      <h5>Account information</h5>
      {/* <p>
                Dignissim dolor elementum duis tempus rhoncus consectetur nulla quam massa
            </p> */}
      <Row>
        <Col md={9} xs={12}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Email Address<span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <bootstrapForm.Control
              type="email"
              name="email"
              isInvalid={props.touched.email && props.error?.email}
              value={props.values?.email}
              onChange={props.change}
              disabled
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={9} xs={12}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Password <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <bootstrapForm.Control
              type="password"
              placeholder="********"
              name="password"
              isInvalid={props.touched.password && props.error?.password}
              value={props.values?.password}
              onChange={props.change}
              disabled
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
        <Col className="reset-password-label">
          <span onClick={props.resetPassword}> Change password</span>
        </Col>
      </Row>
      <Row>
        <Col md={9} xs={12}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              User Role <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <bootstrapForm.Control as="select" defaultValue="Editor" disabled>
              <option disabled>Select role</option>
              <option value="admin">
                {props.selectedRole?.label ? props.selectedRole.label : ""}
              </option>
            </bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={9} xs={12}>
          <bootstrapForm.Group>
            {/* <bootstrapForm.Label>Language</bootstrapForm.Label> */}
            {/* <bootstrapForm.Control as="select" defaultValue="English" required>
                                <option disabled>Select language</option>
                                <option value="chinese">Chinese</option>
                                <option value="russian">Russian</option>
                            </bootstrapForm.Control> */}
            {/* <DropDown
                            lang={languages}
                            updateform={props.updateform}
                            sendOldHotelValues={props.oldHotelValues}
                            inpName={'language_ids'}
                            req={true}
                        ></DropDown> */}
            <bootstrapForm.Label>
              Language <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <LanuageDropDown
              val={languageList}
              inpName={"language_id"}
              touched={props.touched}
              errors={props.error}
              updateDropdown={props.updateDropdown}
              values={props.value}
            ></LanuageDropDown>
          </bootstrapForm.Group>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserAccountInformation;
