
import { call, put, takeLatest } from 'redux-saga/effects';
import HotelGroupService from '../../services/HotelGroupService/index';
import actions from '../actions/actions';

let columnMapForReducer ={
    'name':'HOTEL GROUP',
    'email':'EMAIL' ,
    'contact_person':'CONTACT PERSON',
    'hotels_count':'HOTELS'
}


let columnMapForHotelReducer = {
    "name":"HOTEL NAME",
    "hotel_group_name":"HOTEL GROUP",
    "email":"EMAIL",
    "type_of_subscription":"SUBSCRIPTION TYPE",
    "amount":"MONTHLY FEE(€)",
    "commision_percentage":"COMMISSION%",
    "country_name":"COUNTRY",
  };

const HotelGroupServ = new HotelGroupService();
const fetchHotelGroups = async (page, limit) => {
    try {
        const response = await HotelGroupServ.getHotelGroup(page, limit)
        return response
    } catch (err) {
        throw err;
    }
}

const fetchSortedHotelGroups = async (page, limit, columnName, sortBy) => {
    try {
        const response = await HotelGroupServ.getSortedHotelGroup(page, limit, columnName, sortBy)
        return response
    } catch (err) {
        throw err;
    }

}

const fetchSearchSortedHotelGroups = async (keyword, page, limit, columnName, sortBy) => {
    try {
        
        const response = await HotelGroupServ.getSearchSortedHotelGroup(keyword, page, limit, columnName, sortBy)
        return response
    } catch(err) {
        throw err;
    }

}


const fetchHotelsFromHotelGroup = async (id, page, limit, sort, sortBy, searchKey) => {
    try {
        const response = await HotelGroupServ.getHotelsFromHotelGroup(id, page, limit, sort, sortBy, searchKey)
        return response
    } catch (err) {
        throw err;
    }
}
const unAssignHotelFromHotelGroup = async (hotelGroupId, hotelId) => {
    try {
        
        const response = await HotelGroupServ.unAssignHotelFromHotelGroup(hotelGroupId, hotelId)
        return response
    } catch (err) {
        throw err;
    }
}

const createHotelGroup = async (data) => {
    try {
        const response = await HotelGroupServ.createHotelGroup(data)
        return response
    } catch (err) {
        throw err;
    }
}

const assignHotelForHotelGroup = async (hotelGroupId, hotelsId) => {
    try {
        
        const response = await HotelGroupServ.assignHotelForHotelGroup(hotelGroupId, hotelsId)
        return response
    } catch (err) {
        throw err;
    }
}
const fetchHotelGroupDetails = async (id) => {
    try {
        const response = await HotelGroupServ.getHotelGroupDetails(id)
        return response
    } catch (err) {
        throw err;
    }
}
const fetchOrphanHotels = async (id) => {
    try {
        const response = await HotelGroupServ.getOrphanHotels()
        return response
    } catch (err) {
        throw err;
    }
}
const updateHotelGroup = async (hotelGroupId, data) => {
    try {
    
        const response = await HotelGroupServ.updateHotelGroup(hotelGroupId, data)
        return response
    } catch (err) {
        throw err;
    }
}

const fetchhotelGroupName = async (hotelGroupName) => {
    try {
        const response = await HotelGroupServ.gethotelGroupName(hotelGroupName)
        return response
    } catch (err) {
        throw err;
    }
}

const fetchCheckEmail = async (email) => {
    try {
        const response = await HotelGroupServ.getCheckEmail(email)
        return response
    } catch (err) {
        throw err;
    }
}

function* dispatchGetHotelGroups(action) {
    try {
        const response = yield call(fetchHotelGroups, action.page, action.limit)
        if (response?.data?.success) {
            yield put({
                type: actions.FETCH_HOTELGROUPS_SUCESS,
                data: response.data.data,
                msg: response.msg
            })
            yield put({
                type: actions.SET_HOTEL_GROUP_LIST_PAGE_NO,
                page: response?.data?.data.page,
            });
            yield put({
                type: actions.SET_HOTEL_GROUP_SORT,
                sortBy: action.sortBy,
                sortName: action.columnName,
                sortColumnName: columnMapForReducer[action.columnName] ? columnMapForReducer[action.columnName] : "HOTEL GROUP",
                sort: action.sortBy === "DESC" ? true : false
            });
             
        } else {
            yield put({
                type: actions.FETCH_HOTELGROUPS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchGetSortedHotelGroups(action) {
    try {
        const response = yield call(fetchSortedHotelGroups, action.page, action.limit, action.columnName, action.sortBy)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_HOTELGROUPS_SUCESS,
                data: response.data.data,
                msg: response.msg
            })
            yield put({
                type: actions.RESET_HOTEL_LIST,
            })
            yield put({
                type: actions.CLEAR_HOTEL_GROUP_BY_ID,
            })
            yield put({
                type: actions.SET_HOTEL_GROUP_LIST_PAGE_NO,
                page: response?.data?.data.page,
              });
              yield put({
                type: actions.SET_HOTEL_GROUP_SORT,
                sortBy: action.sortBy,
                sortName: action.columnName,
                sortColumnName: columnMapForReducer[action.columnName] ? columnMapForReducer[action.columnName] : "HOTEL GROUP",
                sort: action.sortBy === "DESC" ? true : false
              });
        } else {
            yield put({
                type: actions.FETCH_HOTELGROUPS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}


function* dispatchGetSearchSortedHotelGroups(action) {
    try {
        const response = yield call(fetchSearchSortedHotelGroups, action.payload, action.page_no, action.limit, action.columnName, action.sortBy)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_HOTELGROUPS_SUCESS,
                data: response.data.data,
                msg: response.msg
            })
            yield put({
                type: actions.SET_HOTEL_GROUP_LIST_PAGE_NO,
                page: response?.data?.data.page,
              });
              yield put({
                type: actions.SET_HOTEL_GROUP_SORT,
                sortBy: action.sortBy,
                sortName: action.columnName,
                sortColumnName: columnMapForReducer[action.columnName] ? columnMapForReducer[action.columnName] : "HOTEL GROUP",
                sort: action.sortBy === "DESC" ? true : false
              });
        } else {
            yield put({
                type: actions.FETCH_HOTELGROUPS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.FETCH_HOTELGROUPS_FAILED,
            msg: error.response.data.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchGetHotelGroupDetails(action) {
    try {
        const response = yield call(fetchHotelGroupDetails, action.id)

        if (response.data.success) {
            yield put({
                type: actions.FETCH_HOTELGROUP_DETAILS_SUCESS,
                data: response.data.data,
                msg: response.msg
            })
        } else {
            yield put({
                type: actions.FETCH_HOTELGROUP_DETAILS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.FETCH_HOTELGROUP_DETAILS_FAILED,
            msg: error.response.data.msg
        })
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchGetHotelFromHotelGroups(action) {
    try {
        const response = yield call(fetchHotelsFromHotelGroup, action.id, action.page, action.limit, action.sort, action.sortBy, action.searchKey)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_HOTELS_FROM_HOTELGROUP_SUCESS,
                data: response.data.data,
                msg: response.data.msg
            })
            yield put({
                type: actions.SET_HOTEL_LIST_PAGE_NO,
                page: response?.data?.data.page,
              });
              yield put({
                type: actions.SET_HOTEL_SORT,
                sortBy: action.sortBy,
                sortName: action.sort,
                sortColumnName: columnMapForHotelReducer[action.sort] ? columnMapForHotelReducer[action.sort] : "HOTEL NAME",
                sort: action.sortBy === "DESC" ? true : false
              });
        } else {
            yield put({
                type: actions.FETCH_HOTELS_FROM_HOTELGROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchUnassignHotelFromHotelGroup(action) {
    try {
        const response = yield call(unAssignHotelFromHotelGroup, action.hotelGroupId, action.hotelId)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_SUCESS,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchCreateHotelGroup(action) {
    try {
        const response = yield call(createHotelGroup, action.payload)
        if (response.data.success) {
            yield put({
                type: actions.ADD_HOTELGROUP_SUCESS,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.ADD_HOTELGROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchassignHotelForHotelGroup(action) {
    try {
        const response = yield call(assignHotelForHotelGroup, action.hotelGroupId, action.hotelsId)
        if (response.data.success) {
            yield put({
                type: actions.ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_SUCESS,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchSearch(action) {
    try {
        let response = yield call(searchKeyword, action.payload, action.page_no, action.limit)
        if (response.success) {
            yield put({
                type: actions.FETCH_HOTELGROUPS_SUCESS,
                data: response.data,
                msg: response.msg
            })
        } else {
            yield put({
                type: actions.FETCH_HOTELGROUPS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

const searchKeyword = async (keyword, page_no, limit) => {
    let HotelGroupServ = new HotelGroupService();
    const response = await HotelGroupServ.getSearchGroup(keyword, page_no, limit)
    return response.data
}

function* dispatchGetOrphanHotels(action) {
    try {
        let response = yield call(fetchOrphanHotels)
        if (response.data.success) {
            yield put({
                type: actions.FETCH_ORPHAN_HOTELS_SUCESS,
                data: response.data.data,
                msg: response.msg
            })
        } else {
            yield put({
                type: actions.FETCH_ORPHAN_HOTELS_FAILED,
                msg: response.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}

function* dispatchUpdateHotelGroup(action) {
    try {
        const response = yield call(updateHotelGroup, action.hotelGroupId, action.payload)
        if (response.data.success) {
            yield put({
                type: actions.UPDATE_HOTEL_GROUP_SUCESS,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.UPDATE_HOTEL_GROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.API_FAILED,
            data: error?.response
        })
    }
}


function* dispatchResetUpdateHotelGroup(action) {

    yield put({
        type: actions.FETCH_HOTELGROUP_DETAILS_SUCESS,
        msg: "Successfully reset",
        data: {}
    })
}

function* dispatchhotelGroupName(action) {
    try {
        const response = yield call(fetchhotelGroupName, action.hotelGroupName)
        if (response.data.success) {
            yield put({
                type: actions.CHECK_HOTEL_GROUP_SUCCESS,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.CHECK_HOTEL_GROUP_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        if(error.response.status==400){
            yield put({
                type: actions.CHECK_HOTEL_GROUP_FAILED,
                msg: error.response.data.msg
            })
        }else{

            yield put({
                type: actions.API_FAILED,
                data: error?.response
            })
        }
    }
}

function* dispatchCheckEmail(action) {
    try {
        const response = yield call(fetchCheckEmail, action.email)
        if (response.data.success) {
            yield put({

                type: actions.CHECK_HOTEL_GROUP_USER_EMAIL_SUCCESS,
                data: response.data.data,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.CHECK_HOTEL_GROUP_USER_EMAIL_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        
        if(error.response.status == 400){
            yield put({
                type: actions.CHECK_HOTEL_GROUP_USER_EMAIL_FAILED,
                msg: error.response.data.msg
            })
        }else{

            yield put({
                type: actions.API_FAILED,
                data: error?.response
            })
        }
        
    }
}





export function* listenGetHotelGroups() {
    yield takeLatest(actions.GET_HOTELGROUPS, dispatchGetHotelGroups)
}

export function* listenGetSortedHotelGroups() {
    yield takeLatest(actions.GET_SORTED_HOTELGROUPS, dispatchGetSortedHotelGroups)
}

export function* listenGetSearchSortedHotelGroups() {
    yield takeLatest(actions.SEARCH_SORTED, dispatchGetSearchSortedHotelGroups)
}

export function* listenGetHotelGroupDetails() {
    yield takeLatest(actions.GET_HOTELGROUP_DETAILS, dispatchGetHotelGroupDetails)
}
export function* listenGetHotelByHotelGroups() {
    yield takeLatest(actions.GET_HOTELS_FROM_HOTELGROUP, dispatchGetHotelFromHotelGroups)
}

export function* listenUnassignHotelFromHotelGroup() {
    yield takeLatest(actions.UNASSIGN_HOTEL_FROM_HOTELGROUP, dispatchUnassignHotelFromHotelGroup)
}

export function* listenassignHotelForHotelGroup() {
    yield takeLatest(actions.ASSIGN_HOTEL_FOR_HOTELGROUP, dispatchassignHotelForHotelGroup)
}

export function* listenCreateHotelGroup() {
    yield takeLatest(actions.ADD_HOTELGROUP, dispatchCreateHotelGroup)
}

export function* searchHotelGroup() {
    yield takeLatest(actions.SEARCH, dispatchSearch)
}

export function* listenGetOrhanHotels() {
    yield takeLatest(actions.GET_ORPHAN_HOTELS, dispatchGetOrphanHotels)
}

export function* listenUpdateHotelGroup() {
    yield takeLatest(actions.UPDATE_HOTEL_GROUP, dispatchUpdateHotelGroup)
}

export function* listenResetUpdateHotelGroup() {
    yield takeLatest(actions.RESET_UPDATE_HOTEL_GROUP, dispatchResetUpdateHotelGroup)
}

export function* listenhotelGroupName() {
    yield takeLatest(actions.CHECK_HOTEL_GROUP, dispatchhotelGroupName)
}

export function* listenCheckEmail() {
    yield takeLatest(actions.CHECK_HOTEL_GROUP_USER_EMAIL, dispatchCheckEmail)
}

