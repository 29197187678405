export const GET_HOTELGROUPS = 'GET_HOTELGROUPS'
export const FETCH_HOTELGROUPS_SUCESS = 'FETCH_HOTELGROUPS_SUCESS'
export const FETCH_HOTELGROUPS_FAILED = 'FETCH_HOTELGROUPS_FAILED'

export const GET_HOTELGROUP_DETAILS = 'GET_HOTELGROUP_DETAILS'
export const FETCH_HOTELGROUP_DETAILS_SUCESS = 'FETCH_HOTELGROUP_DETAILS_SUCESS'
export const FETCH_HOTELGROUP_DETAILS_FAILED = 'FETCH_HOTELGROUP_DETAILS_FAILED'

export const GET_HOTELS_FROM_HOTELGROUP = 'GET_HOTELS_FROM_HOTELGROUP'
export const FETCH_HOTELS_FROM_HOTELGROUP_SUCESS = 'FETCH_HOTELS_FROM_HOTELGROUP_SUCESS'
export const FETCH_HOTELS_FROM_HOTELGROUP_FAILED = 'FETCH_HOTELS_FROM_HOTELGROUP_FAILED'

export const UNASSIGN_HOTEL_FROM_HOTELGROUP = 'UNASSIGN_HOTEL_FROM_HOTELGROUP'
export const FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_SUCESS = 'FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_SUCESS'
export const FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_FAILED = 'FETCH_UNASSIGN_HOTEL_FROM_HOTELGROUP_FAILED'

export const ADD_HOTELGROUP = 'ADD_HOTELGROUP'
export const ADD_HOTELGROUP_SUCESS = 'ADD_HOTELGROUP_SUCESS'
export const ADD_HOTELGROUP_FAILED = 'ADD_HOTELGROUP_FAILED'

export const ASSIGN_HOTEL_FOR_HOTELGROUP = 'ASSIGN_HOTEL_FOR_HOTELGROUP'
export const ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_SUCESS = 'ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_SUCESS'
export const ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_FAILED = 'ADD_ASSIGN_HOTEL_FOR_HOTELGROUP_FAILED'

export const SEARCH = 'SEARCH'

export const GET_ORPHAN_HOTELS = 'GET_ORPHAN_HOTELS'
export const FETCH_ORPHAN_HOTELS_SUCESS = 'FETCH_ORPHAN_HOTELS_SUCESS'
export const FETCH_ORPHAN_HOTELS_FAILED = 'FETCH_ORPHAN_HOTELS_FAILED'

export const UPDATE_HOTEL_GROUP = 'UPDATE_HOTEL_GROUP'
export const UPDATE_HOTEL_GROUP_SUCESS = 'UPDATE_HOTEL_GROUP_SUCESS'
export const UPDATE_HOTEL_GROUP_FAILED = 'UPDATE_HOTEL_GROUP_FAILED'

export const GET_SORTED_HOTELGROUPS  = 'GET_SORTED_HOTELGROUPS'

export const RESET_UPDATE_HOTEL_GROUP = 'RESET_UPDATE_HOTEL_GROUP'

export const SEARCH_SORTED = "SEARCH_SORTED" 

export const CHECK_HOTEL_GROUP = "CHECK_HOTEL_GROUP"
export const CHECK_HOTEL_GROUP_SUCCESS = "CHECK_HOTEL_GROUP_SUCCESS"
export const CHECK_HOTEL_GROUP_FAILED = "CHECK_HOTEL_GROUP_FAILED"

export const CHECK_HOTEL_GROUP_USER_EMAIL = "CHECK_HOTEL_GROUP_USER_EMAIL"
export const CHECK_HOTEL_GROUP_USER_EMAIL_SUCCESS = "CHECK_HOTEL_GROUP_USER_EMAIL_SUCCESS"
export const CHECK_HOTEL_GROUP_USER_EMAIL_FAILED = "CHECK_HOTEL_GROUP_USER_EMAIL_FAILED"

export const RESET_EMAIL = "RESET_EMAIL"
export const SET_HOTEL_GROUP_SORT= "SET_HOTEL_GROUP_SORT"
export const SET_HOTEL_GROUP_LIST_PAGE_NO = "SET_HOTEL_GROUP_LIST_PAGE_NO"

export const SET_SEARCH_HOTEL_GROUP = "SET_SEARCH_HOTEL_GROUP"

export const SET_SEARCH_HOTEL_GROUP_HOTEL = "SET_SEARCH_HOTEL_GROUP_HOTEL"

export const getHotelGroup = (page, limit) => {
    return {
        type: GET_HOTELGROUPS,
        page,
        limit
    }
}

export const getSortedHotelGroup = (page, limit, columnName, sortBy) => {
    return {
        type: GET_SORTED_HOTELGROUPS,
        page,
        limit,
        columnName,
        sortBy
    }
}

export const search_action = (keyword, page_no, limit) =>{
    return {
        type : SEARCH,
        payload: keyword,
        page_no,
        limit
    }
}

export const search_sorted_action = (keyword, page_no, limit, columnName, sortBy) =>{
    return {
        type : SEARCH_SORTED,
        payload: keyword,
        page_no,
        limit,
        columnName, 
        sortBy
    }
}

export const getHotelGroupDetails = (id) => {
    return {
        type: GET_HOTELGROUP_DETAILS,
        id,
    }
}
export const getHotelsFromHotelGroup = (id, page, limit, sort="", sortBy="", searchKey="") => {
    return {
        type: GET_HOTELS_FROM_HOTELGROUP,
        id,
        page,
        limit,
        sort, 
        sortBy, 
        searchKey
    }
}

export const UnassignHotelFromHotelGroup = (hotelGroupId,hotelId) => {
    return {
        type: UNASSIGN_HOTEL_FROM_HOTELGROUP,
        hotelGroupId,
        hotelId
    }
}

export const assignHotelForHotelGroup = (hotelGroupId,hotelsId) => {
    return {
        type: ASSIGN_HOTEL_FOR_HOTELGROUP,
        hotelGroupId,
        hotelsId,
    }
}

export const CreateHotelGroup = (data) => {
    return {
        type: ADD_HOTELGROUP,
        payload:data
    }
}

export const getOrphanHotels = (page, limit) => {
    return {
        type: GET_ORPHAN_HOTELS,
        page,
        limit
    }
}

export const updateHotelGroup = (hotelGroupId,data) => {
    return {
        type: UPDATE_HOTEL_GROUP,
        hotelGroupId,
        payload:data,
    }
}

export const resetUpdateHotelGroup = ()=>{
    return{
        type:RESET_UPDATE_HOTEL_GROUP
    }
}

export const checkHotelGroup = (hotelGroupName)=>{
    return{
        type:CHECK_HOTEL_GROUP,
        hotelGroupName
    }
}

export const checkEmail = (email)=>{
    return{
        type:CHECK_HOTEL_GROUP_USER_EMAIL,
        email
    }
}

export const resetEmail =()=>{
    return{
        type:RESET_EMAIL
    }
}

export const setSearchHotelGroup = (searchTerm)=>{
    return{
        type: SET_SEARCH_HOTEL_GROUP,
        searchTerm

    }
}

export const setSearchHotelGroupHotel = (searchTerm)=>{
    return{
        type: SET_SEARCH_HOTEL_GROUP_HOTEL,
        searchTerm
    }
}
