import { Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import actions from '../../../store/actions/actions';
import ConfirmWindow from "../ConfirmWindow";
import expectmelogo from '../icons/expectmelogo.svg';
import Logo from '../SidebarSvg/Logo.svg';

const ForgetPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = React.useState(false);
    const isFogotPasswordApiSuccess = useSelector((state) => state?.network?.isLoading);
    const [email, setEmail] = useState({ email: "" });
    const formInitialValue = {
        email: "",
    };
    const formSchema = yup.object().shape({
        email: yup.string().required('Required'),
    });

    const backToLogin = () => {
        history.push("/login");
    }
    const forgotPassword = (payload) => {
        dispatch(actions.forgotPassword(payload));
    }
    useEffect(() => {
        if (isFogotPasswordApiSuccess) {
            setModalShow(isFogotPasswordApiSuccess)
        }
    }, [isFogotPasswordApiSuccess])

    const isConfirm = () => {
        history.push("/reset-password");
    }
    const redirctToResetPassword = () => {
        history.push("/reset-password");
    }

    return (
        <React.Fragment>
            <Formik initialValues={{
                ...formInitialValue,
            }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values
                    }
                    forgotPassword(payload)

                }}
            >
                {({
                    handleChange,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <Row className="noMargin">
                            <Col md={6} sm={12} xs={12} className="loginleft">
                                <div className="logo">
                                    <img src={expectmelogo} />
                                </div>
                                <div className="dashborad-login-form">
                                    <div>
                                        <span className="welcometext">Forgot Password ?</span><br/>
                                        <span className="welcome-info-text">
                                            We have you covered. Enter your email and we will send a new password.
                                        </span><br/><br/>
                                    </div>
                                    <BootstrapForm.Group>
                                        <BootstrapForm.Label>Your Email <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                        <BootstrapForm.Control
                                            type="email" name="email" onChange={handleChange} isInvalid={touched?.email && errors?.email} value={values?.email}
                                        ></BootstrapForm.Control>
                                    </BootstrapForm.Group>
                                    <Row className="mt-3">
                                        <Col md={6} sm={6} xl={6}>
                                            <Button variant="primary" type="submit">Submit</Button>
                                        </Col>
                                        <Col md={6} sm={6} xl={6}>
                                            <Button variant="secondary" onClick={backToLogin}>Back to Login</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col className="login-right-panel noPadding">
                                <div className="background-login">

                                </div>
                                <div className="dashboard-header-login">
                                    <img src={Logo} />
                                </div>
                            </Col>
                            <ConfirmWindow
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                redirctToResetPassword={isConfirm}
                                header={"Confirm"}
                                body={
                                    "Verification code sent to your email id"
                                }
                            />
                        </Row>
                    </Form>)}
            </Formik>
        </React.Fragment>

    )
}

export default ForgetPassword;