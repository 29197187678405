import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RES_CODE } from "../helper/constants";
import { removeToken } from "../services/auth.service";
import { ToastService } from "../services/toast.service";
import actions from "./../store/actions/actions";

export const APIErrorHandling = (props) => {
  const toastService = new ToastService();
  const apiErrorListing = useSelector((state) => state.network.data);
  const dispatch = useDispatch();
  useEffect(() => {
    handleAPIResponse();
  }, [apiErrorListing]);

  // handling all responses
  const handleAPIResponse = () => {
    // TODO: status code from constance
    // if(+apiErrorListing?.status === 401) {
    //     // removing token
    //     removeToken();
    //     dispatch(actions.removeToken());
    // }
    if (apiErrorListing && apiErrorListing?.message === "Network Error") {
      internalServerErr();
    }
    switch (+apiErrorListing?.status) {
      case +RES_CODE.UNAUTHORIZED: {
        if (
          apiErrorListing.headers.isrefreshtokenexpired === "1" ||
          apiErrorListing.headers.isrefreshtokenexpired === "undefined" ||
          apiErrorListing.headers.isrefreshtokenexpired === undefined
        ) {
          // toastService.onFailure(apiErrorListing?.data?.msg);
          removeToken();
          dispatch(actions.removeToken());
          break;
        } else {
          // toastService.onFailure(apiErrorListing?.data?.msg);
        }
      }
      case +RES_CODE.FORBIDDEN: {
        toastService.onFailure(apiErrorListing?.data?.msg);
        break;
      }
      case +RES_CODE.BAD_REQ: {
        toastService.onWarning(apiErrorListing?.data?.msg);
        break;
      }
      case +RES_CODE.INTERNAL_SERVER: {
        // original msg ===> apiResponseListing?.data?.msg
        internalServerErr();
        break;
      }
      case +RES_CODE.SUCCESS: {
        toastService.onSuccess(apiErrorListing?.data?.msg);
        break;
      }
      default: {
        break;
      }
    }
  };
  const internalServerErr = () => {
    toastService.onFailure("something went wrong, try again later");
  };

  return <></>;
};
