import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import IntlTelInput from "react-intl-tel-input";

const HotelGroupContactInformation = (props) => {
  const [emailExist, setEmailExist] = useState(false);
  let [HotelVisible, setHotelVisible] = useState({
    first_name: true,
    middle_name: true,
    last_name: true,
    phone_number: true,
    email: true,
    heading: true,
  });

  useEffect(() => {
    if (props.updateState != undefined) {
      setHotelVisible(props.updateState);
    }
    if (props.sendOldHotelValues?.email?.length > 0) {
      setEmailExist(true);
    }
  }, []);

  let formattingData = (event) => {
    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        props.updateform.setHotel({
          ...props.updateform.hotel,
          contact_information: {
            ...props.updateform.hotel["contact_information"],
            [event.target.name]: event.target.value,
          },
        });
      } else {
        props.updateform.setHotel({
          ...props.updateform.hotel,
          contact_information: {
            ...props.updateform.hotel["contact_information"],
            [event.target.name]: "",
          },
        });
      }
    } catch (err) {
      props.updateform.setHotel({
        ...props.updateform.hotel,
        contact_information: {
          ...props.updateform.hotel["contact_information"],
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  const dispatch = useDispatch();
  const checkEmail = (event) => {
    if (event.target.value) {
      dispatch(actions.checkEmail(event.target.value));
    }
  };
  const result = useSelector((state) => state.hotelgroup.result);
  useEffect(() => {
    if (result?.isEmailExist) {
      setEmailExist(true);

      props.updateform.setHotel({
        ...props.updateform.hotel,
        contact_information: {
          ...props.updateform.hotel["contact_information"],
          first_name: result.data.first_name,
          middle_name: result.data.middle_name,
          last_name: result.data.last_name,
          phone_number: result.data.phone_number,
          phone_number_country: result.data.phone_number_country
            ? result.data.phone_number_country
            : "",
        },
      });
    } else if (!result?.isEmailExist) {
      if (emailExist) {
        setEmailExist(false);
        props.updateform.setHotel({
          ...props.updateform.hotel,
          contact_information: {
            ...props.updateform.hotel["contact_information"],
            first_name: "",
            middle_name: "",
            last_name: "",
            phone_number: "",
          },
        });
      }
    }
  }, [result]);

  // intl tel input handler
  const handlePhoneChange = (_status, phoneNumber, country) => {
    props.updateform.setHotel({
      ...props.updateform.hotel,
      contact_information: {
        ...props.updateform.hotel["contact_information"],
        phone_number: phoneNumber,
        phone_number_country: country.iso2,
      },
    });

    // props.setcountryISO(country.iso2);
  };

  const handleFlagChange = (_data, t1, _t2) => {
    // props.setcountryISO(t1.iso2);
    props.updateform.setHotel({
      ...props.updateform.hotel,
      contact_information: {
        ...props.updateform.hotel["contact_information"],
        phone_number_country: t1.iso2,
      },
    });
  };

  const intlTelInputRef = useRef();
  const [isFlagSet, setIsFlagSet] = useState(false);

  useEffect(() => {
    if (
      intlTelInputRef.current &&
      props.sendOldHotelValues.phone_number_country &&
      props.sendOldHotelValues.phone_number_country.length > 0 &&
      !isFlagSet
    ) {
      setTimeout(() => {
        intlTelInputRef.current.setFlag(
          props.sendOldHotelValues.phone_number_country
        );
      }, 100);

      if (intlTelInputRef.current?.tel) {
        const intlInputParentDOM = intlTelInputRef.current?.tel?.parentElement;
        if (intlInputParentDOM) {
          const dialcodeDOM =
            intlInputParentDOM.firstElementChild.querySelector(
              ".selected-dial-code"
            );
          if (dialcodeDOM) {
            dialcodeDOM.classList.remove("selected-dial-code");
            dialcodeDOM.style.marginLeft = "5px";
            setIsFlagSet(true);
          }
        }
      }
    }
  }, [intlTelInputRef, props.sendOldHotelValues]);

  return (
    <div>
      {HotelVisible.heading === true && <h5>Contact information</h5>}
      <Row>
        <Col md={9}>
          <Form.Group>
            <Form.Label>
              Email address <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              isInvalid={
                props.sendOldHotelValues === undefined ||
                props.sendOldHotelValues.email === undefined
                  ? false
                  : props.sendOldHotelValues.email.length < 2 ||
                    props.sendOldHotelValues.email.length > 199
                  ? true
                  : false
              }
              value={
                props.sendOldHotelValues === undefined
                  ? ""
                  : props.sendOldHotelValues.email
              }
              minLength={2}
              onBlur={checkEmail}
              maxLength={200}
              onChange={formattingData}
            ></Form.Control>
            {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 200, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          {HotelVisible.first_name === true && (
            <Form.Group>
              <Form.Label>
                First name<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                isInvalid={
                  props.sendOldHotelValues === undefined ||
                  props.sendOldHotelValues.first_name === undefined
                    ? false
                    : props.sendOldHotelValues.first_name.length < 2 ||
                      props.sendOldHotelValues.first_name.length > 100
                    ? true
                    : false
                }
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.first_name
                }
                onChange={formattingData}
                minLength={2}
                maxLength={100}
                disabled={emailExist}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
            </Form.Group>
          )}
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>Middle name</Form.Label>
            <Form.Control
              type="text"
              name="middle_name"
              value={
                props.sendOldHotelValues === undefined
                  ? ""
                  : props.sendOldHotelValues.middle_name
              }
              onChange={formattingData}
              disabled={emailExist}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Group>
            <Form.Label>
              Last name<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              isInvalid={
                props.sendOldHotelValues === undefined ||
                props.sendOldHotelValues.last_name === undefined
                  ? false
                  : props.sendOldHotelValues.last_name.length < 2 ||
                    props.sendOldHotelValues.last_name.length > 99
                  ? true
                  : false
              }
              value={
                props.sendOldHotelValues === undefined
                  ? ""
                  : props.sendOldHotelValues.last_name
              }
              minLength={2}
              maxLength={100}
              onChange={formattingData}
              disabled={emailExist}
            ></Form.Control>
            {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 100, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <Form.Group>
            <Form.Label>
              PHONE NUMBER <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName={`form-control ${
                props.sendOldHotelValues === undefined ||
                props.sendOldHotelValues.phone_number === undefined
                  ? false
                  : props.sendOldHotelValues.phone_number?.length < 2 ||
                    props.sendOldHotelValues.phone_number?.length > 19
                  ? "is-invalid"
                  : false
              }`}
              disabled={emailExist}
              input
              type="tel"
              value={
                props.sendOldHotelValues.phone_number &&
                props.sendOldHotelValues.phone_number?.trim().length > 0
                  ? props.sendOldHotelValues.phone_number?.trim()
                  : ""
              }
              fieldName="phone_number"
              onSelectFlag={handleFlagChange}
              onPhoneNumberChange={handlePhoneChange}
              separateDialCode={true}
              ref={intlTelInputRef}
            />
            {/* <Form.Control
              type="text"
              name="phone_number"
              isInvalid={
                props.sendOldHotelValues === undefined ||
                props.sendOldHotelValues.phone_number === undefined
                  ? false
                  : props.sendOldHotelValues.phone_number?.length < 2 ||
                    props.sendOldHotelValues.phone_number?.length > 19
                  ? true
                  : false
              }
              value={
                props.sendOldHotelValues === undefined
                  ? ""
                  : props.sendOldHotelValues.phone_number
              }
              minLength={2}
              maxLength={20}
              onChange={formattingData}
              disabled={emailExist}
            ></Form.Control> */}
            {/*  <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly (Maximum limit is 20, Minimum limit is 2)"}
                </Form.Control.Feedback> */}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <Form.Group>
            <Form.Label>
              User role<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              style={{ cursor: "not-allowed" }}
              type="text"
              disabled
              name="role_name"
              value="Super admin"
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
export default HotelGroupContactInformation;
