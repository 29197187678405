import React, { useEffect, useState } from 'react'
import {Form,Col,Row} from 'react-bootstrap'

const HotelAdditonalNotes = (props)=>{

    let [HotelGroupVisible, setHotelGroupVisible] = useState({'heading':true,'additional_notes':true})

    useEffect(()=>{
        if (props.updateState!=undefined){

            setHotelGroupVisible(props.updateState)
        }
    },[])

    const onChangeHandleInputChange = (event) =>{
        props.onChange({...props.hotel,[event.target.name]:event.target.value})
    }
    return(
        <div>
            {(HotelGroupVisible.heading === true)&&<h5>Additional notes</h5>}
            {
                (HotelGroupVisible.additional_notes === true) && <Row>
                    <Col md={9}>
                        <Form.Group>
                            
                            <Form.Control as="textarea" rows={6} name="additional_notes" defaultValue={(props.sendOldHotelValues===undefined)?(''):(props.sendOldHotelValues.additional_notes)} onChange={onChangeHandleInputChange} />
                        </Form.Group>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default HotelAdditonalNotes