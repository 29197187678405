import { Form, Formik } from "formik";
import { Button, Col, Form as BootstrapForm, Modal, Row } from 'react-bootstrap';
import * as yup from "yup";
import { ReactComponent as CloseIcon } from '../Common/icons/close.svg';

const ChangePasswordModal = (props) => {
    const formInitialValue = {
        password: "",
        newPassword: "",
        confirm_password: ""
    };
    const formSchema = yup.object().shape({
        password: yup.string().required('Required'),
        newPassword: yup.string().required('Required'),
        confirm_password: yup.string().required(" ").when("newPassword", {
            is: val => (val && val.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref("newPassword")], "Password did not match")
        })
    });
    const resetPassword = (payload) => {
       props.confirmPassword(payload)
    }
    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="mt-3">
                <Row>
                    <Col md={2}></Col>
                    <Col md={8} className="alignCenter"> <h5> {props.header} </h5>  </Col>
                    <Col md={2} className="pl-3">
                        <span onClick={props.onHide} className="pointer">
                            <CloseIcon />
                        </span>
                    </Col>
                </Row>
            </div>
            <Formik initialValues={{
                ...formInitialValue,
            }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                    const payload = {
                        ...values
                    }
                    resetPassword(payload)

                }}
            >
                {({
                    handleChange,
                    errors,
                    values,
                    touched,
                    setFieldValue,
                    submitForm,
                }) => (
                    <Form>
                        <Modal.Body>
                            <BootstrapForm.Group>
                                <BootstrapForm.Label>Old Password <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                <BootstrapForm.Control
                                    type="password"
                                    name="password"
                                    onChange={handleChange} isInvalid={touched?.password && errors?.password} value={values?.password}
                                ></BootstrapForm.Control>
                            </BootstrapForm.Group>
                            <BootstrapForm.Group className="mt-2">
                                <BootstrapForm.Label>New Password <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                <BootstrapForm.Control
                                    type="password"
                                    name="newPassword"
                                    onChange={handleChange} isInvalid={touched?.newPassword && errors?.newPassword} value={values?.newPassword}
                                ></BootstrapForm.Control>
                            </BootstrapForm.Group>
                            <BootstrapForm.Group className="mt-2">
                                <BootstrapForm.Label>Confirm Password <span style={{ color: "red" }}>*</span></BootstrapForm.Label>
                                <BootstrapForm.Control
                                    type="password"
                                    name="confirm_password"
                                    onChange={handleChange} isInvalid={touched?.confirm_password && errors?.confirm_password} value={values?.confirm_password}
                                ></BootstrapForm.Control>
                                <span style={{ color: "red" }}>{touched?.confirm_password?errors?.confirm_password:''}</span>
                            </BootstrapForm.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button type="submit" className="change-password-button">Change Password</Button>
                            {/* <Button variant="secondary" onClick={props.onHide}>Cancel</Button> */}
                        </Modal.Footer>
                    </Form>)}
            </Formik>
        </Modal>
    )
}
export default ChangePasswordModal