import { call, put, takeLatest } from "redux-saga/effects";
import Authr from "../../services/Authr/index";
import actions from "../actions/actions";

const getRoles =  async () =>{
    let AuthrServe = new Authr();
    const response = await AuthrServe.getRoles()
    return response
}




function* dispatchGetRoles(action) {
    try {
        const response = yield call(getRoles)
        if (response.data.success) {
            yield put({
                type: actions.GET_ROLE_SUCCESS,
                data: response.data.data.records,
                msg: response.data.msg
            })
        } else {
            yield put({
                type: actions.GET_ROLE_FAILED,
                msg: response.data.msg
            })
        }
    } catch (error) {
        yield put({
            type: actions.GET_ROLE_FAILED,
            msg: error.response.data.msg
        })
    }
}


export function* listengetRoles() {
    yield takeLatest(actions.GET_ROLE, dispatchGetRoles)
  }