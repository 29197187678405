import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PRIVATE_ROUTES } from "../../helper/constants";
import HotelGroupValidator from "../../services/validator/hotelGroupvalidator";
import nullFormatter from "../../services/validator/nullFormat";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import Breadcrumbs from "../Common/Breadcrumbs";
import { ToastService } from "./../../services/toast.service";
import HotelGroupBasicInformation from "./HotelGroupBasicInformation";
import HotelGroupContactInformation from "./HotelGroupContactInformation";

const CreateHotelGroup = (props) => {
  const breadcrumbs = [
    { path: "/hotel-group/list", title: "Hotel groups" },
    { path: "/hotel-group/create", title: "Add new hotel group" },
  ];
  const [formValues, setformValues] = useState();
  const [saveHit, setSaveHit] = useState(false);
  const [countryISO, setCountryISO] = useState();

  const result = useSelector((state) => state.hotelgroup.result);
  const dispatch = useDispatch();
  const toast = new ToastService();
  useEffect(() => {
    dispatch(actions.getCountries());
  }, []);
  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  useEffect(() => {
    if (result.success && result.created && saveHit) {
      setSaveHit(false);
      props.history.push("/hotel-group/list");
    }
  }, [result]);

  const createHotelGroup = (e) => {
    setSaveHit(true);
    e.preventDefault();

    let validating = HotelGroupValidator(formValues);
    let notMandatoryField = ["middle_name", "address_state_id"];
    let flag = true;

    setformValues(validating);
    Object.keys(validating).forEach((validKeys, i) => {
      if (
        validating[validKeys] === "" &&
        !notMandatoryField.includes(validKeys)
      ) {
        flag = false;
      }
    });

    if (flag) {
      let nullFormatted = nullFormatter(validating);
      dispatch(actions.CreateHotelGroup(nullFormatted));
      dispatch(actions.resetEmail());
    } else {
      toast.onWarning("Please fill out the fields correctly");
    }
  };

  let updateformValues = (event) => {
    if (
      event.target.name === "address_country_id" &&
      event.target.value === undefined
    ) {
      delete formValues.address_state_id;
      delete formValues.address_city;
    }
    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        setformValues((value) => ({
          ...value,
          [event.target.name]: event.target.value,
        }));
      } else {
        setformValues((value) => ({ ...value, [event.target.name]: "" }));
      }
    } catch (err) {
      setformValues((value) => ({
        ...value,
        [event.target.name]: event.target.value,
      }));
    }
  };
  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3> Add new hotel group </h3>
          </Col>
        </Row>
      </div>
      <div className="content">
        <Form onSubmit={createHotelGroup}>
          <Row>
            <Col>
              <Row>
                <Col>
                  <HotelGroupBasicInformation
                    updateform={updateformValues}
                    oldHotelValues={formValues}
                    disabled={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <HotelGroupContactInformation
                    updateform={updateformValues}
                    oldHotelValues={formValues}
                    setCountryISO={setCountryISO}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3} sm={6} xs={6}>
                  {hiddenIconAsPerRole(PRIVATE_ROUTES.create_hotel_group) ? (
                    <Button variant="primary" type="submit">
                      + Add new hotel group
                    </Button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Button
                    variant="secondary"
                    onClick={(e) => props.history.push("/hotel-group/list")}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CreateHotelGroup;
