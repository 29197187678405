import { Utils } from "../../utils/utils";
import axios from "../authInstance";

class UserManagement {
 

  getUserProfile = async (payload) => {
    try {
      const response = await axios.post(`/profile/`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  updateUserProfile = async (payload) => {
    const bodyFormData = Utils.appendDataServiceAndOffers(payload);
    try {
      const response = await axios.put(`/update-profile/`, bodyFormData);
      return response;
    } catch (error) {
      throw error;
    }
  };

}

export default UserManagement;
