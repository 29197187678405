import { BrowserRouter, Switch, Route } from "react-router-dom";
import BaseLayout from "../src/components/layout/BaseLayout";
import "bootstrap/dist/css/bootstrap.min.css";

//for tesing
import "./styles/app.scss";

import { Routes } from "./routes/routes";
import { Toaster } from "react-hot-toast";
import { TOAST_CONFIG } from "./helper/constants";
import { APIErrorHandling } from "./helper/APIErrorHandling";

function App() {
  return (
    <BrowserRouter>
      <APIErrorHandling />
      {/* toast management */}
      <Toaster position={TOAST_CONFIG.default.position} reverseOrder={true} />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
