import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import SingleDropDown from "../Common/singleDropDown";

const CONSTANT_FIELDS = {
  CONTRACT_TYPE: 0,
  HOTEL_TYPE: 1,
  PMS: 2,
  SUBSCRIPTION_TYPE: 3,
};

const HotelFinancialAgreements = (props) => {
  const dispatch = useDispatch();

  let [HotelVisible, setHotelVisible] = useState({
    heading: true,
    contract_start_end_date: true,
    contract_endless: true,
    type_of_subscription: true,
    amount: true,
    discount: true,
    commision_percentage: true,
  });

  let [checkEndless, setCheckEndless] = useState(false);

  useEffect(() => {
    if (props.sendOldHotelValues !== undefined) {
      setCheckEndless(props.sendOldHotelValues.is_contract_no_end_date);
    }
  }, [checkEndless]);

  useEffect(() => {
    if (props.updateState != undefined) {
      setHotelVisible(props.updateState);
    }
    dispatch(
      actions.getConstantsData([
        CONSTANT_FIELDS.CONTRACT_TYPE,
        CONSTANT_FIELDS.HOTEL_TYPE,
        CONSTANT_FIELDS.PMS,
        CONSTANT_FIELDS.SUBSCRIPTION_TYPE,
      ])
    );
  }, []);

  let constantData = useSelector((state) => state.constants.constants);
  let giveDropdownData = (field) => {
    return constantData[field];
  };

  const handleOnPriceChange = (e) => {
    const value = parseFloat(
      e.target.value?.replace("€", "").replaceAll(",", "")
    );

    if (value && value > 0) {
      props.updateform.setHotel({
        ...props.updateform.hotel,
        financial_agreement: {
          ...props.updateform.hotel["financial_agreement"],
          [e.target.name]: value,
        },
      });
    } else {
      props.updateform.setHotel({
        ...props.updateform.hotel,
        financial_agreement: {
          ...props.updateform.hotel["financial_agreement"],
          [e.target.name]: "",
        },
      });
    }
  };

  let parseVariableFloat = [
    "amount",
    "discount_percentage",
    "commision_percentage",
  ];
  let formattingData = (e) => {
    if (e.target.name !== "is_contract_no_end_date") {
      parseVariableFloat.includes(e.target.name)
        ? props.updateform.setHotel({
            ...props.updateform.hotel,
            financial_agreement: {
              ...props.updateform.hotel["financial_agreement"],
              [e.target.name]: isNaN(parseFloat(e.target.value))
                ? ""
                : parseFloat(e.target.value) < 0
                ? 0
                : parseFloat(e.target.value),
            },
          })
        : props.updateform.setHotel({
            ...props.updateform.hotel,
            financial_agreement: {
              ...props.updateform.hotel["financial_agreement"],
              [e.target.name]: isNaN(parseFloat(e.target.value))
                ? ""
                : e.target.value,
            },
          });
    } else {
      props.updateform.setHotel({
        ...props.updateform.hotel,
        financial_agreement: {
          ...props.updateform.hotel["financial_agreement"],
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  let ckeckOnChange = (e) => {
    e.target.checked
      ? formattingData({
          target: { name: "is_contract_no_end_date", value: true },
        })
      : formattingData({
          target: { name: "is_contract_no_end_date", value: false },
        });
    setCheckEndless(e.target.checked ? true : false);
  };

  return (
    <div>
      {HotelVisible.heading === true && <h5>Financial Agreement</h5>}
      {HotelVisible.contract_start_end_date === true && (
        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Label>
                CONTRACT START DATE<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="contract_start_date"
                isInvalid={
                  props.sendOldHotelValues === undefined ||
                  props.sendOldHotelValues.contract_start_date === undefined
                    ? false
                    : props.sendOldHotelValues.contract_start_date.length < 1
                    ? true
                    : false
                }
                value={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.contract_start_date
                }
                onChange={formattingData}
              ></Form.Control>
              {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly"}
                </Form.Control.Feedback> */}
            </Form.Group>
          </Col>
          {!checkEndless && (
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  CONTRACT END DATE<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="contract_end_date"
                  isInvalid={
                    props.sendOldHotelValues === undefined ||
                    props.sendOldHotelValues.contract_end_date === undefined
                      ? false
                      : props.sendOldHotelValues.contract_end_date.length < 1
                      ? true
                      : false
                  }
                  value={
                    props.sendOldHotelValues === undefined
                      ? ""
                      : props.sendOldHotelValues.contract_end_date
                  }
                  min={
                    props.sendOldHotelValues === undefined
                      ? ""
                      : props.sendOldHotelValues.contract_start_date
                  }
                  onChange={formattingData}
                ></Form.Control>
                {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly"}
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
          )}
        </Row>
      )}
      {HotelVisible.contract_endless === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Check
                name="is_contract_no_end_date"
                defaultChecked={
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.is_contract_no_end_date
                }
                label="Contract has no end date"
                onChange={ckeckOnChange}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.type_of_subscription === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>
                SUBSCRIPTION TYPE<span style={{ color: "red" }}>*</span>
              </Form.Label>

              <SingleDropDown
                val={giveDropdownData(CONSTANT_FIELDS.SUBSCRIPTION_TYPE)}
                inpName={"type_of_subscription"}
                sendOldHotelValues={props.sendOldHotelValues}
                updateDropdown={formattingData}
                req={true}
              ></SingleDropDown>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.amount === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>
                MONTHLY FEE<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <InputGroup>
                {/* <InputGroup.Text>€</InputGroup.Text> */}
                {Utils.NumberFormat(
                  "€",
                  "",
                  "financial-aggrement-fee",
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.amount,
                  "",
                  false,
                  props.sendOldHotelValues === undefined ||
                    props.sendOldHotelValues.amount === undefined
                    ? false
                    : props.sendOldHotelValues.amount < 0 ||
                      props.sendOldHotelValues.amount === ""
                    ? true
                    : false,
                  "amount",
                  handleOnPriceChange,
                  Form.Control
                )}
                {/* <Form.Control.Feedback type="invalid">
                  {"Please fill out the field correctly"}
                </Form.Control.Feedback> */}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.discount === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Discount percentage</Form.Label>
              <InputGroup>
                {Utils.NumberFormat(
                  "",
                  "%",
                  "financial-aggrement-discount",
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.discount_percentage,
                  "",
                  false,
                  props.sendOldHotelValues.discount_percentage &&
                    ((props.sendOldHotelValues.discount_percentage < 0
                      ? true
                      : false) ||
                      (props.sendOldHotelValues.discount_percentage > 100
                        ? true
                        : false)),
                  "discount_percentage",
                  handleOnPriceChange,
                  Form.Control,
                  100
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )}
      {HotelVisible.commision_percentage === true && (
        <Row>
          <Col md={9}>
            <Form.Group>
              <Form.Label>Commision percentage</Form.Label>
              <InputGroup>
                {Utils.NumberFormat(
                  "",
                  "%",
                  "financial-aggrement-commision_percentage",
                  props.sendOldHotelValues === undefined
                    ? ""
                    : props.sendOldHotelValues.commision_percentage,
                  "",
                  false,
                  props.sendOldHotelValues.commision_percentage &&
                    ((props.sendOldHotelValues.commision_percentage < 0
                      ? true
                      : false) ||
                      (props.sendOldHotelValues.commision_percentage > 100
                        ? true
                        : false)),
                  "commision_percentage",
                  handleOnPriceChange,
                  Form.Control,
                  100
                )}
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default HotelFinancialAgreements;
