import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Switch from "react-switch";
import { PRIVATE_ROUTES } from "../../helper/constants";
import { ToastService } from "../../services/toast.service";
import HotelValidator from "../../services/validator/hotelValidator";
import nullFormatter from "../../services/validator/nullFormat";
import actions from "../../store/actions/actions";
import { Utils } from "../../utils/utils";
import { ReactComponent as ArrowLeft } from "../Common/arrowLeft.svg";
import pencil from "../Common/pencil.svg";
import Breadcrumbs from "./../Common/Breadcrumbs";
import HotelGroupContactInformation from "./HotelContactInformation";
import HotelInvoiceInformation from "./HotelInvoiceInformation";
import HotelAdditonalNotes from "./HotelsAdditonalNotes";
import HotelsBasicInformation from "./HotelsBasicInforamtion";
import HotelFinancialAgreements from "./HotelsFinancialAgreement";

const UpdateHotels = (props) => {
  const [isPmsIntegrationActive, setIsPmsIntegrationActive] = useState(false);
  const [pmsId, setPmsId] = useState(false);
  const [countryISO, setcountryISO] = useState();
  const [isInvoiceAddressDuplicate, setIsInvoiceAddressDuplicate] =
    useState(false);
  const [tempInvoiceAddress, setTempInvoiceAddress] = useState(false);
  const dispatch = useDispatch();
  const hotelDetails = useSelector((state) => state.hotels.hotelDetails);

  const onHandlePMSStatusToggle = (value) => {
    setIsPmsIntegrationActive(value);
    pmsStatusToggleDebounceHandler(value, pmsId);
  };

  const updatePMSIntegrationStatus = (value, pmsId) => {
    let payload = {
      hotel_id: parseInt(props.match.params.id),
      pms_id: pmsId,
      is_active: value,
    };
    dispatch(actions.updatePmsIntegration(payload));
  };

  const pmsStatusToggleDebounceHandler = useCallback(
    debounce(updatePMSIntegrationStatus, 500),
    []
  );

  const syncCategory = () => {
    dispatch(actions.syncCategories(pmsId, props.match.params.id));
  };

  const syncRooms = () => {
    dispatch(actions.syncRooms(pmsId, props.match.params.id));
  };

  const syncProducts = () => {
    dispatch(actions.syncProducts(pmsId, props.match.params.id));
  };

  const pmsList = useSelector((state) => state.hotels.pms);

  let connect = (pms_id, form_data, pms_name) => {
    props.history.push(
      `/hotel/update/${props.match.params.id}/pms-integration`,
      { pms_id, form_data, pms_name }
    );
  };

  let result = useSelector((state) => state.hotels.result);
  const breadcrumbs = [
    { path: "/hotel/list", title: "Hotels" },
    {
      path: `/hotel/update/${props.match.params.id}`,
      title: hotelDetails?.id && hotelDetails?.name,
    },
  ];
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };
  const [formHotelValues, setHotelformValues] = useState({
    contact_information: {},
    financial_agreement: {},
    invoice_information: {},
  });
  const history = useHistory();
  const [saveCancel, setSaveCancel] = useState(false);
  const [saveHit, setSaveHit] = useState(false);
  let toast = new ToastService();

  useEffect(() => {
    if (result.success && result.update) {
      dispatch(actions.resetUpdateHotel());
    }
    if (result.success && result.msg === "Successfully reset" && saveHit) {
      setSaveHit(false);
      history.goBack();
    }
  }, [result]);

  useEffect(() => {
    dispatch(actions.getHotelDetails(props.match.params.id));
    dispatch(actions.getCountries());
    dispatch(actions.getPmsList(props.match.params.id));
    return () => {
      dispatch(actions.resetEmail());
    };
  }, []);

  useEffect(() => {
    if (hotelDetails.id !== undefined) {
      let mapOldvalue = {
        name: hotelDetails?.name === null ? "" : hotelDetails?.name,
        cluster: hotelDetails?.cluster === null ? "" : hotelDetails?.cluster,
        area: hotelDetails?.area === null ? "" : hotelDetails?.area,
        region: hotelDetails?.region === null ? "" : hotelDetails?.region,
        division: hotelDetails?.division === null ? "" : hotelDetails?.division,
        brand: hotelDetails?.brand === null ? "" : hotelDetails?.brand,
        total_rooms_count:
          hotelDetails?.total_rooms_count === 0
            ? 0
            : hotelDetails?.total_rooms_count,
        address_city:
          hotelDetails?.address_city === null ? "" : hotelDetails?.address_city,
        hotel_group_id:
          hotelDetails?.hotel_group === null
            ? ""
            : hotelDetails?.hotel_group["id"],
        contract_type_id:
          hotelDetails?.contract_type_id === null
            ? ""
            : hotelDetails?.contract_type_id,
        hotel_type_id: hotelDetails?.hotel_type_id,
        pms_id: hotelDetails?.pms_id,
        lang_id: 1 /* Do we really need ? */,
        address: hotelDetails?.address,
        state_id:
          hotelDetails?.state_id /* Not able to find probably state_id */,
        country_id:
          hotelDetails?.country_id /* Not able to find  probably country_id */,
        zip_code: hotelDetails?.zip_code,
        additional_notes: hotelDetails?.additional_notes,
        finiancial_aggrement_id: hotelDetails?.finiancial_aggrement_id,
        invoice_info_id: hotelDetails?.invoice_info_id,
        financial_agreement: {
          contract_start_date:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["contract_start_date"] === null
              ? ""
              : hotelDetails?.finiancial_aggrement["contract_start_date"],
          contract_end_date:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["contract_end_date"] === null
              ? ""
              : hotelDetails?.finiancial_aggrement["contract_end_date"],
          is_contract_no_end_date:
            hotelDetails?.finiancial_aggrement["is_contract_no_end_date"] ===
            null
              ? false
              : hotelDetails?.finiancial_aggrement?.is_contract_no_end_date,
          type_of_subscription:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["type_of_subscription"] === null
              ? ""
              : hotelDetails?.finiancial_aggrement?.type_of_subscription,
          amount:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["amount"] === null
              ? 0
              : parseFloat(hotelDetails?.finiancial_aggrement?.amount),
          discount_percentage:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["discount_percentage"] === null
              ? 0
              : parseFloat(
                  hotelDetails?.finiancial_aggrement?.discount_percentage
                ),
          commision_percentage:
            hotelDetails?.finiancial_aggrement === undefined ||
            hotelDetails?.finiancial_aggrement === null ||
            hotelDetails?.finiancial_aggrement["commision_percentage"] === null
              ? 0
              : parseFloat(
                  hotelDetails?.finiancial_aggrement?.commision_percentage
                ),
        },
        invoice_info: {
          company_name: hotelDetails?.invoice_info?.company_name,
          billing_address: hotelDetails?.invoice_info?.billing_address,
          zip_code: hotelDetails?.invoice_info?.zip_code,
          state_id: hotelDetails?.invoice_info?.state_id,
          country_id: hotelDetails?.invoice_info?.country_id,
          vat_number: hotelDetails?.invoice_info?.vat_number,
          address_city: hotelDetails?.invoice_info?.address_city,
        },
        contact_information: {
          first_name: hotelDetails?.contact_info_user?.first_name,
          last_name: hotelDetails?.contact_info_user?.last_name,
          middle_name: hotelDetails?.contact_info_user?.middle_name,
          email: hotelDetails?.contact_info_user?.email,
          phone_number: hotelDetails?.contact_info_user?.phone_number,
          phone_number_country:
            hotelDetails?.contact_info_user?.phone_number_country,
        },
      };
      setHotelformValues(mapOldvalue);
      setIsPmsIntegrationActive(
        hotelDetails?.pms_hotel_integrations?.[0]?.is_active
      );
      setPmsId(hotelDetails?.pms_hotel_integrations?.[0]?.pms_master?.id);
    }
  }, [hotelDetails, saveCancel]);

  let [hotelShowOnly, setHotelShowOnly] = useState({
    heading: false,
    name_update: false,
    cluster: false,
    area: false,
    region: false,
    division: false,
    brand: false,
    hotel_group: false,
    contract_type_id: false,
    hotel_type_id: false,
    pms_id: false,
    address_zipcode: false,
    state_country: false,
    contract_start_end_date: false,
    contract_endless: false,
    type_of_subscription: false,
    amount: false,
    discount: false,
    commision_percentage: false,
    company_name: false,
    zipcode_billing_address: false,
    state_country_invoice: false,
    vat_number: false,
    additional_notes: false,
    total_rooms_count: false,
    first_name: false,
    middle_name: false,
    last_name: false,
    phone_number: false,
    email: false,
  });

  let setUpdateHotelInfo = (event) => {
    if (
      event.target.name === "country_id" &&
      event.target.value === undefined
    ) {
      delete formHotelValues.state_id;
      delete formHotelValues.address_city;
    }

    try {
      if (
        event.target.value >= 1 ||
        !event.target.value ||
        event.target.value.trim().length >= 1
      ) {
        setHotelformValues({
          ...formHotelValues,
          [event.target.name]: event.target.value,
        });
      } else {
        setHotelformValues({ ...formHotelValues, [event.target.name]: "" });
      }
    } catch (err) {
      setHotelformValues({
        ...formHotelValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  let changeName = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });
    setHotelShowOnly({
      ...hotelShowOnly,
      name_update: !hotelShowOnly.name_update,
    });
  };

  let changeHotelBasicInfo = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });
    setHotelShowOnly({
      ...hotelShowOnly,

      cluster: !hotelShowOnly.cluster,
      area: !hotelShowOnly.area,
      region: !hotelShowOnly.region,
      division: !hotelShowOnly.division,
      brand: !hotelShowOnly.brand,
      hotel_group: !hotelShowOnly.contact_type,
      contract_type_id: !hotelShowOnly.contract_type_id,
      hotel_type_id: !hotelShowOnly.hotel_type_id,
      pms_id: !hotelShowOnly.pms_id,
      state_country: !hotelShowOnly.state_country,
      address_zipcode: !hotelShowOnly.address_zipcode,
      total_rooms_count: !hotelShowOnly.total_rooms_count,
    });
  };

  let changeInvoiceInformation = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });
    setHotelShowOnly({
      ...hotelShowOnly,
      company_name: !hotelShowOnly.company_name,
      zipcode_billing_address: !hotelShowOnly.zipcode_billing_address,
      state_country_invoice: !hotelShowOnly.state_country_invoice,
      vat_number: !hotelShowOnly.vat_number,
    });
  };

  let changeAdditionalNotes = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });

    setHotelShowOnly({
      ...hotelShowOnly,
      additional_notes: !hotelShowOnly.additional_notes,
    });
  };

  let changeFinancialAgreement = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });

    setHotelShowOnly({
      ...hotelShowOnly,
      heading: false,
      contract_start_end_date: !hotelShowOnly.contract_start_end_date,
      contract_endless: !hotelShowOnly.contract_endless,
      type_of_subscription: !hotelShowOnly.type_of_subscription,
      amount: !hotelShowOnly.amount,
      discount: !hotelShowOnly.discount,
      commision_percentage: !hotelShowOnly.commision_percentage,
    });
  };

  let changeContact = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });
    setHotelShowOnly({
      ...hotelShowOnly,
      heading: false,
      first_name: !hotelShowOnly.first_name,
      middle_name: !hotelShowOnly.middle_name,
      last_name: !hotelShowOnly.last_name,
      phone_number: !hotelShowOnly.phone_number,
      email: !hotelShowOnly.email,
    });
  };

  let cancelButton = (e) => {
    Object.keys(hotelShowOnly).map((v, i) => {
      if (!hotelShowOnly[v]) {
        setSaveCancel(true);
      }
    });
    if (saveCancel) {
      setSaveCancel(false);
    }
    setHotelShowOnly({
      ...hotelShowOnly,
      heading: false,
      name_update: false,
      cluster: false,
      area: false,
      region: false,
      division: false,
      brand: false,
      hotel_group: false,
      contract_type_id: false,
      hotel_type_id: false,
      pms_id: false,
      address_zipcode: false,
      state_country: false,
      contract_start_end_date: false,
      contract_endless: false,
      type_of_subscription: false,
      amount: false,
      discount: false,
      commision_percentage: false,
      company_name: false,
      zipcode_billing_address: false,
      state_country_invoice: false,
      vat_number: false,
      additional_notes: false,

      first_name: false,
      middle_name: false,
      last_name: false,
      phone_number: false,
      email: false,
    });
  };

  let formSubmitted = (e) => {
    setSaveHit(true);
    e.preventDefault();

    let validating = HotelValidator(formHotelValues, "update");
    let notMandatoryField = [
      "cluster",
      "area",
      "region",
      "division",
      "brand",
      "hotel_group_id",
      "contract_type_id",
      "middle_name",
      "discount_percentage",
      "commision_percentage",
      "additional_notes",
      "finiancial_aggrement_id",
      "invoice_info_id",
      "state_id",
      "total_rooms_count",
      "is_contract_no_end_date",
    ];
    if (formHotelValues.financial_agreement.is_contract_no_end_date) {
      notMandatoryField.push("contract_end_date");
    }
    let flag = true;
    setHotelformValues(validating);
    Object.keys(validating).forEach((validKeys, i) => {
      if (
        (validating[validKeys] === "" ||
          validating[validKeys] === null ||
          validating[validKeys] === undefined) &&
        !notMandatoryField.includes(validKeys) &&
        typeof validating[validKeys] !== "object"
      ) {
        flag = false;
      } else if (
        validating[validKeys] !== null &&
        !notMandatoryField.includes(validKeys)
      ) {
        if (typeof validating[validKeys] === "object") {
          Object.keys(validating[validKeys]).forEach((vk2, i2) => {
            if (
              (validating[validKeys][vk2] === "" ||
                validating[validKeys][vk2] === null ||
                validating[validKeys][vk2] === undefined) &&
              !notMandatoryField.includes(vk2) &&
              typeof validating[validKeys][vk2] !== "object"
            ) {
              flag = false;
            }
          });
        }
      }
    });

    if (flag) {
      let nullFormatted = nullFormatter(formHotelValues);
      dispatch(
        actions.updateHotelDetails(props.match.params.id, nullFormatted)
      );
      e.target.reset();
      cancelButton();
      dispatch(actions.resetEmail());
    } else {
      toast.onWarning("Please fill out the fields correctly");
    }
  };

  // get webhook status block
  // show only if webhook status is available
  const getWebhookStatusBlock = () => {
    if (
      !hotelDetails?.pms_hotel_integrations?.[0]?.pms_master
        ?.is_webhook_available
    )
      return <></>;

    return hotelDetails?.pms_hotel_integrations?.[0]?.is_webhook_connected ? (
      <p>Webhook connected</p>
    ) : (
      <p> Webhook not connected</p>
    );
  };

  useEffect(() => {
    if (isInvoiceAddressDuplicate) {
      setTempInvoiceAddress({
        ...formHotelValues.invoice_info,
      });

      setHotelformValues({
        ...formHotelValues,
        invoice_info: {
          ...formHotelValues.invoice_info,
          billing_address: formHotelValues.address,
          zip_code: formHotelValues.zip_code,
          address_city: formHotelValues.address_city,
          country_id: formHotelValues.country_id,
          state_id: formHotelValues.state_id,
        },
      });
    } else {
      setHotelformValues({
        ...formHotelValues,
        invoice_info: {
          ...formHotelValues.invoice_info,
          billing_address: tempInvoiceAddress?.billing_address
            ? tempInvoiceAddress?.billing_address
            : "",
          zip_code: tempInvoiceAddress?.zip_code
            ? tempInvoiceAddress?.zip_code
            : "",
          address_city: tempInvoiceAddress?.address_city
            ? tempInvoiceAddress?.address_city
            : "",
          country_id: tempInvoiceAddress?.country_id
            ? tempInvoiceAddress?.country_id
            : "",
          state_id: tempInvoiceAddress?.state_id
            ? tempInvoiceAddress?.state_id
            : "",
        },
      });
    }
  }, [isInvoiceAddressDuplicate]);

  const intlTelInputRef = useRef();

  useEffect(() => {
    if (
      intlTelInputRef.current &&
      hotelDetails?.contact_info_user?.phone_number_country &&
      hotelDetails?.contact_info_user?.phone_number_country?.length > 0
    ) {
      intlTelInputRef.current.setFlag(
        hotelDetails?.contact_info_user.phone_number_country
      );
      if (intlTelInputRef.current?.tel) {
        const intlInputParentDOM = intlTelInputRef.current?.tel?.parentElement;
        if (intlInputParentDOM) {
          const dialcodeDOM =
            intlInputParentDOM.firstElementChild.querySelector(
              ".selected-dial-code"
            );
          if (dialcodeDOM) {
            dialcodeDOM.classList.remove("selected-dial-code");
            dialcodeDOM.style.marginLeft = "5px";
          }
        }
      }
    }
  }, [intlTelInputRef, hotelDetails]);

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        <Row>
          <Col>
            <Breadcrumbs BreadcrumbData={breadcrumbs} />
          </Col>
        </Row>
        <Row className="marginBottom">
          <Col md={6} sm={6} xs={6} className="extra-content">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="white"
                      onClick={() => {
                        props.history.goBack();
                      }}
                    >
                      <ArrowLeft />
                    </Button>
                  </td>
                  <td className="p-3"> Back </td>
                </tr>
              </tbody>
            </table>
          </Col>
          {/* <Col md={6} sm={6} xs={6} className="alignSelf alignRight">
            <Button variant="white">
              <Vectors />
            </Button>
          </Col> */}
        </Row>
      </div>
      <div className="without-content-color">
        <Form onSubmit={formSubmitted}>
          <Row>
            <span className="alignRight">
              {saveCancel && (
                <>
                  {" "}
                  {hiddenIconAsPerRole(PRIVATE_ROUTES.update_hotel) ? (
                    <Button
                      variant="outline-success"
                      size="sm"
                      className="m-1 save-btn-size"
                      type="submit"
                    >
                      Save changes
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {saveCancel && (
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={cancelButton}
                >
                  Cancel
                </Button>
              )}
            </span>
          </Row>
          <Row>
            <Col>
              {hotelShowOnly.name_update === false && (
                <span>
                  <h5>
                    {hotelDetails && (
                      <span className="text-in-content">
                        {hotelDetails?.name}
                      </span>
                    )}
                    <sup>
                      <img
                        src={pencil}
                        alt="pencil-icon"
                        onClick={changeName}
                      />
                    </sup>
                  </h5>
                </span>
              )}

              {hotelShowOnly.name_update === true && (
                <Form.Group>
                  <Form.Label>
                    Hotel name <span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    isInvalid={
                      formHotelValues === undefined ||
                      formHotelValues.name === undefined
                        ? false
                        : formHotelValues.name.length < 2 ||
                          formHotelValues.name.length > 100
                        ? true
                        : false
                    }
                    value={
                      formHotelValues === undefined ? "" : formHotelValues.name
                    }
                    minLength={2}
                    maxLength={100}
                    onChange={setUpdateHotelInfo}
                  ></Form.Control>
                </Form.Group>
              )}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    Basic Information{" "}
                    <sup>
                      <img src={pencil} onClick={changeHotelBasicInfo} />
                    </sup>
                  </Card.Title>
                  {hotelShowOnly.cluster === false && (
                    <Row>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          Cluster
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.cluster}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Region
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.region}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Brand
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.brand}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Hotel Type
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.hotel_type?.value}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Total Rooms
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.total_rooms_count}</Card.Text>
                      </Col>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          Area
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.area}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Division
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.division}</Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Contract Type
                        </Card.Subtitle>
                        <Card.Text>
                          {hotelDetails?.contract_type?.value}
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          PMS
                        </Card.Subtitle>
                        <Card.Text>{hotelDetails?.pm?.value}</Card.Text>
                      </Col>
                    </Row>
                  )}
                  {hotelShowOnly.cluster === true && (
                    <Row>
                      <Col>
                        <HotelsBasicInformation
                          updateform={setUpdateHotelInfo}
                          updateState={hotelShowOnly}
                          sendOldHotelValues={formHotelValues}
                          disabled={true}
                        ></HotelsBasicInformation>
                        <br></br>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    Contact{" Information"}
                    <sup>
                      <img src={pencil} onClick={changeContact} />
                    </sup>
                  </Card.Title>
                  {hotelShowOnly.first_name === false && (
                    <Row className="hotel-update-contact">
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">{`${
                          hotelDetails?.contact_info_user?.first_name || ""
                        } ${
                          hotelDetails?.contact_info_user?.middle_name || ""
                        } ${
                          hotelDetails?.contact_info_user?.last_name || ""
                        }`}</Card.Subtitle>
                        <Card.Text>Super Admin</Card.Text>
                      </Col>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          {hotelDetails?.contact_info_user?.email || ""}
                        </Card.Subtitle>
                        <Card.Text>
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            disabled={true}
                            input
                            type="tel"
                            defaultValue={
                              hotelDetails?.contact_info_user?.phone_number ||
                              ""
                            }
                            value={
                              hotelDetails?.contact_info_user?.phone_number ||
                              ""
                            }
                            fieldName="phone_number"
                            separateDialCode={true}
                            ref={intlTelInputRef}
                          />
                        </Card.Text>
                      </Col>
                    </Row>
                  )}
                  {hotelShowOnly.first_name === true && (
                    <Row>
                      <Col>
                        <HotelGroupContactInformation
                          updateform={{
                            setHotel: setHotelformValues,
                            hotel: formHotelValues,
                          }}
                          updateState={hotelShowOnly}
                          sendOldHotelValues={
                            formHotelValues.contact_information
                          }
                          setcountryISO={setcountryISO}
                          disabled={true}
                        ></HotelGroupContactInformation>
                        <br></br>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>Hotel Group </Card.Title>
                  <Row>
                    <Col>
                      {!hotelDetails?.hotel_group?.is_system_generated && (
                        <Card.Subtitle className="mb-2 text-muted">
                          {hotelDetails?.hotel_group?.name}
                        </Card.Subtitle>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              {
                <Card className="mb-2">
                  <Card.Body>
                    {hotelDetails?.pms_hotel_integrations?.length &&
                    hotelDetails?.pms_hotel_id ? (
                      <>
                        <Card.Title>
                          <Row>
                            <Col>PMS Integration</Col>
                            <Col className="alignCenter">
                              <span className="switch-lable sub-header-text">
                                Off
                              </span>
                              <span>
                                <Switch
                                  onChange={(value) => {
                                    onHandlePMSStatusToggle(value);
                                  }}
                                  name="is_active"
                                  checked={isPmsIntegrationActive}
                                />
                              </span>
                              <span
                                className="switch-lable sub-header-text"
                                style={{ marginLeft: "10px" }}
                              >
                                On
                              </span>
                            </Col>
                          </Row>
                        </Card.Title>
                        <h5>
                          {
                            hotelDetails?.pms_hotel_integrations?.[0]
                              ?.pms_master?.name
                          }
                        </h5>
                        {getWebhookStatusBlock()}
                        <div className="breadcrumbs mb-2">
                          <Row>
                            <Col md={4}>
                              <Button
                                className="btn btn-sm mt-1"
                                onClick={syncCategory}
                                disabled={!isPmsIntegrationActive}
                              >
                                Sync Categories
                              </Button>
                            </Col>

                            <Col md={4} sm={1} xs={6}>
                              <Button
                                className="btn btn-sm mt-1"
                                onClick={syncRooms}
                                disabled={!isPmsIntegrationActive}
                              >
                                Sync Rooms
                              </Button>
                            </Col>

                            <Col md={4} sm={1} xs={6}>
                              <Button
                                className="btn btn-sm mt-1"
                                onClick={syncProducts}
                                disabled={!isPmsIntegrationActive}
                              >
                                Sync Products
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        <Card.Title>PMS Integration</Card.Title>
                        {
                          <table>
                            <tbody>
                              {pmsList.map((pms) => (
                                <tr>
                                  {pms.name}
                                  <td>
                                    <Button
                                      className="btn btn-sm mt-1"
                                      onClick={() =>
                                        connect(pms.id, pms.form_data, pms.name)
                                      }
                                      style={{ marginLeft: "350px" }}
                                    >
                                      Connect
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        }
                      </>
                    )}
                  </Card.Body>
                </Card>
              }
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    Financial Agreement{" "}
                    <sup>
                      <img src={pencil} onClick={changeFinancialAgreement} />
                    </sup>
                  </Card.Title>
                  {hotelShowOnly.contract_start_end_date === false && (
                    <Row>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          Contract Date
                        </Card.Subtitle>
                        <Card.Text>
                          From{" "}
                          {Utils.getDDMMYY(
                            hotelDetails?.finiancial_aggrement
                              ?.contract_start_date || ""
                          )}{" "}
                          {!hotelDetails?.finiancial_aggrement
                            ?.is_contract_no_end_date && (
                            <span>
                              Until{" "}
                              {Utils.getDDMMYY(
                                hotelDetails?.finiancial_aggrement
                                  ?.contract_end_date || ""
                              )}
                            </span>
                          )}
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Discount
                        </Card.Subtitle>
                        <Card.Text>
                          {Utils.NumberFormatText(
                            "",
                            hotelDetails?.finiancial_aggrement
                              ?.discount_percentage,
                            "%"
                          )}
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          Monthly Fees
                        </Card.Subtitle>
                        <Card.Text>
                          {Utils.NumberFormatText(
                            "€",
                            hotelDetails?.finiancial_aggrement?.amount
                          )}
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Commission
                        </Card.Subtitle>
                        <Card.Text>
                          {Utils.NumberFormatText(
                            "",
                            hotelDetails?.finiancial_aggrement
                              ?.commision_percentage,
                            "%"
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  )}
                  {hotelShowOnly.contract_start_end_date === true && (
                    <Row>
                      <Col>
                        <HotelFinancialAgreements
                          updateform={{
                            setHotel: setHotelformValues,
                            hotel: formHotelValues,
                          }}
                          updateState={hotelShowOnly}
                          sendOldHotelValues={
                            formHotelValues.financial_agreement
                          }
                        ></HotelFinancialAgreements>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    Invoice information{" "}
                    <sup>
                      <img src={pencil} onClick={changeInvoiceInformation} />
                    </sup>
                  </Card.Title>
                  {hotelShowOnly.company_name === false && (
                    <Row>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          Company Name
                        </Card.Subtitle>
                        <Card.Text>
                          {hotelDetails?.invoice_info?.company_name}
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          Invoicing Address
                        </Card.Subtitle>
                        <Card.Text>
                          {hotelDetails?.invoice_info?.billing_address},
                          {hotelDetails?.invoice_info?.address_city} -{" "}
                          {hotelDetails?.invoice_info?.zip_code}
                          {/* ,{ hotelDetails?.invoice_info?.state_id},{ hotelDetails?.invoice_info?.country_id} */}
                        </Card.Text>
                        <Card.Subtitle className="mb-2 text-muted">
                          VAT Number
                        </Card.Subtitle>
                        <Card.Text>
                          {hotelDetails?.invoice_info?.vat_number}
                        </Card.Text>
                      </Col>
                    </Row>
                  )}
                  {hotelShowOnly.company_name === true && (
                    <Row>
                      <Col>
                        <HotelInvoiceInformation
                          updateform={{
                            setHotel: setHotelformValues,
                            hotel: formHotelValues,
                          }}
                          updateState={hotelShowOnly}
                          sendOldHotelValues={formHotelValues.invoice_info}
                          setIsInvoiceAddressDuplicate={
                            setIsInvoiceAddressDuplicate
                          }
                          isInvoiceAddressDuplicate={isInvoiceAddressDuplicate}
                        ></HotelInvoiceInformation>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
              <Card className="mb-2">
                <Card.Body>
                  <Card.Title>
                    Additional Notes{" "}
                    <sup>
                      <img src={pencil} onClick={changeAdditionalNotes} />
                    </sup>
                  </Card.Title>
                  {hotelShowOnly.additional_notes === false && (
                    <Row>
                      <Col>
                        <Card.Subtitle className="mb-2 text-muted">
                          {hotelDetails?.additional_notes}
                        </Card.Subtitle>
                      </Col>
                    </Row>
                  )}
                  {hotelShowOnly.additional_notes === true && (
                    <Row>
                      <Col>
                        <HotelAdditonalNotes
                          onChange={setHotelformValues}
                          hotel={formHotelValues}
                          sendOldHotelValues={formHotelValues}
                          updateState={hotelShowOnly}
                        ></HotelAdditonalNotes>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default UpdateHotels;
