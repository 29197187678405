import React, { useRef, useEffect } from "react";
import { Col, Form as bootstrapForm, Row } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import LanuageDropDown from "../Common/LanguageDropDown";

const UserContactInformation = (props) => {
  // intl tel input handler
  const handlePhoneChange = (_status, phoneNumber, country) => {
    props.change({
      target: {
        name: "phone_number",
        value: phoneNumber,
      },
    });

    props.change({
      target: {
        name: "phone_number_country",
        value: country.iso2,
      },
    });

    // props.setCountryISO(country.iso2);
  };

  const handleFlagChange = (_data, t1, _t2) => {
    props.change({
      target: {
        name: "phone_number_country",
        value: t1.iso2,
      },
    });
    // props.setCountryISO(t1.iso2);
  };

  const intlTelInputRef = useRef();
  useEffect(() => {
    if (
      intlTelInputRef.current &&
      props.values.phone_number_country &&
      props.values.phone_number_country.length > 0 &&
      props.values.phone_number_country !== "us"
    ) {
      setTimeout(() => {
        intlTelInputRef.current.setFlag(props.values.phone_number_country);
      }, 100);

      if (intlTelInputRef.current?.tel) {
        const intlInputParentDOM = intlTelInputRef.current?.tel?.parentElement;
        if (intlInputParentDOM) {
          const dialcodeDOM =
            intlInputParentDOM.firstElementChild.querySelector(
              ".selected-dial-code"
            );
          if (dialcodeDOM) {
            dialcodeDOM.classList.remove("selected-dial-code");
            dialcodeDOM.style.marginLeft = "5px";
          }
        }
      }
    }
  }, [intlTelInputRef, props.values]);

  return (
    <>
      <h5>Contact Information</h5>
      {!props.isEditForm && (
        <Row>
          <Col md={9}>
            <bootstrapForm.Group>
              <bootstrapForm.Label>
                Email Address <span style={{ color: "red" }}>*</span>
              </bootstrapForm.Label>
              <bootstrapForm.Control
                type="email"
                name="email"
                isInvalid={props.touched.email && props.error?.email}
                value={props.values?.email?.trimStart()}
                onChange={props.change}
                disabled={props.isedit}
              ></bootstrapForm.Control>
            </bootstrapForm.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Address <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <bootstrapForm.Control
              type="text"
              name="contact_address_street"
              isInvalid={
                props.touched.contact_address_street &&
                props.error?.contact_address_street
              }
              value={props.values?.contact_address_street?.trimStart()}
              onChange={props.change}
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
        <Col md={3}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Zip code <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            <bootstrapForm.Control
              type="text"
              name="contact_address_zipcode"
              isInvalid={
                props.touched.contact_address_zipcode &&
                props.error?.contact_address_zipcode
              }
              value={props.values?.contact_address_zipcode?.trimStart()}
              onChange={props.change}
            ></bootstrapForm.Control>
          </bootstrapForm.Group>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Country <span style={{ color: "red" }}>*</span>{" "}
            </bootstrapForm.Label>
            {/* <Form.Control type="text" name="country" defaultValue={(props.sendOldHotelValues===undefined)?(null):(props.sendOldHotelValues.country)} placeholder="United State" onChange = {props.updateform}></Form.Control> */}
            <LanuageDropDown
              val={props.country}
              touched={props.touched}
              errors={props.error}
              inpName={"contact_address_country_id"}
              updateDropdown={props.updateCountry}
              values={props.OldCountryVal}
            ></LanuageDropDown>
          </bootstrapForm.Group>
        </Col>
        <Col md={3}>
          {props.idCountry !== undefined && (
            <bootstrapForm.Group>
              <bootstrapForm.Label>STATE/PROVINCE </bootstrapForm.Label>
              {/* <Form.Control type="text" name="state" placeholder="California" defaultValue={(props.sendOldHotelValues===undefined)?(null):(props.sendOldHotelValues.state)} onChange = {props.updateform}></Form.Control> */}
              <LanuageDropDown
                val={props.states}
                touched={props.touched}
                errors={props.error}
                inpName={"contact_address_state_id"}
                updateDropdown={props.updateState}
                values={props.OldStateVal}
              ></LanuageDropDown>
            </bootstrapForm.Group>
          )}
        </Col>

        <Col md={3}>
          {props.idCountry !== undefined && (
            <bootstrapForm.Group>
              <bootstrapForm.Label>
                City <span style={{ color: "red" }}>*</span>
              </bootstrapForm.Label>
              <bootstrapForm.Control
                type="text"
                name="contact_address_city"
                value={props.values?.contact_address_city}
                isInvalid={
                  props.touched.contact_address_city &&
                  props.error?.contact_address_city
                }
                onChange={props.change}
              ></bootstrapForm.Control>
            </bootstrapForm.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <bootstrapForm.Group>
            <bootstrapForm.Label>
              Phone Number <span style={{ color: "red" }}>*</span>
            </bootstrapForm.Label>
            {/* <bootstrapForm.Control
              type="text"
              name="phone_number"
              isInvalid={props.touched.phone_number && props.error?.phone_number}
              value={props.values?.phone_number?.trimStart()}
              onChange={props.change}
            ></bootstrapForm.Control> */}
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control"
              input
              type="tel"
              value={props.values?.phone_number?.trimStart()}
              fieldName="phone_number"
              onSelectFlag={handleFlagChange}
              onPhoneNumberChange={handlePhoneChange}
              separateDialCode={true}
              ref={intlTelInputRef}
            />
          </bootstrapForm.Group>
        </Col>
      </Row>
    </>
  );
};
export default UserContactInformation;
