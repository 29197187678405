import React, { useEffect, useState } from "react";
import Select from "react-select";

const SingleDropDown = (props) => {
  let [temp, setTemp] = useState();
  let selectedValue =
    props.sendOldHotelValues === undefined
      ? null
      : props.sendOldHotelValues[props.inpName];
  useEffect(() => {
    if (selectedValue !== null && selectedValue !== NaN) {
      setTemp(props.sendOldHotelValues[props.inpName]);
    }
  }, [props.sendOldHotelValues]);
  const sendDropdownValue = (evt, val) => {
    let e = {
      target: {},
    };
    try {
      e["target"]["name"] = val.name;
      e["target"]["value"] = evt.value;
      setTemp(e["target"]["value"]);

      props.updateDropdown(e);
    } catch (err) {
      e["target"]["name"] = val.name;
      e["target"]["value"] = undefined;
      setTemp(e["target"]["value"]);
      props.updateDropdown(e);
    }
    if (props.setCountry !== undefined) {
      props.setCountry(e["target"]["value"]);
    }
  };
  let invalidStyle = {};
  let isInvalid =
    props.sendOldHotelValues === undefined ||
    props.sendOldHotelValues[props.inpName] === undefined ||
    props.sendOldHotelValues[props.inpName] === null
      ? true
      : props.sendOldHotelValues[props.inpName].length < 1
      ? true
      : false;

  if (isInvalid && props.req) {
    invalidStyle = {
      control: (provided, state) => ({
        ...provided,
        border: "1px solid red",
        "&:hover": { borderColor: "red" },
        boxShadow: "none",
      }),
    };
  }

  if (props.val !== undefined) {
    const options = [];
    props.val.forEach((v, i) => {
      options.push({ value: v["id"], label: v["value"] });
    });

    return (
      <div>
        <Select
          styles={invalidStyle}
          name={props.inpName}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={sendDropdownValue}
          placeholder={props.placeholder || ""}
          isClearable={true}
          defaultValue={options.filter(
            (option) => option.value === selectedValue
          )}
          value={options.filter((option) => option.value === temp)}
          isDisabled={props.disabled === undefined ? false : props.disabled}
        />
        {/* <Form.Group>
                  <Form.Control className='d-none' isInvalid={isInvalid && props.req}></Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {"Please fill out the field correctly"}
                  </Form.Control.Feedback>
              </Form.Group> */}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SingleDropDown;
