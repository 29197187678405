import axios from "axios";
import { STORAGE_KEYS } from "../helper/constants";
import { StorageService } from "../services/storage.service";
import { getTokens } from "./auth.service";

const storageService = new StorageService();

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  withCredentials: true,
});

// // attaching auth header
// instance.interceptors.request.use(function (config) {
//     const authToken = getToken();
//     config.headers.Authorization = 'Bearer '.concat(authToken);
//     return config;
// });

//applying header
instance.interceptors.request.use(function (config) {
  const authToken = getTokens();
  config.headers["Authorization"] = authToken.AccessToken;
  config.headers["Refresh-Token"] = authToken.RefreshToken;
  return config;
});

//applying refresh token
instance.interceptors.response.use(
  (response) => {
    if (response.headers) {
      if (response.headers.isAccessTokenRefreshed === "1") {
        storageService.setItem(
          STORAGE_KEYS.AccessToken,
          response.headers.AccessToken
        );
      }
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

// checking each request for auth status

// instance.interceptors.response.use( (response) => {
//     // for success
//     return response;
// }, (error) => {
//     if(+error.response.status === 401) {
//         redirectToLogin();
//     }

//     if(!authToken || !authToken.length) {
//         redirectToLogin();
//     }
// });

// const redirectToLogin = () => {
//         // history push
//         // removeToken();
//         // history.push("/login")
// }

export default instance;
