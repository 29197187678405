import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  MOBILE_SCREENS,
  PRIVATE_ROUTES,
  STORAGE_KEYS,
} from "../../helper/constants";
import { StorageService } from "../../services/storage.service";
import { Utils } from "../../utils/utils";
import { ReactComponent as Arrowright } from "./SidebarSvg/Arrowright.svg";
import { ReactComponent as DashboardIcon } from "./SidebarSvg/Dashboard.svg";
import { ReactComponent as HomeIcon } from "./SidebarSvg/Home.svg";
import { ReactComponent as HotelIcon } from "./SidebarSvg/Hotel.svg";
import { ReactComponent as HotelsgroupIcon } from "./SidebarSvg/Hotelsgroup.svg";
import { ReactComponent as LogoIcon } from "./SidebarSvg/Logo.svg";
import { ReactComponent as UsersIcon } from "./SidebarSvg/Users.svg";
import { ReactComponent as VectorIcon } from "./SidebarSvg/Vector.svg";

const Sidebar = (props) => {
  const locationRef = useLocation();
  const dashboard = useSelector(
    (state) => state?.login?.result?.dashboard_link
  );
  const storageService = new StorageService();

  const showSidebar = () => {
    props.onChangeSideBar(!props.sidebar);
  };

  //need to change name
  const hiddenIconAsPerRole = (path) => {
    const allowedPagesAccess = Utils.isAccessAllowedByRoute(path);
    return allowedPagesAccess;
  };

  const checkActiveLinks = (sidebarType) => {
    return locationRef.pathname.split("/").includes(sidebarType)
      ? "active"
      : "";
  };

  // checking device screen and hiding sidebar accordingly
  const handleSidebarForMobile = () => {
    // eslint-disable-next-line no-restricted-globals
    if (+MOBILE_SCREENS.MAX_WIDTH > +screen.width) {
      showSidebar();
    }
  };
  const urlReturn = () => {
    if (
      dashboard == "undefined" ||
      dashboard == undefined ||
      dashboard == null ||
      dashboard?.length < 1
    ) {
      return "";
    } else {
      return dashboard;
    }
  };
  const redirectToDashboard = () => {
    if (dashboard) {
      window.open(dashboard);
    }
  };

  return (
    <React.Fragment>
      <Nav
        activeKey={locationRef.pathname}
        className={`${
          props.sidebar ? "flex-column active-sidebar" : "flex-column"
        } sidebar-scroll`}
      >
        <Link
          className="sidebar__list-item--logo"
          to="/home"
          onClick={() => {
            handleSidebarForMobile();
          }}
        >
          <LogoIcon />
        </Link>
        <div className="sidebar-content">
          {hiddenIconAsPerRole(PRIVATE_ROUTES.home_page) ? (
            <Link
              to={PRIVATE_ROUTES.home_page}
              onClick={() => {
                handleSidebarForMobile();
              }}
              className={`${
                props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
              } ${checkActiveLinks("home")}`}
            >
              <span className="sidebar-content">
                <HomeIcon />
                {props.sidebar && <span className="sidebar-label">Home </span>}
              </span>
            </Link>
          ) : (
            <></>
          )}

          <Link
            as={NavLink}
            onClick={() => {
              handleSidebarForMobile();
            }}
            className={`${
              props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
            } ${checkActiveLinks("dashboard")}`}
          >
            <span className="sidebar-content">
              {/* todo: dashboard route is pending */}
              <DashboardIcon onClick={redirectToDashboard} />
              {props.sidebar && (
                <span className="sidebar-label">Dashboard </span>
              )}
            </span>
          </Link>

          {hiddenIconAsPerRole(PRIVATE_ROUTES.hotel_group_listing) ? (
            <Link
              to={PRIVATE_ROUTES.hotel_group_listing}
              onClick={() => {
                handleSidebarForMobile();
              }}
              className={`${
                props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
              } ${checkActiveLinks("hotel-group")}`}
            >
              <span className="sidebar-content">
                <HotelsgroupIcon />
                {props.sidebar && (
                  <span className="sidebar-label">Hotel Groups </span>
                )}
              </span>
            </Link>
          ) : (
            <></>
          )}

          {hiddenIconAsPerRole(PRIVATE_ROUTES.hotel_listing) ? (
            <Link
              to={PRIVATE_ROUTES.hotel_listing}
              onClick={() => {
                handleSidebarForMobile();
              }}
              className={`${
                props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
              } ${checkActiveLinks("hotel")}`}
            >
              <span className="sidebar-content">
                <HotelIcon />
                {props.sidebar && <span className="sidebar-label">Hotels</span>}
              </span>
            </Link>
          ) : (
            <></>
          )}

          {hiddenIconAsPerRole(PRIVATE_ROUTES.user_listing) ? (
            <Link
              to={PRIVATE_ROUTES.user_listing}
              className={`${
                props.sidebar ? "sidebar__list-item" : "sidebar__list-item"
              } ${checkActiveLinks("user")}`}
              onClick={() => {
                handleSidebarForMobile();
              }}
            >
              <span className="sidebar-content">
                <UsersIcon />
                {props.sidebar && <span className="sidebar-label">Users</span>}
              </span>
            </Link>
          ) : (
            <></>
          )}

          <div className="sidebar_toggle_icon" onClick={showSidebar}>
            {!props.sidebar ? (
              <div>
                <VectorIcon />
              </div>
            ) : (
              <div className="arrow-right">
                <Arrowright />
              </div>
            )}
          </div>
        </div>
      </Nav>
    </React.Fragment>
  );
};

export default Sidebar;
