const nullFormatter = (unformatted) => {
  Object.keys(unformatted).forEach((v, i) => {
    try {
      if (
        (unformatted[v] === "" || unformatted[v] === undefined) &&
        typeof unformatted[v] !== "object"
      ) {
        unformatted[v] = null;
      } else if (unformatted[v] !== null) {
        if (typeof unformatted[v] === "object") {
          Object.keys(unformatted[v]).forEach((v1, i1) => {
            if (
              (unformatted[v][v1] === "" || unformatted[v][v1] === undefined) &&
              typeof unformatted[v][v1] !== "object"
            ) {
              unformatted[v][v1] = null;
            }
          });
        }
      }
    } catch (error) {
      console.log(unformatted[v], v);
    }
  });
  return unformatted;
};

export default nullFormatter;
