export const GET_HOTELS = "GET_HOTELS";
export const FETCH_HOTELS_SUCESS = "FETCH_HOTELS_SUCESS";
export const FETCH_HOTELS_FAILED = "FETCH_HOTELS_FAILED";

export const ADD_HOTELS = "ADD_HOTELS";
export const ADD_HOTELS_SUCESS = "ADD_HOTELS_SUCESS";
export const ADD_HOTELS_FAILED = "ADD_HOTELS_FAILED";

export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
export const FETCH_HOTEL_DETAILS_SUCESS = "FETCH_HOTEL_DETAILS_SUCESS";
export const FETCH_HOTEL_DETAILS_FAILED = "FETCH_HOTEL_DETAILS_FAILED";

export const UPDATE_HOTEL_DETAILS = "UPDATE_HOTEL_DETAILS";
export const UPDATE_HOTEL_DETAILS_SUCESS = "UPDATE_HOTEL_DETAILS_SUCESS";
export const UPDATE_HOTEL_DETAILS_FAILED = "UPDATE_HOTEL_DETAILS_FAILED";

export const UPDATE_HOTEL_STATUS = "UPDATE_HOTEL_STATUS";
export const UPDATE_HOTEL_STATUS_SUCESS = "UPDATE_HOTEL_STATUS_SUCESS";
export const UPDATE_HOTEL_STATUS_FAILED = "UPDATE_HOTEL_STATUS_FAILED";

export const GET_SORTED_HOTELS = "GET_SORTED_HOTELS";
export const SEARCH_HOTEL = "SEARCH_HOTEL";

export const RESET_UPDATE_HOTEL = "RESET_UPDATE_HOTEL";
export const SEARCH_HOTEL_SORTED = "SEARCH_HOTEL_SORTED";

export const GET_PMS = "GET_PMS";
export const GET_PMS_SUCCESS = "GET_PMS_SUCCESS";
export const GET_PMS_FAILED = "GET_PMS_FAILED";

export const ADD_PMS_FORM_DATA = "ADD_PMS_FORM_DATA";
export const ADD_PMS_FORM_DATA_SUCESS = "ADD_PMS_FORM_DATA_SUCESS";
export const ADD_PMS_FORM_DATA_FAILED = "ADD_PMS_FORM_DATA_FAILED";

export const GET_PMS_HOTELS_BY_ID = "GET_PMS_HOTELS_BY_ID";
export const GET_PMS_HOTELS_BY_ID_SUCCESS = "GET_PMS_HOTELS_BY_ID_SUCCESS";
export const GET_PMS_HOTELS_BY_ID_FAILED = "GET_PMS_HOTELS_BY_ID_FAILED";

export const ASSIGN_PMS_HOTEL = "ASSIGN_PMS_HOTEL";
export const ASSIGN_PMS_HOTEL_SUCCESS = "ASSIGN_PMS_HOTEL_SUCCESS";
export const ASSIGN_PMS_HOTEL_FAILED = "ASSIGN_PMS_HOTEL_FAILED";

export const UPDATE_PMS_INTEGRATION = "UPDATE_PMS_INTEGRATION";
export const UPDATE_PMS_INTEGRATION_SUCCESS = "UPDATE_PMS_INTEGRATION_SUCCESS";
export const UPDATE_PMS_INTEGRATION_FAILED = "UPDATE_PMS_INTEGRATION_FAILED";

export const SYNC_CATEGORIES = "SYNC_CATEGORIES";
export const SYNC_CATEGORIES_SUCCESS = "SYNC_CATEGORIES_SUCCESS";
export const SYNC_CATEGORIES_FAILED = "SYNC_CATEGORIES_FAILED";

export const SYNC_PRODUCTS = "SYNC_PRODUCTS";
export const SYNC_PRODUCTS_SUCCESS = "SYNC_PRODUCTS_SUCCESS";
export const SYNC_PRODUCTS_FAILED = "SYNC_PRODUCTS_FAILED";

export const SYNC_ROOMS = "SYNC_ROOMS";
export const SYNC_ROOMS_SUCCESS = "SYNC_ROOMS_SUCCESS";
export const SYNC_ROOMS_FAILED = "SYNC_ROOMS_FAILED";

export const SET_HOTEL_SORT = "SET_HOTEL_SORT";
export const SET_HOTEL_LIST_PAGE_NO = "SET_HOTEL_LIST_PAGE_NO";
export const RESET_SORT_BY = "RESET_SORT_BY";
export const RESET_HOTEL_LIST = "RESET_HOTEL_LIST";
export const RESET_HOTEL_GROUP_LIST = "RESET_HOTEL_GROUP_LIST";

export const CLEAR_HOTEL_GROUP_LIST = "CLEAR_HOTEL_GROUP_LIST";
export const CLEAR_HOTEL_LIST = "CLEAR_HOTEL_LIST";
export const CLEAR_HOTEL_BY_ID = "CLEAR_HOTEL_BY_ID";
export const CLEAR_HOTEL_GROUP_BY_ID = "CLEAR_HOTEL_GROUP_BY_ID";

export const RESET_PMS_LIST_ARRAY = "RESET_PMS_LIST_ARRAY";

export const SET_SEARCH_HOTEL = "SET_SEARCH_HOTEL";

export const getHotels = (page, limit) => {
  return {
    type: GET_HOTELS,
    page,
    limit,
  };
};

export const getSortedHotels = (page, limit, columnName, sortBy) => {
  return {
    type: GET_SORTED_HOTELS,
    page,
    limit,
    columnName,
    sortBy,
  };
};

export const search_action_hotel = (keyword, page_no, limit) => {
  return {
    type: SEARCH_HOTEL,
    keyword,
    page_no,
    limit,
  };
};

export const search_sorted_action_hotel = (
  keyword,
  page_no,
  limit,
  columnName,
  sortBy
) => {
  return {
    type: SEARCH_HOTEL_SORTED,
    payload: keyword,
    page_no,
    limit,
    columnName,
    sortBy,
  };
};

export const createHotel = (data) => {
  return {
    type: ADD_HOTELS,
    payload: data,
  };
};

export const getHotelDetails = (id) => {
  return {
    type: GET_HOTEL_DETAILS,
    payload: id,
  };
};

export const updateHotelDetails = (hotelId, data) => {
  return {
    type: UPDATE_HOTEL_DETAILS,
    hotelId,
    payload: data,
  };
};

export const updateHotelStatus = (hotelId, status) => {
  return {
    type: UPDATE_HOTEL_STATUS,
    hotelId,
    payload: status,
  };
};

export const resetUpdateHotel = () => {
  return {
    type: RESET_UPDATE_HOTEL,
  };
};

export const getPmsList = (payload) => {
  return {
    type: GET_PMS,
    payload,
  };
};

export const addFormData = (payload) => {
  return {
    type: ADD_PMS_FORM_DATA,
    payload,
  };
};

export const getPmsHotelsList = (pmsId, hotelId) => {
  return {
    type: GET_PMS_HOTELS_BY_ID,
    pmsId,
    hotelId,
  };
};

export const assignPmsHotel = (pmsId, hotelId, payload) => {
  return {
    type: ASSIGN_PMS_HOTEL,
    pmsId,
    hotelId,
    payload,
  };
};

export const updatePmsIntegration = (payload) => {
  return {
    type: UPDATE_PMS_INTEGRATION,
    payload,
  };
};

export const syncCategories = (pmsId, hotelId) => {
  return {
    type: SYNC_CATEGORIES,
    pmsId,
    hotelId,
  };
};

export const syncRooms = (pmsId, hotelId) => {
  return {
    type: SYNC_ROOMS,
    pmsId,
    hotelId,
  };
};

export const syncProducts = (pmsId, hotelId) => {
  return {
    type: SYNC_PRODUCTS,
    pmsId,
    hotelId,
  };
};

export const resetPmsList = () => {
  return {
    type: RESET_PMS_LIST_ARRAY,
  };
};

export const setSearchHotel = (searchTerm) => {
  return {
    type: SET_SEARCH_HOTEL,
    searchTerm,
  };
};
