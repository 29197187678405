import { Table } from "react-bootstrap";
import { TABLE_ALIGNMENT } from "../../helper/constants";
import { ReactComponent as SortingUpArrowSVG } from "../Common/sorting_up_arrow.svg";
import { ReactComponent as SortingDownArrowSVG } from "../Common/sorting_down_arrow.svg";

const TableComponent = (props) => {
  if (
    props.mapper === undefined ||
    props.mapper.length === 0 ||
    props.mapper === 0
  ) {
    return (
      <div className="container">
        <strong>
          <span style={{ align: "center" }}> {"No Records Found"} </span>
        </strong>
      </div>
    );
  } else {
    return (
      <Table responsive striped borderless>
        <thead>
          <tr>
            {Object.keys(props.mapper[0]).map(
              (v, i) =>
                v !== "id" && (
                  <th key={i} >
                    {props.sort !== undefined ? (
                      <span className = "pointer">
                        <span onClick={
                          v!=='STATUS'?props.sort.sortingClick:()=>{}
                          }>{v}</span>
                        {(props.sort.sortColumns.includes(v) && v!='STATUS') && (
                          <span>
                            {" "}
                            {props.sort.sort !== "" &&
                            props.sort.sort[v] === true ? (
                              <span>
                                <SortingUpArrowSVG
                                  className="transition-arrow"
                                  onClick={() => {
                                    props.sort.sortingClick({
                                      target: { innerText: v },
                                    });
                                  }}
                                />
                              </span>
                            ) : (
                              <span>
                                <SortingUpArrowSVG
                                  className="transform-arrow transition-arrow"
                                  onClick={() => {
                                    props.sort.sortingClick({
                                      target: { innerText: v },
                                    });
                                  }}
                                />
                              </span>
                            )}
                          </span>
                        )}
                      </span>
                    ) : (
                      <span>{v}</span>
                    )}
                  </th>
                )
            )}

            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {props.mapper.map((v, i) => (
            <tr key={i}>
              {Object.keys(v).map(
                (v1, i1) =>
                  v1 !== "id" && (
                    <td key={i1} className={TABLE_ALIGNMENT[v1]}>
                      {v[v1]}
                    </td>
                  )
              )}
              {props.type === "update_list_table" && (
                <td key={i}>
                  <img
                    id={v["id"]}
                    alt={`action-img-01-${v["id"]}`}
                    src={require("./pencil.svg").default}
                    onClick={props.action.edit_row}
                    className="svg-s25"
                  />
                </td>
              )}
              {props.type === "delete_list_table" && (
                <td key={i}>
                  <img
                    id={v["id"]}
                    alt={`action-img-02-${v["id"]}`}
                    onClick={props.action.delete_row}
                    src={require("./delete.svg").default}
                    className="svg-s25"
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
};

export default TableComponent;
