const HotelGroupValidator = (data) => {
  let keyValidate = [
    "name",
    "address_street",
    "address_zipcode",
    "address_state_id",
    "address_country_id",
    "address_city",
    "first_name",
    "middle_name",
    "last_name",
    "email",
    "language_ids",
    "phone_number",
  ];
  let tempObj = {};
  try {
    keyValidate.forEach((v, i) => {
      try {
        if (
          data === undefined ||
          data[v] === undefined ||
          data[v] === "" ||
          data[v].length < 1
        ) {
          tempObj[v] = "";
        } else {
          tempObj[v] = data[v];
        }
      } catch (error) {}
    });
    return tempObj;
  } catch (err) {
    return err;
  }
};

export default HotelGroupValidator;
