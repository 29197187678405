import { Redirect, Route, useHistory } from "react-router-dom";
import SetPassword from "../components/Common/Login/SetPassword";
import Dashboard from "../components/Dashboard";
import CreateHotelGroup from "../components/HotelGroup/CreateHotelGroup";
import HotelAssignforHotelGroup from "../components/HotelGroup/HotelAssignforHotelGroup";
import HotelGroupList from "../components/HotelGroup/HotelGroupList";
//import Spiner from '../components/Common/SidebarSvg';
import UpdateHotelGroup from "../components/HotelGroup/UpdateHotelGroup";
import CreateHotels from "../components/Hotels/CreateHotels";
import HotelList from "../components/Hotels/HotelsList";
import UpdateHotels from "../components/Hotels/UpdateHotels";
import CreateUser from "../components/User/CreateUser";
import EditUser from "../components/User/EditUser";
import UpdateUser from "../components/User/UpdateUser";
import PmsIntegration from "../components/Hotels/PmsIntegration";
// for lazyloading
// const SomeComponent = lazy(() => import("./components/SomeComponent"))
// const SomeComponent = lazy(() => import("./components/SomeComponent"))
import UserList from "../components/User/UserList";
import { StorageService } from "../services/storage.service";

export const PrivateRoute = ({ recentPath = null }) => {
  const history = useHistory();
  const storageService = new StorageService();
  const sessionToken = storageService.getItem("session-token");
  const lastRoute = () => {
    const needToRedirectRoutes = [
      "/",
      "/login",
      "/set-password",
      "/forget-password",
      "/reset-password",
    ];
    if (sessionToken && sessionToken.length) {
      return "/set-password";
    } else {
      return recentPath &&
        recentPath.length &&
        !needToRedirectRoutes.includes(recentPath)
        ? recentPath
        : "/home";
    }
  };
  return (
    <>
      <Route exact path="/home" component={Dashboard}></Route>
      <Route
        exact
        path="/hotel-group/create"
        component={CreateHotelGroup}
      ></Route>
      <Route exact path="/hotel-group/list" component={HotelGroupList}></Route>

      <Route exact path="/hotel/create" component={CreateHotels}></Route>
      <Route exact path="/hotel/list" component={HotelList}></Route>
      {/* <Route exact path="/spiner" component={Spiner}></Route> */}
      <Route
        exact
        path="/hotel-group/update/:id"
        component={UpdateHotelGroup}
      ></Route>
      <Route exact path="/hotel/update/:id" component={UpdateHotels}></Route>
      {/* <Route exact path="/hotel/:id" component={HotelDetails}></Route> */}
      <Route
        exact
        path="/hotel/assign"
        component={HotelAssignforHotelGroup}
      ></Route>
      <Route exact path="/user/create" component={CreateUser}></Route>
      <Route exact path="/user/list" component={UserList}></Route>
      <Route exact path="/user/profile" component={UpdateUser}></Route>
      <Route exact path="/update-user/:id" component={EditUser}></Route>
      <Route exact path="/set-password" component={SetPassword}></Route>
      <Route
        exact
        path="/hotel/update/:id/pms-integration"
        component={PmsIntegration}
      ></Route>
      <Redirect to={lastRoute()}></Redirect>
    </>
  );
};
